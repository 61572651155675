import { version } from '../version';

export async function checkVersion() {
    try {
        const response = await fetch('/version.json', {
            cache: 'no-cache' // Ensure we don't get a cached version
        });
        const data = await response.json();
        return {
            currentVersion: version,
            serverVersion: data.version,
            needsUpdate: data.version !== version
        };
    } catch (error) {
        console.error('Version check failed:', error);
        return {
            currentVersion: version,
            serverVersion: null,
            needsUpdate: false
        };
    }
}
