import React, { useState, useRef, useEffect } from 'react';
import { PlusIcon, XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

// Button component that can be exported and used independently
const Button = ({
    isOpen,
    onClick,
    buttonText = '',
    buttonRadius = 'rounded-lg',
    icon = 'Plus',
    actionLabel = 'Open menu',
    className = '',
    showBadge = false,
    badgeText = 'New',
    badgeClassName = '',
    wrapperClassName = ''
}) => {
    const getIcons = (icon) => {
        switch (icon) {
            case 'Plus':
                return <PlusIcon className="w-5 h-5" />;
            case 'Exclamation':
                return <ExclamationCircleIcon className="w-7 h-7" />;
            default:
                return <PlusIcon className="w-5 h-5" />;
        }
    };

    const defaultClassName = `flex items-center justify-center ${isOpen ? 'w-14 h-14 !rounded-full' : buttonText ? 'px-4 py-2' : 'w-14 h-14'} ${buttonRadius} shadow-lg focus:outline-none transition-colors duration-300 ${isOpen ? 'bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700' : 'bg-purple-500 hover:bg-purple-600 dark:bg-purple-600 dark:hover:bg-purple-700'} text-white ${!isOpen && className}`;
    const defaultBadgeClassName = `absolute -top-2 -right-2 text-xs bg-red-600 text-white px-1.5 py-0.5 rounded-full shadow ${badgeClassName}`;
    const defaultWrapperClassName = `relative ${!isOpen && wrapperClassName}`;

    const buttonElement = (
        <button
            onClick={onClick}
            className={defaultClassName}
            aria-label={isOpen ? 'Close menu' : actionLabel}
        >
            {showBadge && !isOpen && (
                <span className={defaultBadgeClassName}>{badgeText}</span>
            )}
            {isOpen ? <XMarkIcon className="w-5 h-5" /> : buttonText || getIcons(icon)}
        </button>
    );

    return (
        <div className={defaultWrapperClassName}>
            {buttonElement}
        </div>
    );
};

// Panel component that can be exported and used independently
const Panel = ({
    isOpen,
    onClose,
    title = 'Notification',
    children,
    className = '',
    panelClassName = 'px-6 py-4 bg-white rounded-lg border border-gray-100 shadow-lg min-w-72 dark:bg-gray-800 dark:border-slate-800',
    titleClassName = 'text-lg font-medium text-gray-900 dark:text-white',
    contentClassName = 'text-gray-600 dark:text-gray-300',
    closeButtonClassName = 'absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
}) => {
    const panelRef = useRef(null);
    const defaultClassName = `transition-all duration-100 ${isOpen ? 'mb-4 opacity-100' : 'opacity-0 pointer-events-none'}`;

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && panelRef.current && !panelRef.current.contains(event.target)) {
                onClose();
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    return (
        <div className={className || defaultClassName}>
            {children && (
                <>
                    <div ref={panelRef} className={panelClassName}>
                        <h3 className={titleClassName}>{title}</h3>
                        <div className={contentClassName}>
                            {children}
                        </div>
                        <button
                            onClick={onClose}
                            className={closeButtonClassName}
                        >
                            <XMarkIcon className="w-5 h-5" />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

const FloatingNotification = ({
    onAction,
    actionLabel,
    position = 'bottom-right',
    children,
    icon = 'Plus',
    buttonRadius = 'rounded-lg',
    buttonText = '',
    buttonClassName = '',
    showBadge = false,
    badgeText = 'New',
    badgeClassName = 'absolute -top-2 -right-2 text-xs bg-red-600 text-white px-1.5 py-0.5 rounded-full shadow',
    panelClassName = '',
    titleClassName = '',
    contentClassName = '',
    closeButtonClassName = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);

    // Determine position classes
    const getPositionClasses = () => {
        switch (position) {
            case 'bottom-right':
                return 'bottom-6 right-6';
            case 'bottom-left':
                return 'bottom-6 left-6';
            case 'top-right':
                return 'top-6 right-6';
            case 'top-left':
                return 'bottom-6 left-6';
            case 'mid-right':
                return 'bottom-1/2 -translate-y-1/2 right-6';
            case 'mid-left':
                return 'top-1/2 -translate-y-1/2 left-6';
            default:
                return 'bottom-6 right-6';
        }
    };

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
        if (onAction && !isOpen) onAction();
    };

    const handleClosePanel = () => {
        setIsOpen(false);
    };

    return (
        <div className={`flex fixed z-50 flex-col items-end ${getPositionClasses()}`}>
            {/* Notification Panel */}
            <Panel
                isOpen={isOpen}
                onClose={handleClosePanel}
                title={actionLabel || 'Notification'}
                panelClassName={panelClassName}
                titleClassName={titleClassName}
                contentClassName={contentClassName}
                closeButtonClassName={closeButtonClassName}
            >
                {children}
            </Panel>

            {/* Floating Action Button */}
            <Button
                isOpen={isOpen}
                onClick={handleButtonClick}
                buttonText={buttonText}
                buttonRadius={buttonRadius}
                icon={icon}
                actionLabel={actionLabel}
                className={buttonClassName}
                showBadge={showBadge}
                badgeText={badgeText}
                badgeClassName={badgeClassName}
            />
        </div>
    );
};

// Attach components as properties of FloatingNotification
FloatingNotification.Button = Button;
FloatingNotification.Panel = Panel;

export default FloatingNotification;