import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useApi } from "./ApiContext";
import { io } from "socket.io-client";

export const CoinsContext = createContext();

export const CoinsProvider = ({ children }) => {
  const [coinsData, setCoinsData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [symbols, setSymbols] = useState([]);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const { apiHost, apiVersion } = useApi();
  const [wsErrorCount, setWsErrorCount] = useState(0);
  const MAX_WS_ERRORS = 3;
  const reconnectTimeoutRef = useRef(null);
  const isConnecting = useRef(false);
  const [cryptoSymbols, setCryptoSymbols] = useState([]);
  const [stockSymbols, setStockSymbols] = useState([]);
  const [forexSymbols, setForexSymbols] = useState([]);
  const [fetchedRooms, setFetchedRooms] = useState([]);

  //Fetch historical data from API
  const fetchBatchHistoricalData = useCallback(
    async (symbol) => {
      try {
        const url = `${apiHost}${apiVersion}/polygon/candles?symbol=${symbol.toUpperCase()}`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(`Error fetching historical data for ${symbol}:`, error);
        // setError(`Error fetching historical data for ${symbol}`);
        return [];
      }
    },
    [apiHost, apiVersion]
  );

  const fetchBatchHistoricalDataMultiple = useCallback(
    async (symbols) => {
      try {
        const url = `${apiHost}${apiVersion}/polygon/candles/multiple`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ symbols }),
        });
        const data = await response.json();
        return data.data;
      } catch (error) {
        console.error(`Error fetching historical data for multiple symbols:`, error);
        return {};
      }
    },
    [apiHost, apiVersion]
  );

  //Function to set the coins data
  const fetchCoinsData = useCallback(async () => {
    try {
      const response = await fetch(`${apiHost}${apiVersion}/market/list`);
      const data = await response.json();

      const cryptoSyms = [...new Set(
        data
          .filter((item) => item.type === "crypto")
          .map((item) => item.cryptoCompareSymbol)
      )];
      const stockSyms = [...new Set(
        data
          .filter((item) => item.type === "stocks")
          .map((item) => item.symbol)
      )];
      const forexSyms = [...new Set(
        data
          .filter((item) => item.type === "forex")
          .map((item) => item.symbol)
      )];

      setFetchedRooms(data);
      setCryptoSymbols(cryptoSyms);
      setStockSymbols(stockSyms);
      setForexSymbols(forexSyms);
      setSymbols([...new Set(data.map((item) => item.symbol))]);

      // Get all symbols for the batch request
      const allSymbols = [...new Set(data.map((item) => item.symbol))];

      // Fetch historical data for all symbols in one request
      const historicalDataBatch = await fetchBatchHistoricalDataMultiple(allSymbols);

      const liveCoins = data.map(coin => {
        const historicalData = historicalDataBatch[coin.symbol] || [];
        const priceTrend = historicalData.map(item => parseFloat(item.close));
        const currentPrice = priceTrend[priceTrend.length - 1] || null;
        const initialPrice = priceTrend[0] || null;

        let percentageChange = null;
        if (initialPrice && currentPrice) {
          percentageChange = ((currentPrice - initialPrice) / initialPrice) * 100;
        }

        return {
          ...coin,
          priceTrend,
          currentPrice,
          percentageChange,
          initialPrice,
        };
      });
      setCoinsData(liveCoins);
      setIsDataFetched(true);
    } catch (error) {
      console.error("Error fetching coin data:", error);
      setError("No coin data available");
    }
  }, [apiHost, apiVersion, fetchBatchHistoricalDataMultiple]);

  //Fetch coins data when the component mounts
  useEffect(() => {
    fetchCoinsData();
  }, [fetchCoinsData]);

  //Connect to WebSocket proxy server
  const connectWebSocket = useCallback(() => {
    if (isConnecting.current || socketRef.current?.connected) {
      return;
    }

    isConnecting.current = true;

    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    socketRef.current = io(
      process.env.REACT_APP_WS_PROXY_URL || "wss://swaggyprodws.debugged.com.my",
    );

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket proxy server");
      setWsErrorCount(0);
      setError("");
      isConnecting.current = false;

      // Subscribe to crypto and stocks with proper formatting
      if (cryptoSymbols.length > 0) {
        const formattedCryptoSymbols = cryptoSymbols.map((sym) => `XAS.${sym}`);
        
        socketRef.current.emit("subscribe_crypto", formattedCryptoSymbols);
      }

      if (stockSymbols.length > 0) {
        const formattedStockSymbols = stockSymbols.map((sym) => `A.${sym}`);
        
        socketRef.current.emit("subscribe_stocks", formattedStockSymbols);
      }

      if (forexSymbols.length > 0) {
        const formattedForexSymbols = forexSymbols.map((sym) => `CAS.${sym}`);
        
        socketRef.current.emit("subscribe_forex", formattedForexSymbols);
      }
    });

    // Handle crypto updates
    socketRef.current.on("polygon_crypto", (data) => {
      if (Array.isArray(data)) {
        data.forEach((msg) => {
          // Handle XAS (second aggregate) events
          if (msg.ev === "XAS" || msg.ev === "XA") {
            // Extract base symbol from pair (e.g., "ETH-USD" -> "ETH")
            const symbol = msg.pair;
            const price = msg.c; // Use closing price from aggregate
            if (symbol && price) {
              setCoinsData(prevCoins => 
                prevCoins.map(coin => {
                  if (coin.cryptoCompareSymbol.toLowerCase() === symbol.toLowerCase() && coin.type === "crypto") {
                    const updatedCoin = {
                      ...coin,
                      currentPrice: parseFloat(price),
                    };
                    return updatedCoin;
                  }
                  return coin;
                })
              );
            }
          }
        });
      }
    });

    // Handle stock updates
    socketRef.current.on("polygon_stocks", (data) => {
      if (Array.isArray(data)) {
        data.forEach((msg) => {
          // Handle both A and AM (minute aggregate) events
          if (msg.ev === "A" || msg.ev === "AM") {
            const symbol = msg.sym;
            const price = msg.c;
            if (symbol && price) {
              setCoinsData(prevCoins => 
                prevCoins.map(coin => {
                  if (coin.symbol === symbol && coin.type === "stocks") {
                    return {
                      ...coin,
                      currentPrice: parseFloat(price),
                    };
                  }
                  return coin;
                })
              );
            }
          }
        });
      }
    });

    // Handle forex updates
    socketRef.current.on("polygon_forex", (data) => {
      if (Array.isArray(data)) {
        data.forEach((msg) => {
          // Handle both CAS and CA (minute aggregate) events
          if (msg.ev === "CAS" || msg.ev === "CA") {
            const symbol = `C:${msg.pair.replace("/", "-")}`;
            const price = msg.c;
            if (symbol && price) {
              setCoinsData(prevCoins => 
                prevCoins.map(coin => {
                  if (coin.symbol === symbol && coin.type === "forex") {
                    return {
                      ...coin,
                      currentPrice: parseFloat(price),
                    };
                  }
                  return coin;
                })
              );
            }
          }
        });
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket proxy server");
      isConnecting.current = false;

      setWsErrorCount((prevCount) => {
        const newCount = prevCount + 1;

        if (newCount >= MAX_WS_ERRORS) {
          setError(
            "Connection issues detected. Page will reload in 5 seconds..."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          return newCount;
        }

        if (!isConnecting.current) {
          const reconnectDelay = Math.min(1000 * Math.pow(2, newCount), 10000);

          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }

          reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
          }, reconnectDelay);
        }

        return newCount;
      });
    });

    socketRef.current.on("error", (error) => {
      console.error("WebSocket error:", error);
      isConnecting.current = false;
    });
  }, [cryptoSymbols, stockSymbols, forexSymbols]);

  //Connect to WebSocket when the component mounts
  useEffect(() => {
    if (isDataFetched) {
      connectWebSocket();

      return () => {
        isConnecting.current = false;

        if (reconnectTimeoutRef.current) {
          clearTimeout(reconnectTimeoutRef.current);
        }

        if (socketRef.current) {
          // Unsubscribe before disconnecting
          if (cryptoSymbols.length > 0) {
            const formattedCryptoSymbols = cryptoSymbols.map((sym) => `XAS.${sym}`);
            socketRef.current.emit("unsubscribe_crypto", formattedCryptoSymbols);
          }
          if (stockSymbols.length > 0) {
            const formattedStockSymbols = stockSymbols.map((sym) => `A.${sym}`);
            socketRef.current.emit("unsubscribe_stocks", formattedStockSymbols);
          }
          socketRef.current.disconnect();
        }

        setWsErrorCount(0);
      };
    }
  }, [isDataFetched, connectWebSocket, cryptoSymbols, stockSymbols]);

  const getCoinData = (id) => {
    const coin = coinsData.find((coin) => coin.id === parseInt(id));
    if (!coin) {
    }
    return coin;
  };

  return (
    <CoinsContext.Provider
      value={{
        coinsData,
        isDataFetched,
        fetchBatchHistoricalData,
        error,
        getCoinData,
        fetchCoinsData,
        fetchedRooms,
        symbols
      }}
    >
      {children}
    </CoinsContext.Provider>
  );
};
