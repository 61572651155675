import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import FloatingNotification from './FloatingNotification';
import { CoinsContext } from '../contexts/CoinsContext';
import { useTour } from '../contexts/TourContext';
import { useApi } from '../contexts/ApiContext';
import { useAuth } from '../AuthContext';

const FreeDemoBets = () => {
    const navigate = useNavigate();
    const [freeBetsNotificationOpen, setFreeBetsNotificationOpen] = useState(false);
    const [showFreeDemo, setShowFreeDemo] = useState(false);
    const { coinsData } = useContext(CoinsContext);
    const { tourState } = useTour();
    const [currentCoinIndex, setCurrentCoinIndex] = useState(0);
    const [buttonText, setButtonText] = useState('Bet Now!');
    const intervalRef = useRef(null);
    const currentCoinRef = useRef(null);

    const { apiHost, apiVersion } = useApi();
    const { isAuthenticated, user } = useAuth();

    // Memoize the coin names to prevent unnecessary recalculations
    const coinNames = useMemo(() => {
        if (!coinsData || !coinsData.length) return ['Bitcoin'];
        return coinsData.map(coin => coin.fullCryptoName || coin.name || 'Crypto');
    }, [coinsData]);

    // Handle navigation when button is clicked
    const handleBetClick = () => {
        if (!coinsData || !coinsData.length) {
            navigate('/');
            return;
        }

        const currentCoin = coinsData[currentCoinIndex];
        if (!currentCoin) {
            navigate('/');
            return;
        }

        // Determine the type based on coin properties
        let type = 'crypto';
        if (currentCoin.type) {
            type = currentCoin.type.toLowerCase();
        }

        // Get the symbol and id
        const symbol = currentCoin.symbol || '';
        const id = currentCoin.id || '';

        // Navigate to the appropriate URL
        navigate(`/event/${type}/${symbol}/${id}`);
        setFreeBetsNotificationOpen(false);
    };

    useEffect(() => {
        const getUserStatus = async () => {
            const response = await fetch(`${apiHost}${apiVersion}/get-user-is-new`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`,
                }
            });
            const data = await response.json();
            return data.is_new ? true : false;
        };

        if (isAuthenticated && user) {
            getUserStatus().then(data => {
                setShowFreeDemo(data);
            });
        } else {
            setShowFreeDemo(false);
        }

    }, [apiHost, apiVersion, isAuthenticated, user]);

    // Setup the interval to change the button text
    useEffect(() => {
        // Only start the interval if we have coin data and the panel is open
        if (coinNames.length > 0 && freeBetsNotificationOpen) {
            // Clear any existing interval
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            // Set initial text and current coin reference
            setButtonText(`Bet on ${coinNames[currentCoinIndex]} Now!`);
            currentCoinRef.current = coinsData?.[currentCoinIndex] || null;

            // Create new interval
            intervalRef.current = setInterval(() => {
                setCurrentCoinIndex(prevIndex => {
                    const nextIndex = (prevIndex + 1) % coinNames.length;
                    setButtonText(`Bet on ${coinNames[nextIndex]} Now!`);
                    currentCoinRef.current = coinsData?.[nextIndex] || null;
                    return nextIndex;
                });
            }, 800);
        }

        // Cleanup function
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [coinNames, freeBetsNotificationOpen, currentCoinIndex, coinsData]);

    // Reset index when panel closes
    useEffect(() => {
        if (!freeBetsNotificationOpen) {
            setCurrentCoinIndex(0);
        }
    }, [freeBetsNotificationOpen]);

    // Check if tour is active
    const isTourActive = tourState?.run === true;

    // If tour is active, don't render the component
    if (isTourActive || !showFreeDemo) {
        return null;
    }

    return (
        /* Floating Action Button for Demo Bet Access */
        <div className="flex fixed right-6 bottom-1/2 z-20 flex-col items-end -translate-y-1/2 mid-right">
            <FloatingNotification.Panel
                isOpen={freeBetsNotificationOpen}
                onClose={() => setFreeBetsNotificationOpen(false)}
                title="You have free bets available!"
                panelClassName="px-6 py-4 bg-white rounded-lg border border-gray-100 shadow-lg min-w-72 dark:bg-gray-800 dark:border-slate-800"
                titleClassName="text-lg font-medium text-purple-600 dark:text-purple-400"
                contentClassName="text-gray-600 dark:text-gray-300"
            >
                <div className="space-y-2">
                    <p className="text-sm">Use them now to test your skills and win!</p>
                    <div className="flex justify-center">
                        <button
                            onClick={handleBetClick}
                            className="px-4 py-2 mt-2 w-48 text-sm text-white bg-purple-600 rounded-md transition-colors hover:bg-purple-700 dark:bg-purple-700 dark:hover:bg-purple-800"
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </FloatingNotification.Panel>

            <FloatingNotification.Button
                isOpen={freeBetsNotificationOpen}
                onClick={() => setFreeBetsNotificationOpen(!freeBetsNotificationOpen)}
                buttonText={
                    <span className="relative group">
                        <span className="hidden lg:inline">🎲 Free Demo Bets!</span>
                        <span className="inline lg:hidden">🎲</span>
                    </span>
                }
                icon="Plus"
                className="text-white font-semibold !bg-transparent"
                showBadge={true}
                badgeText="New"
                wrapperClassName="glow-border !rounded-full lg:!rounded-md py-1.5 lg:!p-[4px] hover:scale-105 bg-gradient-to-r from-purple-500 via-pink-600 to-indigo-600"
            />
        </div>
    );
};

export default FreeDemoBets;