import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../AuthContext";
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';

const getCurrencyIcon = (currency) => {
  switch (currency.toLowerCase()) {
    case 'bitcoin':
    case 'btc':
      return <FaBitcoin className="text-yellow-500" />;
    case 'ethereum':
    case 'eth':
      return <FaEthereum className="text-blue-500" />;
    case 'usd':
    case 'dollar':
      return <FaDollarSign className="text-green-500" />;
    default:
      return <FaDollarSign className="text-gray-500" />;
  }
};

const BetTable = ({ bets, newBets, t, location, navigate }) => (
  <div className="hidden overflow-hidden bg-gradient-to-r from-purple-400 to-purple-700 rounded-lg shadow-lg md:block">
    <div className="flex justify-between items-center px-4 py-2">
      <h2 className="text-lg font-bold">{t('Recent Activity')}</h2>
      {location.pathname !== '/activity' && (
        <button
          className="text-sm text-gray-100 rounded-lg transition-colors hover:text-white"
          onClick={() => navigate('/activity')}
        >
          {t('See All')}
        </button>
      )}
    </div>
    <table className="min-w-full table-auto">
      <thead className="text-white">
        <tr>
          {['Coin', 'Player', 'Time', 'Predict'].map((heading) => (
            <th
              key={heading}
              className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase transition-colors cursor-pointer hover:text-yellow-400"
            >
              {t(heading)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y dark:bg-gray-800">
        {bets.length > 0 ? (
          bets.map((bet, index) => (
            <tr
              key={index}
              className={`hover:bg-gray-300 dark:hover:bg-gray-700 transition-colors ${
                newBets.includes(index) ? 'animate-slideInUp' : ''
              }`}
            >
              <td className="flex items-center px-6 py-4 space-x-2 whitespace-nowrap">
                {getCurrencyIcon(bet.currency)}
                <span>{bet.currency}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{bet.username}</td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(bet.order_at).toLocaleString()}</td>
              <td className="px-6 py-4 whitespace-nowrap">${bet.amount}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="py-4 text-center text-gray-400">
              {t('No trades available')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

BetTable.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      order_at: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  newBets: PropTypes.array.isRequired,
};

const BetCards = ({ bets, newBets, t }) => (
  <div className="space-y-4 md:hidden">
    {bets.length > 0 ? (
      bets.map((bet, index) => (
        <div
          key={index}
          className={`bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-4 rounded-lg shadow-lg ${
            newBets.includes(index) ? 'animate-slideInUp' : ''
          }`}
        >
          <div className="flex justify-between items-center">
            <span className="font-bold text-gray-400">{t('Coin')}:</span>
            <span className="flex items-center space-x-2">
              {getCurrencyIcon(bet.currency)}
              <span>{bet.currency}</span>
            </span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">{t('Player')}:</span>
            <span>{bet.username}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">{t('Time')}:</span>
            <span>{new Date(bet.order_at).toLocaleString()}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">{t('Trade')}:</span>
            <span>${bet.amount}</span>
          </div>
        </div>
      ))
    ) : (
      <p className="text-center text-gray-400">{t('No trades available')}</p>
    )}
  </div>
);

BetCards.propTypes = BetTable.propTypes;

const Activity = () => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [newBets, setNewBets] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();

  const fetchBets = async (initialLoad = false) => {
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/market/live-order`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
          },
          body: JSON.stringify({
            user_id: user?.id
          })
        }
      );
      if (!response.ok) {
        throw new Error(`${t('HTTP error! status')}: ${response.status}`);
      }
      const data = await response.json();
      
      // Conditionally load 5 activities when not on /activity page
      const limit = location.pathname === '/activity' ? 40 : 5;
      const newBetsData = data.slice(0, limit); // Always reset offset on first fetch

      // If on /activity page, track the new ones for reveal effect
      if (location.pathname === '/activity') {
        setNewBets(newBetsData.map((_, idx) => bets.length + idx)); // Mark new bets by their index
      }

      setBets((prevBets) =>
        initialLoad ? newBetsData : [...prevBets, ...newBetsData]
      );

      if (newBetsData.length < 20 && location.pathname === '/activity') {
        setHasMore(false); // If fewer than 20 bets are returned, stop further loading
      }
    } catch (err) {
      console.error('Error fetching bets:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = useCallback(() => {
    if (hasMore) {
      setOffset((prevOffset) => prevOffset + 20);
      fetchBets(); // Load more when scrolling, but only on /activity page
    }
  }, [hasMore]);

  useEffect(() => {
    fetchBets(true); // Load initial bets
    const intervalId = setInterval(() => {
      if (location.pathname === '/activity') {
        fetchBets(); // Refresh only on /activity page
      }
    }, 10000); // Auto-refresh every 10 seconds on /activity
    return () => clearInterval(intervalId);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (location.pathname === '/activity' && hasMore) {
          loadMore();
        }
      }
    };

    if (location.pathname === '/activity') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, hasMore, loadMore]);

  return (
    <div className={`w-full ${location.pathname !== '/activity' && 'last-bets'}`}>
      <div className="flex justify-between items-center mb-8 md:hidden">
        <h2 className="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500">
          {t('Recent Activity')}
        </h2>
        {location.pathname !== '/activity' && (
          <button
            className="px-3 py-1 rounded-lg border border-gray-400 transition-colors hover:bg-white hover:text-purple-500"
            onClick={() => navigate('/activity')}
          >
            {t('See All')}
          </button>
        )}
      </div>

      {loading && <p className="text-center animate-pulse">{t('Loading')}...</p>}

      {error && (
        <p className="text-center text-red-500">
          {t('Error fetching trades')}: {error.message}
        </p>
      )}

      {!loading && !error && (
        <>
          <BetTable bets={bets} newBets={newBets} t={t} location={location} navigate={navigate}/>
          <BetCards bets={bets} newBets={newBets} t={t} />
          {hasMore && location.pathname === '/activity' && (
            <p className="mt-4 text-center text-gray-400">{t('Loading more')}...</p>
          )}
        </>
      )}
    </div>
  );
};

export default Activity;
