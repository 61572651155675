import React from 'react'
import { Line } from 'react-chartjs-2'
import "chart.js/auto";
import "chartjs-adapter-date-fns";

const PredictionDataChart = ({ predictionData }) => {
    // Skip rendering if no data is available
    if (!predictionData || !predictionData.orders || predictionData.orders.length === 0) {
        return <div className="p-4 text-center">No prediction data available</div>;
    }

    // Process the orders data to extract timestamps and predictions
    const processedData = predictionData.orders.reduce((acc, order) => {
        // Round timestamp to the nearest minute to group predictions in the same minute
        const date = new Date(order.created_at);
        date.setSeconds(0, 0); // Zero out seconds and milliseconds
        const timestamp = date.getTime();
        const prediction = order.prediction;

        // Determine if the order is bullish or bearish based on the arrays in predictionData
        const isBullish = predictionData.bullish_orders.includes(prediction);

        // Group by timestamp
        if (isBullish) {
            if (!acc.bullishByTime[timestamp]) {
                acc.bullishByTime[timestamp] = { sum: 0, count: 0 };
            }
            acc.bullishByTime[timestamp].sum += prediction;
            acc.bullishByTime[timestamp].count += 1;
        } else {
            if (!acc.bearishByTime[timestamp]) {
                acc.bearishByTime[timestamp] = { sum: 0, count: 0 };
            }
            acc.bearishByTime[timestamp].sum += prediction;
            acc.bearishByTime[timestamp].count += 1;
        }

        return acc;
    }, { bullishByTime: {}, bearishByTime: {} });

    // Convert grouped data to arrays of points with averages
    const bullishData = Object.entries(processedData.bullishByTime).map(([timestamp, data]) => ({
        x: parseInt(timestamp),
        y: data.sum / data.count, // Average prediction
        count: data.count // Store count for tooltip
    }));

    const bearishData = Object.entries(processedData.bearishByTime).map(([timestamp, data]) => ({
        x: parseInt(timestamp),
        y: data.sum / data.count, // Average prediction
        count: data.count // Store count for tooltip
    }));

    // Sort data points by timestamp
    bullishData.sort((a, b) => a.x - b.x);
    bearishData.sort((a, b) => a.x - b.x);

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute',
                    stepSize: 10,
                    displayFormats: {
                        minute: 'h:mma'
                    }
                },
                ticks: {
                    source: 'data',
                    autoSkip: false,
                    maxRotation: 0,
                    major: {
                        enabled: true
                    },
                    // Force specific time intervals
                    callback: function (value, index, values) {
                        const date = new Date(value);
                        const minutes = date.getMinutes();
                        // Only show ticks at 0, 10, 20, 30, 40, 50 minutes
                        return minutes % 10 === 0 ? date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        }) : '';
                    }
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Prediction Value'
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Prediction Trends Over Time',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        const count = context.raw?.count || 1;
                        return `${label}: ${value.toFixed(2)} (${count} prediction${count > 1 ? 's' : ''})`;
                    },
                    title: function (context) {
                        const date = new Date(context[0].parsed.x);
                        return date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        });
                    }
                }
            }
        },
    };

    const data = {
        datasets: [
            {
                label: 'Bullish',
                data: bullishData,
                borderColor: 'rgb(75, 192, 92)',
                backgroundColor: 'rgba(75, 192, 92, 0.5)',
                pointRadius: 4,
                pointHoverRadius: 6,
            },
            {
                label: 'Bearish',
                data: bearishData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 4,
                pointHoverRadius: 6,
            },
        ],
    };

    return (
        <div className="flex flex-col items-center !my-12 space-y-4">
            <h2 className="text-lg font-bold">Prediction Trends</h2>
            <div className="w-full">
                <Line
                    data={data}
                    options={options}
                />
            </div>
            <div className="flex gap-4 justify-center items-center text-sm text-gray-600 dark:text-gray-400">
                <p>Bullish: {predictionData.bullish_percentage}% ({predictionData.bullish_orders.length} orders)</p>
                <p>Bearish: {predictionData.bearish_percentage}% ({predictionData.bearish_orders.length} orders)</p>
            </div>
        </div>
    )
}

export default PredictionDataChart