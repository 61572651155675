import React, { useEffect, useState } from 'react';
import { FiMail, FiLock, FiCopy } from 'react-icons/fi';
import { useApi } from "../../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

const Settings = () => {
  const [email, setEmail] = useState('');
  const [currentPasswordForEmail, setCurrentPasswordForEmail] = useState('');
  const [currentPasswordForPassword, setCurrentPasswordForPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailSuccess, setEmailSuccess] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();
  const [referralCode, setReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmailSuccess(t('Your email has been updated successfully.'));
    setErrorMessage('');
    console.log('Email updated to:', email);
  };

  const fetchReferralCode = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("sanctum_token");
      const response = await fetch(`${apiHost}${apiVersion}/get-referral-code`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`${t('Error fetching referral code')}: ${response.statusText}`);
      }
      const data = await response.json();
      setReferralCode(data?.referral?.referral_code || '');
    } catch (error) {
      console.error("Error fetching referral code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReferralCode();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage(t('New passwords do not match!'));
      setPasswordSuccess('');
      return;
    }

    try {
      const token = localStorage.getItem("sanctum_token");

      const response = await fetch(`${apiHost}${apiVersion}/update-user-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_password: currentPasswordForPassword,
          new_password: newPassword,
          new_password_confirmation: confirmPassword,
        }),
      });

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        console.log(data)
        if (response.ok) {
          if (data.success) {
            setPasswordSuccess(t('Your password has been updated successfully.'));
            setErrorMessage('');
          } else {
            setErrorMessage(data.message);
            setPasswordSuccess('');
          }
        } else {
          setErrorMessage(data.message);
          setPasswordSuccess('');
        }
      } else {
        const text = await response.text();
        console.error("Non-JSON response:", text);
        setErrorMessage(`${t('Unexpected response format')}: ${text}`);
        setPasswordSuccess('');
      }

    } catch (error) {
      setErrorMessage(t('An error occurred while updating your password.'));
      setPasswordSuccess('');
      console.log('Error updating password:', error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    toast.success(t('Copied to clipboard'));
  };

  // Generate a placeholder code that looks like a referral code but is blurred
  const placeholderCode = "ABCDEF";

  return (
    <div className="p-8 mx-auto mt-32 max-w-3xl">
      <h2 className="mb-12 text-4xl font-extrabold text-center">{t('Account Settings')}</h2>
      <hr className="mb-16 border-t" />
      <div>
        <h3 className="mb-8 text-2xl font-semibold">{t('Refer a friend')}</h3>
        <div className="flex items-center py-2 border-b border-gray-600">
          <input
            type="text"
            className={`px-2 py-1 mr-3 w-full leading-tight bg-transparent border-none appearance-none focus:outline-none ${isLoading ? 'blur-sm' : ''}`}
            placeholder={t('Share your referral code')}
            value={isLoading ? placeholderCode : referralCode}
            required
            readOnly
          />
          <FiCopy
            className={`mr-3 text-gray-500 cursor-pointer hover:scale-110 hover:text-gray-400 ${isLoading ? 'opacity-50' : ''}`}
            onClick={!isLoading ? handleCopy : undefined}
          />
        </div>
        <span className="text-xs text-gray-500">{t('Share your referral code with your friends and family to get $5 bonus for both you and your friends')}</span>
      </div>
      <hr className="my-16 border-t" />
      {/* Change Password Section */}
      <form onSubmit={handlePasswordChange}>
        <h3 className="mb-8 text-2xl font-semibold">{t('Change Password')}</h3>
        {passwordSuccess && (
          <div className="mb-6 font-medium text-green-400">{passwordSuccess}</div>
        )}
        {errorMessage && (
          <div className="mb-6 font-medium text-red-500">{errorMessage}</div>
        )}
        <div className="mb-6">
          <label className="block mb-2">{t('Current Password')}</label>
          <div className="flex items-center py-2 border-b border-gray-600">
            <FiLock className="mr-3 text-gray-500" />
            <input
              type="password"
              className="px-2 py-1 mr-3 w-full leading-tight bg-transparent border-none appearance-none focus:outline-none"
              placeholder={t('Enter your current password')}
              value={currentPasswordForPassword}
              onChange={(e) => setCurrentPasswordForPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2">{t('New Password')}</label>
          <div className="flex items-center py-2 border-b border-gray-600">
            <FiLock className="mr-3 text-gray-500" />
            <input
              type="password"
              className="px-2 py-1 mr-3 w-full leading-tight bg-transparent border-none appearance-none focus:outline-none"
              placeholder={t('Enter your new password')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-10">
          <label className="block mb-2">{t('Confirm New Password')}</label>
          <div className="flex items-center py-2 border-b border-gray-600">
            <FiLock className="mr-3 text-gray-500" />
            <input
              type="password"
              className="px-2 py-1 mr-3 w-full leading-tight bg-transparent border-none appearance-none focus:outline-none"
              placeholder={t('Confirm your new password')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="py-4 w-full font-semibold text-purple-50 bg-purple-700 rounded-lg transition duration-300 hover:bg-purple-700/80"
        >
          {t('Update Password')}
        </button>
      </form>
    </div>
  );
};

export default Settings;
