import React, { useState, useEffect, useMemo, useRef } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { FaCog, FaWallet, FaEye, FaSpinner } from "react-icons/fa";
import BetHistory from "./BetHistory";
import DepositModal from "../DepositModal";
import Pagination from "../Pagination";
import WithdrawalModal from "../WithdrawalModal";
import AssetRecords from "./AssetRecords"; // Import AssetRecords component
import { Tooltip } from "flowbite-react";
import { useApi } from "../../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import { useTour } from '../../contexts/TourContext';
import BalanceModal from "./BalanceModal";

function Dashboard() {
  const { t, i18n } = useTranslation();
  const {
    user,
    balance,
    fetchBalance,
    hasBonus,
    totalBonus,
    balanceError,
    isBalanceLoading,
    forceBalanceRefresh,
    hasCommission,
    totalCommission,
    withdrawableCommission,
    canWithdrawCommission
  } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPaymentStatus = searchParams.get("status");
  const { tourState, activeTab: tourActiveTab } = useTour();

  // Bet history state variables
  const [betHistory, setBetHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [totalHistoryPage, setTotalHistoryPage] = useState(0);
  const [coinList, setCoinList] = useState([]);
  const [finsolBankList, setFinsolBankList] = useState([]);

  // Tab state - use tour active tab if available
  const [localActiveTab, setLocalActiveTab] = useState("assetRecords");
  const activeTab = tourActiveTab || localActiveTab;
  const setActiveTab = (tab) => {
    setLocalActiveTab(tab);
  };

  const location = useLocation();
  const { shouldOpenDepositModal } = location.state || {};
  const { apiHost, apiVersion } = useApi();
  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    const fetchBetHistory = async () => {
      setHistoryLoading(true);

      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/transaction-history`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              type: "order",
              page: currentHistoryPage,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `${t('Error fetching bet history')}: ${response.statusText}`
          );
        }

        const data = await response.json();
        setBetHistory(data.order.data);
        setTotalHistoryPage(data.order.last_page);
      } catch (error) {
        console.error("Error fetching bet history:", error);
        setBetHistory([]);
      } finally {
        setHistoryLoading(false);
      }
    };

    fetchBetHistory();
  }, [currentHistoryPage]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/topup/currency-list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`${t('Network response was not ok')}`);
        }

        const data = await response.json();

        setCoinList(data.data);
      } catch (error) {
        console.error("Error fetching currency list:", error);
      }
    };

    fetchList();
  }, []);

  useEffect(() => {
    const fetchBankList = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/withdraw/bank-list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`${t('Network response was not ok')}`);
        }

        const data = await response.json();
        setFinsolBankList(data.currency_bank_list);
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    };

    fetchBankList();
  }, []);

  const handleSettingsClick = () => {
    navigate("/user/settings");
  };

  const handlePageChange = (page) => {
    setCurrentHistoryPage(page);
  };

  // Deposit state variables
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const toggleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
  };

  const handleDepositModal = () => {
    setIsPaymentProcessed(false);
    toggleDepositModal();
  };

  useEffect(() => {
    if (shouldOpenDepositModal) {
      setTimeout(() => {
        handleDepositModal();
      }, 500);
    }
  }, [shouldOpenDepositModal]);

  useEffect(() => {
    if (urlPaymentStatus) {
      setIsPaymentProcessed(true);
      setPaymentStatus(urlPaymentStatus);

      toggleDepositModal();

      searchParams.delete("status");
      searchParams.delete("gateway");
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlPaymentStatus, setSearchParams, searchParams]);

  // Withdrawal state variables
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [isWithdrawalProcessed, setIsWithdrawalProcessed] = useState(false);
  const [showBalanceModal, setShowBalanceModal] = useState(false);

  const toggleBalanceModal = () => {
    setShowBalanceModal(!showBalanceModal);
  };

  const toggleWithdrawalModal = () => {
    setShowWithdrawalModal(!showWithdrawalModal);
  };

  const handleWithdrawalModal = async () => {
    setIsWithdrawalProcessed(false);
    await forceBalanceRefresh();
    toggleWithdrawalModal();
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "assetRecords":
        return <AssetRecords />;
      case "betHistory":
        return (
          <>
            <BetHistory bets={betHistory} historyLoading={historyLoading} />
            {!historyLoading && totalHistoryPage > 1 && (
              <Pagination
                totalPages={totalHistoryPage}
                currentPage={currentHistoryPage}
                onPageChange={handlePageChange}
                isLoading={historyLoading}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  const formattedBalance = useMemo(() => {

    let totalBalance = balance;

    totalBalance += totalBonus || 0;

    totalBalance += totalCommission || 0;

    return totalBalance !== null ? totalBalance.toFixed(2) : '0.00';
  }, [balance, totalBonus, totalCommission]);

  const depositModalRef = useRef(null);
  const withdrawalModalRef = useRef(null);
  const balanceModalRef = useRef(null);

  return (
    <div className="p-4 mt-20 dashboard-overview">
      {/* Header */}
      <div className="flex flex-col justify-between items-center p-6 bg-gradient-to-r from-purple-400 to-purple-700 rounded-lg shadow-lg sm:flex-row dashboard-header">
        <div>
          <h2 className="mb-2 text-3xl font-bold text-white">
            {t('Welcome back')}, {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : `${t('Guest')}`)}
          </h2>
          <p className="text-sm text-gray-200">
            {t('Here\'s what\'s happening with your account today.')}
          </p>
        </div>
        <button
          className="mt-4 text-white hover:text-gray-300 focus:outline-none sm:mt-0 settings-button"
          onClick={handleSettingsClick}
        >
          <FaCog size={24} />
        </button>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 gap-6 mt-8 lg:grid-cols-3">
        {/* Balance Card */}
        <div className="col-span-1 p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800 balance-card">
          <div className="flex items-center">
            <div className="p-4 text-white bg-purple-500 rounded-full">
              <FaWallet size={24} />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">{t('Total Balance')}</h3>
              {balanceError ? (
                <div className="text-sm text-red-500">
                  {t('Error loading balance. Please try again.')}
                </div>
              ) : isBalanceLoading ? (
                <div className="flex items-center text-2xl">
                  <FaSpinner className="mr-2 animate-spin" />
                  {t('Loading')}...
                </div>
              ) : (
                <div className="flex gap-2 items-center text-2xl font-bold">
                  <span>${formattedBalance}</span>
                  <FaEye size={18} onClick={toggleBalanceModal} className="transition hover:text-gray-500 dark:hover:text-gray-300 hover:scale-110" />
                </div>
              )}
            </div>
          </div>
          <div className="flex mt-6 space-x-2 deposit-withdraw-buttons">
            <button
              className="flex-1 px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-600"
              onClick={handleDepositModal}
            >
              {t('Deposit')}
            </button>
            <button
              className="flex-1 px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-600"
              onClick={handleWithdrawalModal}
            >
              {t('Withdraw')}
            </button>
          </div>
        </div>

        {/* Additional Cards */}
        <div className="grid grid-cols-1 col-span-1 gap-6 lg:col-span-2 md:grid-cols-2 additional-cards">
          {/* Topup Banner*/}
          <div className="overflow-hidden h-full bg-white rounded-lg shadow-lg dark:bg-gray-800">
            <img
              src={require('../../assets/images/bonus_banner.jpg')}
              alt="Double Your Funds"
              className="object-cover w-full h-full"
            />
          </div>

          {/* Statistics */}
          <div className="p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800">
            <h3 className="mb-4 text-lg font-semibold">{t('Statistics')}</h3>
            {/* Placeholder for statistics */}
            <p className="text-gray-400">{t('No statistics available.')}</p>
          </div>
        </div>
      </div>

      {/* Simple Tabs for Asset Records and Bet History */}
      <div className="p-6 mt-8 bg-white rounded-lg shadow-lg dark:bg-gray-800 records-tab">
        <div className="flex border-b border-gray-700">
          <button
            onClick={() => setActiveTab("assetRecords")}
            className={`flex-1 text-center py-2 ${activeTab === "assetRecords"
              ? "border-b-2 border-purple-500 text-purple-500"
              : "dark:text-gray-400 dark:hover:text-gray-200"
              }`}
          >
            {t('Asset Records')}
          </button>
          <button
            onClick={() => setActiveTab("betHistory")}
            className={`flex-1 text-center py-2 ${activeTab === "betHistory"
              ? "border-b-2 border-purple-500 text-purple-500"
              : "dark:text-gray-400 dark:hover:text-gray-200"
              }`}
          >
            {t('Bet History')}
          </button>
        </div>
        <div className="mt-4">{renderTabContent()}</div>
      </div>

      {/* Deposit Modal */}
      {showDepositModal && (
        <DepositModal
          ref={depositModalRef}
          onClose={toggleDepositModal}
          isPaymentProcessed={isPaymentProcessed}
          paymentStatus={paymentStatus}
          currnecyList={coinList}
        />
      )}

      {/* Withdrawal Modal */}
      {showWithdrawalModal && (
        <WithdrawalModal
          ref={withdrawalModalRef}
          onClose={toggleWithdrawalModal}
          isWithdrawalProcessed={isWithdrawalProcessed}
          currnecyList={coinList}
          fetchBalance={forceBalanceRefresh}
          finsolBankList={finsolBankList}
        />
      )}

      {/* Balance Modal */}
      {showBalanceModal && (
        <BalanceModal
          ref={balanceModalRef}
          onClose={toggleBalanceModal}
          balance={balance}
          totalBonus={totalBonus}
          totalCommission={totalCommission}
          formattedBalance={formattedBalance}
        />
      )}
    </div>
  );
}

export default Dashboard;
