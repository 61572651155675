import React, { useState, useEffect, forwardRef, useMemo } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import pendingPaymentImage from '../assets/images/pending-payment.png';
import { useAuth } from "../AuthContext";
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import { validateNumericInput } from "../utils/inputValidation";
import { FaPlus } from "react-icons/fa";
import '/node_modules/flag-icons/css/flag-icons.min.css';
import CurrencySelect from './CurrencySelect';

const WithdrawalModal = forwardRef(({ onClose, isWithdrawalProcessed, currnecyList, fetchBalance, finsolBankList }, ref) => {
	// State variables
	const { hasBonus, totalBonus, canWithdrawBonus, balance, hasCommission, totalCommission, withdrawableCommission, canWithdrawCommission } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [walletAddress, setWalletAddress] = useState("");
	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState("");
	const [gateway, setGateway] = useState("");
	const [error, setError] = useState("");
	const [coinList, setCoinList] = useState(currnecyList);
	const { t } = useTranslation();
	const [coinGroupList, setCoinGroupList] = useState({
		"Crypto Currencies": [],
		"Fiat Currencies": [],
	});
	const [bankSelect, setBankSelect] = useState("");
	const [filteredBankList, setFilteredBankList] = useState(
		Array.isArray(finsolBankList) ? finsolBankList : Object.values(finsolBankList)
	);
	const [minAmount, setMinAmount] = useState(100);
	const [maxAmount, setMaxAmount] = useState(null);
	const [processedPayment, setProcessedPayment] = useState(isWithdrawalProcessed);
	const [paymentType, setPaymentType] = useState("");
	const [accountName, setAccountName] = useState(""); // New state variable
	const [accountEmail, setAccountEmail] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");

	const { apiHost, apiVersion } = useApi();
	const [currentStep, setCurrentStep] = useState(1);

	const totalWithdrawableAmount = useMemo(() => {
		let total = balance;

		if (hasBonus && canWithdrawBonus) {
			total += totalBonus;
		}
		if (hasCommission && canWithdrawCommission) {
			total += withdrawableCommission;
		}
		return total;
	}, [hasBonus, canWithdrawBonus, totalBonus, hasCommission, canWithdrawCommission, withdrawableCommission, balance]);

	// Add step validation functions
	const canProceedToStep2 = () => currency !== "";
	const canProceedToStep3 = () => ((gateway === "finsol" && bankSelect === "SAFM") ? mobileNumber !== "" : walletAddress !== "")
		&& (paymentType === "crypto" || bankSelect !== "")
		&& (gateway === "finsol" ? accountName !== "" && accountEmail !== "" && mobileNumber !== "" : true)
		&& (gateway === "surepay" ? accountName !== "" : true)
	const canProceedToFinal = () => amount > 0;

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	useEffect(() => {
		setCoinList(currnecyList);

		let fiatC = [];
		let cryptoC = [];

		currnecyList.forEach(currency => {
			if (currency.type === "fiat") {
				fiatC.push(currency)
			} else if (currency.type === "crypto") {
				cryptoC.push(currency)
			}
		});

		setCoinGroupList({
			"Crypto Currencies": cryptoC,
			"Fiat Currencies": fiatC,
		})
	}, [currnecyList]);

	useEffect(() => {
		const selectedCurrency = coinList.find(item => item.value === currency);

		if (selectedCurrency) {
			setPaymentType(selectedCurrency.type) // crypto or fiat
			setGateway(selectedCurrency.gateway);

			if (selectedCurrency.deposit_max <= 0) {
				setMinAmount(Math.floor(selectedCurrency.withdraw_min * 100) / 100);
				setMaxAmount(null)
			} else {
				setMinAmount(Math.floor(selectedCurrency.withdraw_min * 100) / 100);
				setMaxAmount(Math.floor(selectedCurrency.withdraw_max * 100) / 100) // Round down to 2 decimal places
			}
		};
	}, [currency, setGateway]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		// Validate all required fields
		if (!currency) {
			setError(t("Please select a currency"));
			return;
		}

		if (paymentType === "fiat" && !bankSelect) {
			setError(t("Please select a bank"));
			return;
		}

		if (paymentType === "fiat" && gateway === "surepay" && !accountName) {
			setError(t("Please enter an account name"));
			return;
		}

		if (paymentType === "fiat" && gateway === "finsol") {
			if (!accountName) {
				setError(t("Please enter an account name"));
				return;
			}

			if (!accountEmail) {
				setError(t("Please enter an account email"));
				return;
			}

			if (!mobileNumber) {
				setError(t("Please enter an account number"));
				return;
			}
		}

		if (bankSelect !== "SAFM" && !walletAddress) {
			setError(t("Please enter a wallet address"));
			return;
		}

		if (amount <= 0) {
			setError(t("Amount is required"));
			return;
		}

		if (amount < minAmount) {
			setError(`Minimum withdrawal amount is ${minAmount}`);
			return;
		}

		if (amount > maxAmount) {
			setError(`Maximum withdrawal amount is ${maxAmount}`);
			return;
		}

		setError("");

		setIsLoading(true);

		try {
			const token = localStorage.getItem("sanctum_token");

			const response = await fetch(`${apiHost}${apiVersion}/withdraw/create`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					amount: amount,
					currency: currency,
					gateway: gateway,
					wallet_address: bankSelect !== "SAFM" ? walletAddress : mobileNumber,
					bank_select: paymentType === 'fiat' ? bankSelect : "",
					bank_account_name: paymentType === 'fiat' && (gateway === "surepay" || gateway === "finsol") ? accountName : "",
					bank_account_email: paymentType === 'fiat' && gateway === "finsol" ? accountEmail : "",
					mobile_number: paymentType === 'fiat' && gateway === "finsol" ? mobileNumber : ""
				}),
			});

			const contentType = response.headers.get("content-type");

			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				console.log(data)
				if (response.ok) {
					fetchBalance();

					setProcessedPayment(true);

					if (paymentType == "crypto") {
						if (!data.success) {
							alert(data.message);
						}
					}
				} else {
					setError(`${t('Sorry')} ` + data.message);
				}
			} else {
				const text = await response.text();
				console.error("Non-JSON response:", text);
				setError(`${t('Unexpected response format')}: ${text}`);
			}

		} catch (error) {
			console.error('Fetch Error:', error);
			setError(error.message || t("An error occurred, please try again later."));
		} finally {
			setIsLoading(false);
		}
	};

	const WithdrawalStatus = () => {
		return <CryptoWithdrawalStatus />
	}

	const CryptoWithdrawalStatus = () => {
		return <>
			<div className="p-6">
				<div className="flex flex-col items-center mb-2 text-center">
					<img src={pendingPaymentImage} alt="" className="w-24" />
					<h2 className="mt-6 text-xl font-semibold sm:text-2xl text-slate-900">
						{t('Withdrawal is processing')}
					</h2>
					<p className="mt-2 text-sm sm:text-base text-slate-600">
						{t('Your withdrawal is processing, please check your withdrawal address in a moment')}
					</p>
				</div>
			</div>
		</>
	}

	const renderStepContent = () => {
		switch (currentStep) {
			case 1:
				return (
					<div className="mb-4">
						<label htmlFor="currency" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
							{t('Select Currency')}
						</label>
						<CurrencySelect
							value={currency}
							onChange={setCurrency}
							options={coinList}
						/>
					</div>
				);
			case 2:
				return (
					<div className="mb-4">
						{paymentType === 'crypto' ? (
							<>
								<label htmlFor="walletAddress" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
									{t('Wallet Address')}
								</label>
								<input
									name="walletAddress"
									type="text"
									value={walletAddress}
									placeholder={t("Enter your wallet address")}
									onChange={(e) => setWalletAddress(e.target.value)}
									required
									className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
								/>
							</>
						) : (
							<>
								<label htmlFor="bankSelect" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
									{t('Select Bank')}
								</label>
								<select
									name="bankSelect"
									value={bankSelect}
									onChange={(e) => setBankSelect(e.target.value)}
									required
									className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
								>
									<option value="">{t('Select a bank')}</option>
									{filteredBankList.map((bank) => (
										<option key={bank.id} value={bank.id}>
											{bank.bank_name}
										</option>
									))}
								</select>
								{bankSelect && (
									<>
										{(gateway === "surepay" || gateway === "finsol") && (
											<div className="mt-4">
												<label htmlFor="accountName" className="text-sm font-semibold text-slate-900 dark:text-slate-300">
													{t('Account Name')}
												</label>
												<input
													name="accountName"
													type="text"
													value={accountName}
													onChange={(e) => setAccountName(e.target.value)}
													required
													className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
													placeholder={t("Enter your account name")}
												/>
											</div>
										)}
										{gateway === "finsol" && (
											<>
												<div className="mt-4">
													<label htmlFor="accountEmail" className="text-sm font-semibold text-slate-900 dark:text-slate-300">
														{t('Account Email')}
													</label>
													<input
														name="accountEmail"
														type="email"
														value={accountEmail}
														onChange={(e) => setAccountEmail(e.target.value)}
														required
														className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
														placeholder={t("Enter your account email")}
													/>
												</div>
												<div className="mt-4">
													<label htmlFor="mobileNumber" className="text-sm font-semibold text-slate-900 dark:text-slate-300">
														{t('Mobile Number')}
													</label>
													<div className="relative">
														<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
															<span className="text-gray-500"><FaPlus size={14} /></span>
														</div>
														<input
															name="mobile_number"
															type="number"
															value={mobileNumber}
															onChange={(e) => setMobileNumber(e.target.value)}
															onKeyDown={validateNumericInput}
															required
															className="block py-2 pr-4 pl-8 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
															placeholder="Mobile Number"
														/>
													</div>
												</div>
											</>
										)}
										{bankSelect !== "SAFM" && (
											<div className="mt-4">
												<label htmlFor="accountNumber" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
													{t('Account Number')}
												</label>
												<input
													name="accountNumber"
													type="text"
													value={walletAddress}
													onChange={(e) => setWalletAddress(e.target.value)}
													required
													className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
													placeholder={t("Enter your account number")}
												/>
											</div>
										)}
									</>
								)}
							</>
						)}
					</div>
				);
			case 3:
				return (
					<>
						<div className="mb-4">
							<label htmlFor="amount" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
								{t('Enter Amount')} <span className="text-xs text-gray-400">({t('Available')}: {`$${totalWithdrawableAmount}`})</span>
							</label>
							<div className="relative">
								<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
									<span className="text-gray-500">USD</span>
								</div>
								<input
									name="amount"
									type="number"
									min={minAmount}
									max={maxAmount}
									value={amount}
									step="0.01"
									onChange={(e) => setAmount(e.target.value)}
									required
									className="block py-2 pr-4 pl-12 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:border-gray-700 text-slate-900 dark:text-slate-300 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-black"
									placeholder="0.00"
								/>
							</div>
						</div>
					</>
				);
			default:
				return null;
		}
	};

	const handlePreviousStep = () => {
		setCurrentStep(prev => prev - 1);
	}

	const handleNextStep = () => {
		const nextStep = currentStep + 1;

		if (nextStep === 2 && gateway === "finsol" || nextStep === 2 && gateway === "expay-fiat" || nextStep === 2 && gateway === "surepay") {
			const bankList = finsolBankList[currency];
			setFilteredBankList(
				Array.isArray(bankList) ? bankList : Object.values(bankList)
			);
		}

		setCurrentStep(nextStep);
	}

	return (
		<div
			id="deposit-modal"
			tabIndex="-1"
			className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-start min-h-screen bg-black/50"
		>
			<div ref={ref} className="relative p-4 mt-8 mb-8 w-full max-w-md bg-white rounded-lg shadow-md dark:bg-slate-800 sm:p-6">
				<button
					type="button"
					className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
					onClick={onClose}
				>
					<XMarkIcon className="w-5 h-5 text-gray-500 sm:h-6 sm:w-6" />
					<span className="sr-only">{t('Close popup')}</span>
				</button>
				{!processedPayment
					? <>
						<div className="p-6">
							<div className="mb-6 text-center">
								<h2 className="text-xl font-semibold sm:text-2xl">
									{t('Withdraw')}
								</h2>
								<p className="mt-2 text-sm sm:text-base text-slate-600 dark:text-slate-400">
									{t('Step')} {currentStep} {t('of')} 3
								</p>
							</div>

							<div className="flex justify-center mb-6">
								<div className="flex items-center">
									{[1, 2, 3].map((step) => (
										<React.Fragment key={step}>
											<div className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep >= step ? 'bg-purple-700/80 text-purple-200 font-semibold' : 'bg-purple-200 text-purple-700'
												}`}>
												{step}
											</div>
											{step < 3 && (
												<div className={`w-12 h-1 ${currentStep > step ? 'bg-purple-700/80 text-purple-200 font-semibold' : 'bg-purple-200 text-purple-700'
													}`} />
											)}
										</React.Fragment>
									))}
								</div>
							</div>

							<form onSubmit={handleSubmit}>
								{renderStepContent()}
								{error && <p className="mb-4 text-red-500">{error}</p>}

								<div className="flex gap-3">
									{currentStep > 1 && (
										<button
											type="button"
											onClick={handlePreviousStep}
											className="flex-1 py-3 font-medium text-black rounded-lg border border-black dark:text-slate-300 dark:border-gray-700"
										>
											{t('Back')}
										</button>
									)}

									{currentStep < 3 ? (
										<button
											type="button"
											onClick={handleNextStep}
											disabled={
												(currentStep === 1 && !canProceedToStep2()) ||
												(currentStep === 2 && !canProceedToStep3())
											}
											className="flex-1 py-3 font-medium text-white bg-black rounded-lg disabled:opacity-50 dark:bg-slate-900 dark:text-slate-300"
										>
											{t('Next')}
										</button>
									) : (
										<button
											type="submit"
											disabled={isLoading || !canProceedToFinal()}
											className="flex-1 py-3 font-medium text-white bg-black rounded-lg disabled:opacity-50 dark:bg-slate-900 dark:text-slate-300"
										>
											{isLoading ? `${t('Loading')}...` : t("Submit")}
										</button>
									)}
								</div>
							</form>
						</div>
					</>
					: <WithdrawalStatus />
				}
			</div>
		</div>
	);
});

export default WithdrawalModal;