import React, { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import Countdown from "./Countdown";
import { Range } from "react-range";
import { useParams } from "react-router-dom";
import { FaMinus, FaPlus, FaCoins, FaCircleNotch } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useAuth } from "../AuthContext";
import Confetti from "react-confetti";
import { useApi } from "../contexts/ApiContext";
import LoginForm from "./LoginForm";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "flowbite-react";

const BetPanel = ({
  type,
  targetDate,
  id,
  min = 2,
  max = 100,
  odds,
  realTimePrice,
  errorMessage,
  setErrorMessage,
  showError,
  setShowError,
  handleCloseError,
  coinTicker,
  coinName,
  timeframe,
  className,
}) => {
  const [price, setPrice] = useState(null);
  const [potentialWinnings, setPotentialWinnings] = useState({
    first: 0,
    second: 0,
    third: 0,
  });
  const {
    balance,
    fetchBalance,
    isUserOnTrialMode,
    trialAttempts,
    setTrialAttempts,
    verifyUserTrial,
    isAuthenticated, // Assume this is provided by useAuth
  } = useAuth();
  const [betAmount, setBetAmount] = useState(50);
  const { coin } = useParams();
  const lowerBound = realTimePrice * 0.7;
  const upperBound = realTimePrice * 1.3;
  const [showMore, setShowMore] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLastTwoHours, setIsLastTwoHours] = useState(false);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [minValue, setMinValue] = useState(min);
  const lastMinute = type === "crypto" ? 5 : 120;
  const totalTrial = 3;

  // State for the modal
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState("login");

  const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();

  // Functions for opening login or register modals
  const toggleModal = () => setShowModal(!showModal);
  const openLoginForm = () => {
    setFormMode("login");
    toggleModal();
  };

  useEffect(() => {
    let symbol = coinTicker;

    const fetchPrice = async () => {
      const url = `${apiHost}${apiVersion}/polygon/candles/chart?symbol=${symbol}`;

      try {
        const response = await fetch(
          url
        );
        const rawData = await response.json();
        const data = rawData.map((item) => parseFloat(item.close));
        setPrice(data[data.length - 1]);
      } catch (error) {
        console.error("Error fetching live price:", error);
        setPrice(60000);
      }
    };

    if (coin) {
      fetchPrice();
    }
  }, [coin]);

  const handlePriceChange = (amount) => {
    setErrorMessage("");
    const newPrice = (price !== null ? price : 0) + amount;

    if (newPrice <= lowerBound) {
      setErrorMessage(
        `${t('Your predicted price is currently lower than 30% of the current price')} (${realTimePrice.toFixed(2)}).`
      );
    } else if (newPrice >= upperBound) {
      setErrorMessage(
        `${t('Your predicted price is currently higher than 30% of the current price')} (${realTimePrice.toFixed(2)}).`
      );
    }

    setPrice(newPrice);
  };

  const handlePriceBlur = () => {
    setErrorMessage("");
    if (price <= lowerBound) {
      setErrorMessage(
        `${t('Your predicted price is currently lower than 30% of the current price')} (${realTimePrice.toFixed(2)}).`
      );
    } else if (price >= upperBound) {
      setErrorMessage(
        `${t('Your predicted price is currently higher than 30% of the current price')} (${realTimePrice.toFixed(2)}).`
      );
    }
  };

  const handleBetAmountChange = (value) => {
    let numericValue;

    if (Array.isArray(value)) {
      numericValue = value[0];
    } else {
      numericValue = value.toString().replace(/[^0-9]/g, "");
    }

    numericValue = numericValue === "" ? 0 : parseInt(numericValue, 10);

    if (numericValue < minValue) {
      numericValue = minValue;
    } else if (numericValue > max) {
      numericValue = max;
    }

    setBetAmount(numericValue);
  };

  const handleInputBetAmountChange = (value) => {
    let numericValue;

    if (Array.isArray(value)) {
      numericValue = value[0];
    } else {
      numericValue = value.toString().replace(/[^0-9]/g, "");
    }

    numericValue =
      numericValue === "" || !numericValue ? 0 : parseInt(numericValue, 10);

    setBetAmount(numericValue);
  };

  const handleBetSubmit = async () => {
    setErrorMessage("");
    if (!isAuthenticated) {
      openLoginForm();
      return;
    }

    let amount = parseFloat(betAmount);

    if (amount > balance && !isUserOnTrialMode) {
      setErrorMessage(t("Insufficient balance."));
      setShowError(true);
      setTimeout(handleCloseError, 3000);
      return;
    }

    // Validate amount with min
    if (amount < min) {
      setErrorMessage(`${t('Minimum amount is')} ${min}`);
      setShowError(true);
      setTimeout(handleCloseError, 3000);
      return;
    }

    setIsProcessingOrder(true);

    try {
      const token = localStorage.getItem("sanctum_token");
      const response = await fetch(
        `${apiHost}${apiVersion}/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
            prediction: price,
            amount: parseFloat(amount),
          }),
        }
      );

      if (response.ok) {
        // Start confetti and show success message
        setShowConfetti(true);
        setOrderSuccess(true);
        fetchBalance();

        if (trialAttempts < totalTrial) {
          const newTotalAttempts = trialAttempts + 1;
          setTrialAttempts(newTotalAttempts);
        }

        verifyUserTrial();
      } else {
        setOrderError(true);
      }

      setIsProcessingOrder(false);
    } catch (error) {
      console.error("Error placing bet:", error);
      setOrderError(true);

      setIsProcessingOrder(false);
    }
  };

  useEffect(() => {
    if (odds) {
      setPotentialWinnings({
        first: betAmount * Number(odds.first),
        second: betAmount * Number(odds.second),
        third: betAmount * Number(odds.third),
      });
    }
  }, [betAmount, odds]);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti disappears after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  // Watch the user trial mode then apply or do not apply the bonus
  useEffect(() => {
    setMinValue(min);
    setBetAmount(50);
  }, []);

  // Add an effect to watch the countdown status
  useEffect(() => {
    const countDownDate = new Date(targetDate + "Z").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      setIsLastTwoHours(distance <= lastMinute * 60 * 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const isDisabled =
    price === null ||
    betAmount === "" ||
    isNaN(parseFloat(betAmount)) ||
    (betAmount > balance && !isUserOnTrialMode) ||
    isLastTwoHours ||
    isProcessingOrder ||
    (isUserOnTrialMode && trialAttempts >= totalTrial);

  return (
    <div className={`overflow-hidden relative p-6 space-y-6 bg-gradient-to-br from-gray-100 via-gray-50 to-gray-200 rounded-lg shadow-2xl dark:from-gray-800 dark:via-gray-900 dark:to-black dark:text-gray-200 bet-panel ${className}`}>
      {/* Confetti Effect */}
      {showConfetti && <Confetti />}
      {/* Success Message */}
      {orderSuccess && (
        <div className="flex absolute inset-0 z-50 flex-col justify-center items-center px-4 bg-gray-400 bg-opacity-75 rounded-lg dark:bg-gray-700">
          <div className="p-6 space-y-4 text-white bg-green-600 rounded-lg">
            <h2 className="text-2xl font-bold">{t('Bet Placed Successfully!')}</h2>
            <p className="text-lg">{t('Your bet has been placed. Good luck!')}</p>
            <button
              className="py-2 w-full text-lg font-semibold bg-green-700 rounded-md hover:bg-green-800"
              onClick={() => setOrderSuccess(false)}
            >
              {t('Close')}
            </button>
          </div>
        </div>
      )}

      {/* Error Message */}
      {orderError && (
        <div className="flex absolute inset-0 z-50 flex-col justify-center items-center px-4 bg-gray-400 bg-opacity-75 rounded-lg dark:bg-gray-700">
          <div className="p-6 space-y-4 text-white bg-red-600 rounded-lg">
            <h2 className="text-2xl font-bold">{t('Error Placing Bet!')}</h2>
            <p className="text-lg">
              {t('Your bet has not been placed. Please try again.')}
            </p>
            <button
              className="py-2 w-full text-lg font-semibold bg-red-700 rounded-md hover:bg-red-800"
              onClick={() => setOrderError(false)}
            >
              {t('Close')}
            </button>
          </div>
        </div>
      )}

      {/* Modal for Login/Register */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost={apiHost}
          apiVersion={apiVersion}
        />
      )}

      <div className="flex items-center space-x-3">
        <FaCoins className="text-2xl text-yellow-400 animate-pulse" />
        <div className="flex flex-col w-full">
          <div className="flex gap-2 justify-between items-center">
            <h3 className="text-xl font-semibold">{coinName}</h3>
            <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-purple-600 rounded dark:text-gray-100 bg-purple-600/20">{timeframe}</span>
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-semibold dark:text-gray-200">{t('Results')}: </p>
            {targetDate ? (
              <Countdown
                targetDate={targetDate}
                className="text-green-400"
                warningTime={lastMinute}
              />
            ) : (
              <div className="flex justify-center px-4 py-2">
                <FaCircleNotch className="text-lg font-semibold animate-spin sm:text-sm" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative prediction-input">
        <label className="block mb-1 text-sm font-medium">
          {t('Predicted Price')}
        </label>
        <div className="flex">
          <button
            className="px-3 py-2 bg-gray-300 rounded-l-md dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
            onClick={() => handlePriceChange(-1)}
          >
            <FaMinus />
          </button>
          <input
            type="number"
            className="w-full text-center border-r-0 border-l-0 border-gray-300 dark:border-gray-700 dark:bg-gray-800 focus:outline-none"
            value={price !== null ? price : ""}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
            onBlur={handlePriceBlur}
          />
          <button
            className="px-3 py-2 bg-gray-300 rounded-r-md dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
            onClick={() => handlePriceChange(1)}
          >
            <FaPlus />
          </button>
        </div>
      </div>

      <div className="prediction-amount-input">
        <label className="block mb-1 text-sm font-medium">
          {t('Trade Amount')}{" "}
          {isUserOnTrialMode ? <>({t('Trial')})</> : <>(${balance} {t('available')})</>}
        </label>

        <div className="relative">
          <RiMoneyDollarCircleFill className="absolute top-3 left-3 text-gray-400" />
          <input
            type="text"
            className="py-2 pr-3 pl-10 w-full rounded-md border-gray-300 dark:border-gray-700 dark:bg-gray-800 focus:outline-none"
            value={betAmount === 0 ? "" : betAmount}
            onChange={(e) => handleInputBetAmountChange(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Range
            step={1}
            min={minValue}
            max={max}
            values={[betAmount]}
            onChange={(values) => handleBetAmountChange(values[0])}
            renderTrack={({ props, children }) => {
              const percentage =
                betAmount > 0
                  ? ((betAmount - minValue) / (max - minValue)) * 100
                  : 0;
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "10px",
                    background: `linear-gradient(90deg, #ff7f00, #ff00ff, #00ffff, #0000ff ${percentage}%, #1f2937 ${percentage}%)`,
                    borderRadius: "5px",
                  }}
                >
                  {children}
                </div>
              );
            }}
            renderThumb={({ props }) => {
              const { key, ...restProps } = props;
              return (
                <div
                  key={key}
                  {...restProps}
                  className="w-6 h-6 bg-green-500 rounded-full shadow focus:outline-none"
                />
              );
            }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center potential-win">
        <p className="mb-2 text-lg">{t('To Win')}</p>
        <div className="flex justify-around items-center py-4 w-full bg-gray-300 rounded-md dark:bg-gray-800">
          <div className="text-center">
            <span className="text-2xl">🥈</span>
            <p className="mt-1 text-sm">
              2nd: ${potentialWinnings.second.toFixed(2)}
            </p>
          </div>
          <div className="text-center">
            <span className="text-2xl">🥇</span>
            <p className="mt-1 text-sm">
              1st: ${potentialWinnings.first.toFixed(2)}
            </p>
          </div>
          <div className="text-center">
            <span className="text-2xl">🥉</span>
            <p className="mt-1 text-sm">
              3rd: ${potentialWinnings.third.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      <div className="text-sm rounded-md">
        <p className="">
          {showMore
            ? t("If you did not make it onto the list of winners, you will receive a lesser balance back, depending on how accurate your price prediction was.")
            : t("If you do not win, you will not lose your funds.")}
        </p>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-blue-500 hover:underline focus:outline-none"
        >
          {showMore ? t("See Less") : t("See More")}
        </button>
      </div>
      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      <div className="*:!w-auto">
        <Tooltip
          content={
            price === null
              ? t('Please select a price prediction')
              : betAmount === "" || isNaN(parseFloat(betAmount))
                ? t('Please enter a valid bet amount')
                : betAmount > balance && !isUserOnTrialMode
                  ? t('Insufficient balance')
                  : isLastTwoHours
                    ? t('Betting is closed before the end of the pool')
                    : isProcessingOrder
                      ? t('Processing your order...')
                      : isUserOnTrialMode && trialAttempts >= totalTrial
                        ? t('You have used all your trial attempts')
                        : ''
          }
          style={isDisabled ? "light" : { display: "none" }}
          placement="top"
        >
          <button
            className={`w-full py-3 rounded-md text-lg font-semibold transition-all duration-300 ${isDisabled
              ? "bg-gray-200 cursor-not-allowed dark:bg-gray-600"
              : "text-green-700 dark:text-green-200 bg-green-500/60 hover:bg-green-600/60"
              }`}
            onClick={handleBetSubmit}
            disabled={isDisabled}
          >
            {isProcessingOrder ? (
              <p className="animate-pulse">Loading...</p>
            ) : (
              <>
                {t('Place Order')}
                <p className="text-sm">
                  {t('Potential Win')}: ${potentialWinnings.first.toFixed(2)}
                </p>
              </>
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default BetPanel;
