import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { GoogleLogin } from "@react-oauth/google";
import OTPScreen from "./User/OTPScreen";
import PasswordRecovery from "./PasswordRecovery";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import RecaptchaV3 from "./ReCaptcha";
import TelegramLogin from './TelegramLogin';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

const LoginForm = ({ onClose, mode = 'login', setFormMode, apiHost, apiVersion }) => {
  // State variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [otpEmail, setOtpEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isReferralValid, setIsReferralValid] = useState(true);
  const captchaToken = useRef('');
  const { t, i18n } = useTranslation();

  const { login, setUserSession } = useAuth();
  const navigate = useNavigate();
  const isLogin = mode === "login";

  // Username validation function
  const validateUsername = (username) => {
    const minLength = 3;
    const allowedCharsRegex = /^[a-zA-Z0-9_]+$/;
    const hasLetterRegex = /[a-zA-Z]/;

    if (username.length < minLength) {
      return `${t('Username must be at least')} ${minLength} ${t('characters long')}.`;
    }
    if (!allowedCharsRegex.test(username)) {
      return t("Username can only contain letters, numbers, and underscores.");
    }
    if (!hasLetterRegex.test(username)) {
      return t("Username cannot consist of numbers only.");
    }
    return "";
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    if (!isLogin) {
      const usernameValidationError = validateUsername(newUsername);
      setUsernameError(usernameValidationError);
    }
  };

  // Password validation function
  const validatePassword = (password) => {
    const minLength = 8;
    let strength = '';

    if (password.length < minLength) {
      strength = t('Password not long enough');
    } else {
      const hasLetters = /[a-zA-Z]/.test(password);
      const hasNumbers = /[0-9]/.test(password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      let strengthScore = 0;
      if (hasLetters) strengthScore++;
      if (hasNumbers) strengthScore++;
      if (hasSpecialChars) strengthScore++;

      if (strengthScore === 1) {
        strength = t('Weak');
      } else if (strengthScore === 2) {
        strength = t('Average');
      } else if (strengthScore === 3) {
        strength = t('Strong');
      }
    }

    setPasswordStrength(strength);

    return password.length >= minLength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!isLogin) {
      validatePassword(newPassword);
    }
  };

  const handleVerifyCaptcha = (token) => {
    captchaToken.current = token;
    //console.log(captchaToken.current);
  };

  const validateReferralCode = async (code) => {
    if (!code) {
      setIsReferralValid(true);
      return;
    }

    if (code.length === 6) {
      try {
        const response = await fetch(`${apiHost}${apiVersion}/validate-referral`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ referral_code: code }),
        });

        const data = await response.json();
        setIsReferralValid(response.ok);

        if (!response.ok) {
          setError(data.message || t("Invalid referral code"));
        } else {
          setError("");
        }
      } catch (error) {
        console.error("Error validating referral code:", error);
        setIsReferralValid(false);
        setError(t("Error validating referral code"));
      }
    } else {
      setIsReferralValid(false);
    }
  };

  const handleReferralChange = (e) => {
    const code = e.target.value;
    setReferralCode(code);

    if (code && code.length === 6) {
      validateReferralCode(code);
    } else {
      setIsReferralValid(!code);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken.current) {
      setCaptchaError(t("Please complete the captcha."));
      return;
    }

    if (!isLogin && password !== confirmPassword) {
      setError(t('Passwords do not match.'));
      return;
    }

    const endpoint = isLogin
      ? `${apiHost}${apiVersion}/login`
      : `${apiHost}${apiVersion}/register`;
    const payload = isLogin
      ? { email, password, captchaToken: captchaToken.current }
      : {
        name: username,
        email,
        password,
        password_confirmation: confirmPassword,
        captchaToken: captchaToken.current,
        referral_code: referralCode,
      };

    setLoading(true);
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        if (response.ok) {
          setSuccess(data.message);
          setError("");
          if (isLogin) {
            setOtpEmail(email);
            setShowOtpScreen(true);
          } else {
            setFormMode("login");
          }
        } else {
          setError(
            data.message ||
            data.errors?.email?.[0] ||
            data.errors?.password?.[0] ||
            data.errors?.name?.[0] ||
            'Invalid credentials'
          );
          setSuccess("");
        }
      } else {
        const text = await response.text();
        console.error("Non-JSON response:", text);
        setError(`${t('Unexpected response format')}: ${text}`);
        setSuccess("");
      }

    } catch (error) {
      console.error('Fetch Error:', error);
      setError(error.message || t("An error occurred, please try again later."));
      setSuccess("");
    } finally {
      setLoading(false);
    }
  };

  // Google Login Success
  const handleGoogleLoginSuccess = async (googleResponse) => {
    setLoading(true);
    console.log("Google Login Success:", googleResponse);

    try {
      const response = await fetch(`${apiHost}${apiVersion}/login/google`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: googleResponse.credential }),
      });

      const data = await response.json();
      if (response.ok) {
        setUserSession(data.user, data.token, data.expires_at);
        onClose();
      } else {
        setError(data.message || t('Failed to login with Google'));
      }
    } catch (error) {
      console.error("Error handling Google login:", error);
    } finally {
      setLoading(false);
    }
  };

  // Google Login Failure
  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
    setError(t("Google login failed. Please try again."));
  };

  // Handle Google login for native platforms
  const handleNativeGoogleLogin = async () => {
    setLoading(true);
    
    try {
      console.log('Starting native Google login with Capacitor Google Auth plugin');
      console.log('Platform:', Capacitor.getPlatform());
      
      // For web platform, we need to initialize differently
      if (Capacitor.getPlatform() === 'web') {
        console.log('Running on web platform, using web implementation');
        // For web, we should use the regular web Google login
        if (window.google && window.google.accounts) {
          window.google.accounts.id.prompt();
        } else {
          setError(t('Google Sign-In is not available in this environment'));
        }
        setLoading(false);
        return;
      }
      
      // Initialize the plugin for native platforms
      console.log('Initializing GoogleAuth plugin');
      await GoogleAuth.initialize();
      
      // Sign in with Google
      console.log('Calling GoogleAuth.signIn()');
      const googleUser = await GoogleAuth.signIn();
      console.log('Google login successful:', googleUser);
      
      // Extract the ID token
      const idToken = googleUser.authentication.idToken;
      
      if (idToken) {
        console.log('Got ID token, sending to backend', idToken);
        
        // Send the token to your backend
        const response = await fetch(`${apiHost}${apiVersion}/login/google`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: idToken }),
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log('Backend login successful, setting user session');
          setUserSession(data.user, data.token, data.expires_at);
          onClose();
        } else {
          const errorData = await response.json();
          console.error('Backend returned error:', errorData);
          setError(errorData.message || t('Failed to login with Google'));
        }
      } else {
        console.error('No ID token received from Google Auth');
        setError(t('Failed to get authentication token. Please try again.'));
      }
    } catch (error) {
      console.error("Error handling native Google login:", error);
      
      // More detailed error logging
      if (error.message) {
        console.error("Error message:", error.message);
      }
      if (error.code) {
        console.error("Error code:", error.code);
      }
      
      // Handle specific error codes
      if (error.code === "UNIMPLEMENTED") {
        setError(t("Google Sign-In is not properly configured for this platform. Please check your setup."));
      } else {
        setError(t("Google login failed. Please try again."));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTelegramLogin = async (telegramUser) => {
    setLoading(true);
    
    try {
      const response = await fetch(`${apiHost}${apiVersion}/login/telegram`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegram_data: telegramUser,
          captchaToken: captchaToken.current,
        }),
        credentials: 'include',
      });

      const data = await response.json();
      
      if (response.ok) {
        setUserSession(data.user, data.token, data.expires_at);
        onClose();
      } else {
        setError(data.message || t('Failed to login with Telegram'));
      }
    } catch (error) {
      console.error('Error handling Telegram login:', error);
      setError(t('Failed to login with Telegram. Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  // Connect Wallet Function
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        setIsConnecting(true);
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        const currentWalletAddress = accounts[0];
        setWalletAddress(currentWalletAddress);

        await new Promise((resolve) => setTimeout(resolve, 100));

        const response = await handleWalletLogin(currentWalletAddress);
        if (response.needRegistration) {
          setError("");
          setShowUsernamePrompt(true);
        } else if (response.success) {
          setSuccess(response.message);
          setError("");
          sessionStorage.setItem("user", JSON.stringify(response.user));
          localStorage.setItem("sanctum_token", response.token);
          login(response.user);
          onClose();
        } else if (response.error) {
          setError(response.error);
          setSuccess('');
        }
      } catch (error) {
        console.error("Error connecting wallet:", error);
        setError(t("Failed to connect wallet. Please try again."));
      } finally {
        setIsConnecting(false);
      }
    } else {
      setError(
        t('MetaMask is not installed. Please install it from ') +
        `<a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">${t('here')}</a>.`
      );
    }
  };

  const handleWalletLogin = async (currentWalletAddress) => {
    const payload = {
      wallet_address: currentWalletAddress,
      captchaToken: captchaToken.current,
    };

    try {
      const response = await fetch(`${apiHost}${apiVersion}/login/wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        if (data.message === "Redirect to register") {
          return { needRegistration: true };
        } else {
          return { success: true, user: data.user, token: data.token, message: data.message };
        }
      } else {
        return { error: data.message || t('Failed to login with wallet') };
      }
    } catch (error) {
      return { error: error.message || t('An error occurred, please try again later.') };
    }
  };

  const handleWalletRegister = async (username) => {
    const usernameValidationError = validateUsername(username);
    if (usernameValidationError) {
      setError(usernameValidationError);
      return;
    }

    const payload = {
      name: username,
      wallet_address: walletAddress,
    };

    try {
      const response = await fetch(`${apiHost}${apiVersion}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess(data.message);
        setError("");
        onClose();
      } else {
        console.error(data.message || t("Registration failed"));
        setSuccess("");
        setError(data.message || t("Registration failed"));
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setError(error.message || t("An error occurred, please try again later."));
      setSuccess("");
    }
  };

  const handleUsernameSubmit = async () => {
    if (!newUsername.trim()) {
      setError(t("Username is required"));
      return;
    }
    await handleWalletRegister(newUsername);
    setShowUsernamePrompt(false);
    setNewUsername("");
    setSuccess(t("Registration successful"));
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-start pt-16 min-h-screen bg-black/50"
    >

      <div className="relative p-4 mt-8 mb-8 w-full max-w-md bg-white rounded-lg shadow-md dark:bg-slate-800 sm:p-6">
        <button
          type="button"
          className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 dark:hover:bg-slate-600 dark:hover:text-gray-900 rounded-full p-1 sm:p-1.5"
          onClick={onClose}
        >
          <XMarkIcon className="w-5 h-5 text-gray-500 sm:h-6 sm:w-6" />
          <span className="sr-only">{t('Close popup')}</span>
        </button>

        {showPasswordRecovery && (
          <PasswordRecovery
            onClose={() => setShowPasswordRecovery(false)}
            apiHost={apiHost}
            apiVersion={apiVersion}
          />
        )}

        {showOtpScreen ? (
          <OTPScreen
            onClose={() => setShowOtpScreen(false)}
            email={otpEmail}
            onSuccess={() => {
              onClose();
              setShowOtpScreen(false);
            }}
          />
        ) : showUsernamePrompt ? (
          <div className="p-6">
            <h2 className="mb-4 text-xl font-semibold sm:text-2xl">
              {t('Enter Username')}
            </h2>
            {error && <p className="mb-4 text-center text-red-500">{error}</p>}
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="block px-4 py-2 mb-4 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:border-gray-600 dark:bg-slate-800 dark:text-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
              placeholder={t("Username")}
            />
            <button
              onClick={handleUsernameSubmit}
              className="py-3 w-full text-base font-medium text-white bg-black rounded-lg sm:text-sm focus:ring-2 focus:ring-black"
            >
              {t('Register')}
            </button>
            <button
              onClick={() => {
                setError("");
                setSuccess("");
                setShowUsernamePrompt(false);
                setFormMode("login");
              }}
              className="py-2 mt-2 w-full text-sm text-center text-gray-600 dark:text-slate-300 hover:underline"
            >
              {t('Cancel')}
            </button>
          </div>
        ) : (
          <div className="p-6">
            <div className="mb-4 text-center">
              <h2 className="text-xl font-semibold sm:text-2xl">
                {isLogin ? t("Login to your account") : t("Create an account")}
              </h2>
              <p className="mt-2 text-sm sm:text-base text-slate-600 dark:text-slate-300">
                {isLogin
                  ? t("You must be logged in to perform this action.")
                  : t("Sign up to get started.")}
              </p>
            </div>

            {error && <p className="mb-4 text-center text-red-500">{error}</p>}
            {success && (
              <p className="mb-4 text-center text-green-500">{success}</p>
            )}

            {/* Social Login */}
            <div className="space-y-4">
              {Capacitor.isNativePlatform() ? (
                  <button
                    onClick={handleNativeGoogleLogin}
                    className="flex justify-center items-center py-2 px-4 w-full text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-100"
                  >
                    <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                      <path
                        fill="#4285F4"
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      />
                      <path
                        fill="#34A853"
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      />
                      <path
                        fill="#EA4335"
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      />
                    </svg>
                    {t('Sign in with Google')}
                  </button>
              ) : (
                <>
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                    useOneTap
                  />
                  <TelegramLogin 
                    onAuth={handleTelegramLogin}
                  />
                </>
              )}
            </div>

            <div className="flex gap-2 items-center py-6 w-full text-sm text-slate-600 dark:text-slate-300">
              <div className="w-full h-px bg-slate-200 dark:bg-slate-600"></div>
              {t('OR')}
              <div className="w-full h-px bg-slate-200 dark:bg-slate-600"></div>
            </div>

            <form className="space-y-4" onSubmit={handleSubmit}>
              {!isLogin && (
                <>
                  <label htmlFor="username" className="sr-only">
                    {t('Username')}
                  </label>
                  <input
                    name="username"
                    type="text"
                    required
                    value={username}
                    onChange={handleUsernameChange}
                    className="block px-4 py-2 w-full text-base text-gray-700 rounded-lg border border-gray-300 shadow-sm dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder={t("Username")}
                  />
                  {usernameError && (
                    <p className="text-sm text-red-500">{usernameError}</p>
                  )}
                </>
              )}
              <label htmlFor="email" className="sr-only">
                {t('Email address')}
              </label>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block px-4 py-2 w-full text-base text-gray-700 rounded-lg border border-gray-300 shadow-sm dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                placeholder={t("Email Address")}
              />
              {!isLogin ? (
                <>
                  <div className="flex justify-between items-center mt-4">
                    <label htmlFor="password" className="text-sm font-medium text-gray-700 dark:text-slate-300">
                      {t('New password (8 characters min)')}
                    </label>
                    <button
                      type="button"
                      className="text-sm text-blue-600 hover:underline"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? t('Hide') : t('Show')}
                    </button>
                  </div>
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    className="block px-4 py-2 w-full text-base text-gray-700 rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:text-slate-300 dark:border-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder={t("Password")}
                  />
                  <p className="mt-2 text-sm">
                    {t('Password strength')}:{" "}
                    <span
                      className={
                        passwordStrength === "Strong"
                          ? "text-green-500"
                          : passwordStrength === "Average"
                            ? "text-yellow-500"
                            : passwordStrength === "Weak"
                              ? "text-red-500"
                              : passwordStrength === "Password not long enough"
                                ? "text-red-500"
                                : ""
                      }
                    >
                      {passwordStrength}
                    </span>
                  </p>

                  {/* Conditionally show this message if the password is weak or average */}
                  {(passwordStrength === "Weak" || passwordStrength === "Average") && (
                    <p className="mt-1 text-sm text-gray-600 dark:text-slate-300">
                      {t('A strong password can improve the security of your account should your device be stolen or compromised.')}
                    </p>
                  )}

                  <label htmlFor="confirm-password" className="sr-only">
                    {t('Confirm Password')}
                  </label>
                  <input
                    name="confirm-password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="block px-4 py-2 mt-4 w-full text-base text-gray-700 rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:text-slate-300 dark:border-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder={t("Confirm Password")}
                  />

                  <div>
                    <label htmlFor="referral_code" className="text-sm font-medium text-gray-700 dark:text-slate-300">
                      {t('Referral Code (optional)')}
                    </label>
                    <input
                      name="referral_code"
                      type="text"
                      value={referralCode}
                      onChange={handleReferralChange}
                      maxLength={6}
                      className={`block w-full text-gray-700 dark:bg-slate-800 dark:text-slate-300 rounded-lg border ${referralCode && !isReferralValid
                        ? 'border-red-500'
                        : 'border-gray-300 dark:border-slate-600'
                        } px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black`}
                      placeholder={t("Referral Code")}
                    />
                    {referralCode && !isReferralValid && (
                      <p className="mt-1 text-sm text-red-500">
                        {referralCode.length === 6 ? t("Invalid referral code") : t("Referral code must be 6 characters")}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="password" className="sr-only">
                    {t('Password')}
                  </label>
                  <input
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block px-4 py-2 w-full text-base text-gray-700 rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 dark:text-slate-300 dark:border-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder={t("Password")}
                  />
                </>
              )}
              {passwordError && (
                <p className="text-sm text-red-500">{passwordError}</p>
              )}
              <RecaptchaV3 action="submit" onVerify={handleVerifyCaptcha} />
              <button
                type="submit"
                className={`w-full py-3 rounded-lg bg-purple-700 text-purple-100 font-medium text-base sm:text-sm focus:ring-2 focus:ring-purple-400 ${(loading || (referralCode && !isReferralValid)) ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={loading || (referralCode && !isReferralValid)}
              >
                {loading ? `${t('Loading')}...` : isLogin ? t("Continue") : t("Sign Up")}
              </button>
              {captchaError && (
                <p className="text-sm text-center text-red-500">
                  {captchaError}
                </p>
              )}
            </form>

            <div className="mt-6 space-y-2 text-sm text-center sm:text-base text-slate-600 dark:text-slate-300">
              {isLogin ? (
                <>
                  <div>
                    {t('Don\'t have an account')}?{" "}
                    <button
                      onClick={() => setFormMode("register")}
                      className="font-medium text-blue-600 hover:underline"
                    >
                      {t('Sign up')}
                    </button>
                  </div>
                  <div>
                    {t('Forgot your password')}?{" "}
                    <button
                      onClick={() => setShowPasswordRecovery(true)}
                      className="font-medium text-blue-600 hover:underline"
                    >
                      {t('Reset it')}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {t('Already have an account')}?{" "}
                  <button
                    onClick={() => setFormMode("login")}
                    className="font-medium text-blue-600 hover:underline"
                  >
                    {t('Login')}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;