import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
const OTPScreen = ({ onClose, email, onSuccess }) => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login, setUserSession } = useAuth();
    const { apiHost, apiVersion } = useApi();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!email) {
            navigate('/', { replace: true });

        }
    }, [email, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiHost}${apiVersion}/login/otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, otp }),
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setUserSession(data.user, data.token, data.expires_at);
                onSuccess();
                onClose();
            } else {
                const data = await response.json();
                setError(data.message);
            }
        } catch (error) {
            setError(t('An error occurred. Please try again later.'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            tabIndex="-1"
            className="flex fixed inset-0 z-50 justify-center items-center bg-black/50"
        >
            <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-md dark:bg-slate-800">
                <button
                    type="button"
                    className="absolute top-3 right-3 p-1.5 text-gray-400 rounded-full hover:bg-gray-200 hover:text-gray-900 dark:text-slate-300 dark:hover:bg-slate-600 dark:hover:text-gray-900"
                    onClick={onClose}
                >
                    <XMarkIcon className="w-6 h-6 text-gray-500" />
                    <span className="sr-only">{t('Close popup')}</span>
                </button>

                <div className="p-6">
                    <div className="mb-4 text-center">
                        <h2 className="text-2xl font-semibold">
                            {t('Verify your account')}
                        </h2>
                        <p className="mt-2 text-sm text-slate-600 dark:text-slate-300">
                            {t('Please enter the OTP sent to your email to verify your account.')}
                        </p>
                    </div>

                    {error && <p className="mb-4 text-center text-red-500">{error}</p>}

                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <label htmlFor="otp" className="sr-only">{t('OTP')}</label>
                        <input
                            name="otp"
                            type="text"
                            maxLength="6"
                            required
                            value={otp}
                            onChange={(e) => {
                                // Allow only numbers and limit to 6 characters
                                const value = e.target.value;
                                if (/^\d{0,6}$/.test(value)) {
                                    setOtp(value);
                                }
                            }}
                            className="block px-4 py-2 w-full text-gray-800 rounded-lg border border-gray-300 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black dark:bg-slate-800 dark:text-slate-300 dark:border-slate-600"
                            placeholder={t('OTP')}
                        />
                        <button
                            type="submit"
                            className={`w-full py-3 rounded-lg bg-black text-white font-medium text-sm focus:ring-2 focus:ring-black ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? `${t('Loading')}...` : `${t('Verify')}`}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OTPScreen;