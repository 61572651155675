// NotificationBell.jsx
import React from 'react';
import { BellIcon } from '@heroicons/react/24/solid';

const NotificationBell = ({ notificationCount, onClick }) => {
  return (
    <div className="relative cursor-pointer" onClick={onClick}>
      <BellIcon className="w-6 h-6" />
      {notificationCount > 0 && (
        <span className="inline-flex absolute top-0 right-0 justify-center items-center px-1.5 py-0.5 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2 animate-pulse-red">
          {notificationCount}
        </span>
      )}
    </div>
  );
};

export default NotificationBell;
