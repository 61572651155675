// App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Joyride from 'react-joyride';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Events from "./components/Events";
import UsersList from "./components/Admin/UsersList";
import ResetPassword from "./components/ResetPassword";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './components/Admin/Login';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import LastBets from './components/Activity';
import CoinList from './components/CoinList';
import EventPanel from './components/EventPanel';
import Privacy from './components/Privacy';
import Tac from './components/Tac';
import About from './components/About';
import Settings from './components/User/Settings';
import Dashboard from './components/User/Dashboard';
import AssetRecords from './components/User/AssetRecords';
import OTPScreen from './components/User/OTPScreen';
import ConnectWallet from './components/User/ConnectWallet';
import { CoinsProvider } from './contexts/CoinsContext';
import Activity from './components/RecentActivity';
import Test from './components/Test';
import PaymentStatus from "./components/PaymentStatus";
import { Toaster } from "react-hot-toast";
import AuthRoute from "./components/AuthRoute";
import MetaPixel from "./utils/meta/metaPixel";
import { ApiProvider } from "./contexts/ApiContext";
import ScrollingLiveNewsTicker from "./components/ScrollingLiveNewsTicker";
import './i18n';
import DepositPopup from './components/DepositPopup';
import Leaderboards from "./components/Leaderboards";
import RecentLeaderboards from "./components/RecentLeaderboards";
import { TourProvider, useTour } from './contexts/TourContext';
import Guides from './components/Guides';
import FreeDemoBets from './components/FreeDemoBets';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { VersionCheck } from "./components/VersionCheck";

// Wrap the main content with tour functionality
const TourWrapper = ({ children }) => {
  const { tourState, handleJoyrideCallback } = useTour();

  return (
    <>
      <Joyride
        {...tourState}
        continuous
        hideBackButton={false}
        showProgress
        showSkipButton
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        spotlightClicks={false}
        spotlightPadding={6}
        styles={{
          options: {
            primaryColor: '#4F46E5',
            textColor: '#333',
            zIndex: 10000,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            backgroundColor: '#fff',
            arrowColor: '#fff',
            borderRadius: '8px',
          },
          buttonNext: {
            backgroundColor: '#4F46E5',
            borderRadius: '6px',
            padding: '8px 16px'
          },
          buttonBack: {
            marginRight: 10,
            backgroundColor: 'transparent',
            color: '#4F46E5',
            border: '1px solid #4F46E5',
            borderRadius: '6px',
            padding: '6px 14px'
          },
          buttonSkip: {
            color: '#6B7280',
            borderRadius: '6px'
          },
          spotlight: {
            borderRadius: '8px'
          },
          tooltip: {
            borderRadius: '8px'
          }
        }}
        callback={handleJoyrideCallback}
      />
      {children}
    </>
  );
};

// Add SafeArea wrapper component
const SafeAreaWrapper = ({ children }) => {
  useEffect(() => {
    const setupStatusBar = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          // Set the StatusBar to overlay the WebView
          await StatusBar.setOverlaysWebView({ overlay: true });

          // Get status bar info
          const { height } = await StatusBar.getInfo();
          console.log('[SAFE_AREA] Status bar height:', height);

          // Get device info to determine platform and model
          const info = await Device.getInfo();
          console.log('[SAFE_AREA] Device info:', info);

          // Use a fallback height for iOS devices when reported height is 0
          let statusBarHeight = height;
          if (info.platform === 'ios' && height === 0) {
            // Default fallback heights based on device model
            if (info.model && (info.model.includes('iPhone X') ||
              parseInt(info.model.replace(/\D/g, ''), 10) >= 10)) {
              // For iPhone X and newer (with notch or Dynamic Island)
              statusBarHeight = 47; // Default for notch devices

              // Check if it's iPhone 14 Pro or newer (Dynamic Island)
              if (parseInt(info.model.replace(/\D/g, ''), 10) >= 14 && info.model.includes('Pro')) {
                statusBarHeight = 54; // Taller for Dynamic Island
              }
            } else {
              // For older iPhones
              statusBarHeight = 20;
            }
            console.log('[SAFE_AREA] Using fallback status bar height:', statusBarHeight);
          }

          // Set the status bar height CSS variable
          document.documentElement.style.setProperty('--status-bar-height', `${statusBarHeight}px`);

          // Add listener for safe area changes (device rotation, etc.)
          document.addEventListener('ionSafeAreaChange', updateSafeArea);

          // Initial safe area setup
          updateSafeArea(statusBarHeight);
        } catch (error) {
          console.error('[SAFE_AREA] Error setting up status bar:', error);
        }
      } else {
        console.log('[SAFE_AREA] Not running on a native platform');
      }
    };

    const updateSafeArea = (fallbackStatusBarHeight = 0) => {
      // Get safe area insets if available
      const safeAreaTop = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top') || '0px';
      const safeAreaBottom = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom') || '0px';
      const safeAreaLeft = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-left') || '0px';
      const safeAreaRight = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-right') || '0px';

      // Log all safe area values
      console.log('[SAFE_AREA] Safe area insets:', {
        top: safeAreaTop,
        bottom: safeAreaBottom,
        left: safeAreaLeft,
        right: safeAreaRight
      });

      // Set safe area CSS variables
      document.documentElement.style.setProperty('--safe-area-top', safeAreaTop);
      document.documentElement.style.setProperty('--safe-area-bottom', safeAreaBottom);
      document.documentElement.style.setProperty('--safe-area-left', safeAreaLeft);
      document.documentElement.style.setProperty('--safe-area-right', safeAreaRight);

      // Use the maximum of status bar height and safe area top
      const statusBarHeight = getComputedStyle(document.documentElement).getPropertyValue('--status-bar-height') || '0px';
      const statusBarValue = parseInt(statusBarHeight, 10) || fallbackStatusBarHeight;
      const safeAreaValue = parseInt(safeAreaTop, 10) || 0;
      const maxTopInset = Math.max(statusBarValue, safeAreaValue);

      console.log('[SAFE_AREA] Calculated values:', {
        statusBarHeight: statusBarHeight,
        statusBarValue: statusBarValue,
        safeAreaValue: safeAreaValue,
        maxTopInset: `${maxTopInset}px`
      });

      document.documentElement.style.setProperty('--safe-top-inset', `${maxTopInset}px`);

      // Log all CSS variables for debugging
      console.log('[SAFE_AREA] CSS Variables:', {
        statusBarHeight: getComputedStyle(document.documentElement).getPropertyValue('--status-bar-height'),
        safeAreaTop: getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top'),
        safeAreaBottom: getComputedStyle(document.documentElement).getPropertyValue('--safe-area-bottom'),
        safeAreaLeft: getComputedStyle(document.documentElement).getPropertyValue('--safe-area-left'),
        safeAreaRight: getComputedStyle(document.documentElement).getPropertyValue('--safe-area-right'),
        safeTopInset: getComputedStyle(document.documentElement).getPropertyValue('--safe-top-inset')
      });
    };

    setupStatusBar();

    return () => {
      // Clean up event listener
      document.removeEventListener('ionSafeAreaChange', updateSafeArea);
    };
  }, []);

  return (
    <div className="safe-area-wrapper">
      {children}
    </div>
  );
};

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <MetaPixel />
      <Router>
        <ApiProvider>
          <AuthProvider>
            <CoinsProvider>
              <TourProvider>
                <ErrorBoundary>
                  <TourWrapper>
                    <SafeAreaWrapper>
                      <VersionCheck />
                      <DepositPopup />
                      <NavBar />
                      <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/admin/login" element={<Login />} />
                        <Route path="/admin/users" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
                        {/* Routes for Password Recovery and Reset */}
                        <Route
                          path="/forgot-password"
                          element={<ResetPassword />}
                        />
                        {/* Main page */}
                        <Route path="/event/:type/:coin/:id" element={<EventPanel />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/activity" element={<Activity />} />
                        <Route path="/tac" element={<Tac />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/leaderboards" element={<Leaderboards />} />
                        <Route path="/guides" element={<Guides />} />

                        {/* Auth Routes */}
                        <Route element={<AuthRoute />}>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/user/settings" element={<Settings />} />
                          <Route path="/user/assets/records" element={<AssetRecords />} />
                          <Route path="/user/connect_wallet" element={<ConnectWallet />} />
                          <Route path="/event/:type/:coin/:id/history" element={<EventPanel isHistory={true} />} />
                        </Route>
                        {/* End of Auth Routes */}

                        <Route path="/otp" element={<OTPScreen />} />
                        <Route path="/test" element={<Test />} />
                        <Route path="/payment/status/:gateway" element={<PaymentStatus />} />
                        {/* LoginForm Route */}
                        {/* <Route
                                              path="/login"
                                              element={<MainPage />}
                                              /> */}
                      </Routes>
                      <Footer />

                      {/* Free Demo Bets Notification */}
                      <FreeDemoBets />

                      <Toaster
                        position="top-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{ top: 70 }}
                        toastOptions={{
                          // Define default options
                          className: "",
                          duration: 5000,
                          style: {
                            background: "#000",
                            color: "#ffff",
                            fontWeight: "500",
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                            minWidth: "150px",
                          },

                          // Default options for specific types
                          success: {
                            duration: 3000,
                            theme: {
                              primary: "green",
                              secondary: "black",
                            },
                          },
                        }}
                      />
                    </SafeAreaWrapper>
                  </TourWrapper>
                </ErrorBoundary>
              </TourProvider>
            </CoinsProvider>
          </AuthProvider>
        </ApiProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

const MainPage = () => (
  <>
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
      <Events />
      <CoinList />
      <ScrollingLiveNewsTicker />
      <div className="grid grid-cols-1 gap-4 p-4 mt-4 lg:grid-cols-2">
        <RecentLeaderboards />
        <LastBets />
      </div>
    </div>
  </>
);

export default App;