import React, { useEffect, useState } from 'react';
import { FaTrophy, FaMedal, FaInfoCircle } from 'react-icons/fa';
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pusher from 'pusher-js';
import { Tooltip } from 'flowbite-react';

const getRankIcon = (index) => {
  switch (index) {
    case 0:
      return <FaTrophy className="text-xl text-yellow-400" />;
    case 1:
      return <FaMedal className="text-xl text-gray-400" />;
    case 2:
      return <FaMedal className="text-xl text-yellow-600" />;
    default:
      return null;
  }
};

const LeaderboardSkeleton = () => {
  const skeletonItems = Array(5).fill(null);
  return (
    <div className="overflow-hidden mt-20 bg-gray-300 rounded-lg shadow-lg animate-pulse dark:bg-gray-800">
      <div className="p-4">
        {skeletonItems.map((_, index) => (
          <div key={index} className="flex items-center py-2 space-x-4">
            <div className="w-8 h-6 bg-gray-700 rounded"></div>
            <div className="w-24 h-6 bg-gray-700 rounded"></div>
            <div className="flex-1"></div>
            <div className="w-16 h-6 bg-gray-700 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RecentLeaderboards = () => {
  const [leaders, setLeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { apiHost, apiVersion } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaderboards = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/leaderboards?page=1&per_page=5`
        );
        const data = await response.json();

        if (data.status === "success") {
          setLeaders(data.data.data.slice(0, 5));
        }
      } catch (error) {
        console.error("Error fetching leaderboards:", error);
      }
      setIsLoading(false);
    };

    fetchLeaderboards();
  }, [apiHost, apiVersion]);

  useEffect(() => {
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    var channel = pusher.subscribe(`leaderboard.overall`);
    channel.bind("leaderboard-update", async function (data) {
      if (data?.data?.data) {
        // Take only the first 5 entries from the leaderboard data
        setLeaders(data.data.data.slice(0, 5));
      }
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`leaderboard.overall`);
    };
  }, []);

  if (isLoading) {
    return <LeaderboardSkeleton />;
  }

  return (
    <div className="overflow-hidden mt-auto bg-white rounded-lg shadow-lg dark:bg-gray-800 recent-leaderboards">
      <div className="flex justify-between items-center px-4 py-4 bg-gradient-to-r from-purple-400 to-purple-700 md:py-7">
        <h2 className="flex items-center space-x-2 text-lg font-bold">
          <span>
            {t('Top Players')}
          </span>
          <Tooltip content={t('Top Players are ranked by win rate. Win more to rank higher!')}>
            <FaInfoCircle />
          </Tooltip>
        </h2>
        <Link
          to="/leaderboards"
          className="text-sm text-gray-300 transition-colors hover:text-white"
        >
          {t('See All')}
        </Link>
      </div>
      <div className="p-4 space-y-2">
        {leaders.map((leader, index) => (
          <div key={leader.id} className="flex justify-between items-center px-2 py-2 rounded transition-colors hover:bg-gray-300 dark:hover:bg-gray-700">
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2 w-12">
                {getRankIcon(index)}
                <span>#{index + 1}</span>
              </div>
              <span className="font-medium">{leader.name}</span>
            </div>
            <div className="flex items-center space-x-4">
              <span className="dark:text-gray-400">${leader.total_stake}</span>
              <span className={`px-2 py-1 text-sm rounded-md text-white ${parseFloat(leader.win_rate) >= 70 ? 'bg-green-500' :
                parseFloat(leader.win_rate) >= 50 ? 'bg-yellow-500' :
                  'bg-red-500'
                }`}>
                {leader.win_rate}%
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentLeaderboards;
