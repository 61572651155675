import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import chevron icons
import { useTranslation } from 'react-i18next';

const Rules = ({ coin, slug, date, time, isHistory = false , coinName}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-4 mt-6 rounded-lg">
      {/* Title */}
      <h2 className="mb-2 text-xl font-bold">{t('About')}</h2>

      {/* Summary Text */}
      <p className="mb-2">
        {isHistory ? (
          <span>
            {t('You are viewing the historical data for')} {coinName.toUpperCase()}. {t('The final price was determined at')} <strong>{time} {t('on')} {date}</strong>.
          </span>
        ) : (
          <span>
            {t('Users can predict the price of')} {coinName.toUpperCase()}. {t('The final price will be determined at')} <strong>{time} {t('on')} {date}</strong>.
          </span>
        )}
      </p>

      {/* Show more button with dropdown icon */}
      {!isOpen && (
        <div
          className="flex items-center cursor-pointer"
          onClick={toggleDropdown}
        >
          <span>{t('Show more')}</span>
          <FaChevronDown className="ml-2" />
        </div>
      )}

      {/* Full Text - Only Visible When Dropdown is Open */}
      {isOpen && (
        <>
          <div className="mt-2">
            <p className="mb-2">
              {t('The resolution source for this market is')} http://polygon.io.
            </p>
            <p>
              {t('Please note that this market is based on the price according to Polygon')} {coinName.toUpperCase()} {t('and not according to other sources or spot markets.')}
            </p>
          </div>

          {/* Show less button moved to the bottom */}
          <div
            className="flex items-center mt-4 cursor-pointer"
            onClick={toggleDropdown}
          >
            <span>{t('Show less')}</span>
            <FaChevronUp className="ml-2" />
          </div>
        </>
      )}
    </div>
  );
};

export default Rules;
