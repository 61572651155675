import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlay } from 'react-icons/fa';

const VideoModal = ({ isOpen, onClose, videoSrc, title }) => {
  if (!isOpen) return null;

  return (
    <div className="flex fixed inset-0 z-50 justify-center items-center">
      {/* Semi-transparent backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <div className="relative z-50 p-4 mt-10 h-dvh">
        <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <h3 className="text-xl font-semibold">{title}</h3>
            <button
              onClick={onClose}
              className="focus:outline-none"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-4">
            <video
              className="h-[80dvh]"
              controls
              autoPlay
              preload="metadata"
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

const Guides = () => {
  const { t } = useTranslation();
  const [activeVideo, setActiveVideo] = useState(null);

  const guides = [
    {
      id: 'deposit',
      title: t('Deposit Guide'),
      src: '/media/Deposit (Indonesia) (Revisi2).mp4',
      thumbnail: '/media/DepositThumbnail.png'
    },
    {
      id: 'withdraw',
      title: t('Withdraw Guide'),
      src: '/media/Withdraw (Indoensia) (Revisi2).mp4',
      thumbnail: '/media/WithdrawThumbnail.png'
    }
  ];

  return (
    <div className="container px-4 py-8 mx-auto mt-20">
      <h1 className="mb-8 text-3xl font-bold">{t('Guides')}</h1>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {guides.map((guide) => (
          <div
            key={guide.id}
            className="overflow-hidden transition-transform transform cursor-pointer hover:scale-105"
            onClick={() => setActiveVideo(guide)}
          >
            <h2 className="p-4 text-xl font-semibold">{guide.title}</h2>
            <div className="relative bg-gray-900 rounded-lg drop-shadow-lg aspect-video">
              {/* Thumbnail Image */}
              <img
                src={guide.thumbnail}
                alt={guide.title}
                className="object-cover absolute inset-0 w-full h-full rounded-lg"
              />
              {/* Play Button Overlay */}
              <div className="flex absolute inset-0 justify-center items-center">
                <div className="p-4 bg-gray-200 bg-opacity-80 rounded-full backdrop-blur-sm">
                  <FaPlay className="w-12 h-12" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <VideoModal
        isOpen={activeVideo !== null}
        onClose={() => setActiveVideo(null)}
        videoSrc={activeVideo?.src}
        title={activeVideo?.title}
      />
    </div>
  );
};

export default Guides;
