// src/components/About.jsx

import React, { useState } from 'react';
import { FaCoins, FaTrophy, FaUsers, FaCheck } from 'react-icons/fa';
import { SirenIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import LoginForm from './LoginForm'; // Ensure the path is correct
import { useAuth } from "../AuthContext";
import { useTranslation } from 'react-i18next';

const AboutUsTexts = ({ children, className }) => {
  return (
    <div className={`mb-4 max-w-2xl text-lg leading-relaxed text-center ${className}`}>
      {children}
    </div>
  );
}

const About = () => {
  const { isAuthenticated } = useAuth();

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState('login'); // 'login' or 'register'
  const { t, i18n } = useTranslation();

  // Functions to control the modal
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const openLoginForm = () => {
    setFormMode('login');
    toggleModal();
  };

  const openRegisterForm = () => {
    setFormMode('register');
    toggleModal();
  };

  return (
    <>
      <div className="container px-4 py-12 mx-auto mt-20">
        <h1 className="mb-8 text-4xl font-bold text-center">{t('About Us')}</h1>

        {/* Hero Section */}
        <div className="flex flex-col items-center mb-12">
          <motion.h2
            className="mb-4 text-2xl font-semibold text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {t('SwaggyX - The Ultimate Prediction Platform for Crypto, Stocks, and Gold')}
          </motion.h2>
          <AboutUsTexts>
            {t('SwaggyX is a cutting-edge platform designed for those who want to test their market prediction skills while minimizing financial risk. Whether you’re a crypto enthusiast, a stock market follower, or a gold investor, SwaggyX.com offers an exciting way to engage with market trends — without the fear of losing everything.')}
          </AboutUsTexts>
          <AboutUsTexts>
            {t('The outcome relies heavily on your knowledge, analysis, and strategy in predicting price movements. Success would depend on factors such as understanding market trends, studying historical data, and analyzing market behavior.')}
          </AboutUsTexts>
          <AboutUsTexts>
            {t('On our platform, you can discover rooms dedicated to the most popular stocks and cryptocurrencies. Make predictions, win exciting rewards, and become part of a vibrant, growing community!')}
          </AboutUsTexts>
          <AboutUsTexts className={'w-full'}>
            <div className='!text-start italic'>
              {t('How It Works')} :
            </div>
            <div className='!text-start *:text-base'>
              <p className='inline-flex'>
                <span className='mr-2'>1.</span> Select Your Market: Choose from popular cryptocurrencies, stocks, or gold.
              </p>
              <p className='inline-flex'>
                <span className='mr-2'>2.</span> Make Your Prediction: Forecast the asset’s price for the next 3 hours — precision is key.
              </p>
              <p className='inline-flex'>
                <span className='mr-2'>3.</span> Win Big or Get Your Deposit Back:
              </p>
              <ul className='*:inline-flex ps-6 mt-2'>
                <li><FaTrophy className="text-lg text-yellow-400 mr-2" /> 1st Place – Earn 8x your deposit if your prediction is the most accurate.</li>
                <li><FaTrophy className="text-lg text-gray-400 mr-2" /> 2nd Place – Earn 6x your deposit for the second most accurate prediction.</li>
                <li><FaTrophy className="text-lg text-amber-600 mr-2" /> 3rd Place – Earn 4x your deposit for the third most accurate prediction.</li>
                <li><SirenIcon className="text-lg text-red-500 mr-2 size-[18px]" /> All other participants – Receive their deposit back, minus the payout for the winners.</li>
              </ul>
            </div>
          </AboutUsTexts>
          <AboutUsTexts>
            <div className='italic text-start'>
              {t('Perfect for Forex Beginners')} :
            </div>
            <div className='!text-start *:text-base'>
              <p>
                SwaggyX.com is an ideal platform for those curious about forex trading but hesitant to risk their money. By participating in these short-term prediction challenges, you can:
              </p>
              <ul className='*:inline-flex ps-6 mt-2'>
                <li><FaCheck className="text-lg dark:text-white mr-2" /> Observe Market Behavior: Understand how asset prices fluctuate in real time.</li>
                <li><FaCheck className="text-lg dark:text-white mr-2" /> Develop Prediction Skills: Improve your ability to analyze trends without major financial risks.</li>
              </ul>
            </div>
          </AboutUsTexts>
        </div>

        {/* Feature Sections */}
        <div className="grid grid-cols-1 gap-8 mb-12 text-white md:grid-cols-3">
          {/* Feature 1 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center p-6 bg-gradient-to-b from-blue-900 to-blue-700 rounded-lg shadow-lg"
          >
            <FaCoins className="mb-4 text-6xl text-yellow-400" />
            <h2 className="mb-2 text-xl font-semibold">{t('Explore Crypto Rooms')}</h2>
            <p className="text-center">
              {t('Each Room is updated every 24 hours. Choose a Room and predict the price of the selected coin 24 hours in advance.')}
            </p>
          </motion.div>

          {/* Feature 2 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center p-6 bg-gradient-to-b from-green-900 to-green-700 rounded-lg shadow-lg"
          >
            <FaTrophy className="mb-4 text-6xl text-yellow-400" />
            <h2 className="mb-2 text-xl font-semibold">{t('Real-Time Updates & Rewards')}</h2>
            <p className="text-center">
              {t('Results are updated daily at UTC+0. Payouts to the top three most accurate predictions are automatically transferred to your wallet.')}
            </p>
          </motion.div>

          {/* Feature 3 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center p-6 bg-gradient-to-b from-purple-900 to-purple-700 rounded-lg shadow-lg"
          >
            <FaUsers className="mb-4 text-6xl text-yellow-400" />
            <h2 className="mb-2 text-xl font-semibold">{t('Community Payouts')}</h2>
            <p className="text-center">
              {t('Even if you don\'t win, you won\'t lose everything, as the remaining pool is distributed equally among all other participants.')}
            </p>
          </motion.div>
        </div>

        {/* Testimonials Section */}
        <div className="mb-12">
          <h2 className="mb-6 text-3xl font-semibold text-center">{t('What Our Users Say')}</h2>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {/* Testimonial 1 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="p-6 bg-white bg-opacity-10 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "This platform has enhanced my understanding of crypto markets and rewarded me for my predictions!"
              </p>
              <p className="mt-4 text-right">- Alex J.</p>
            </motion.div>
            {/* Testimonial 2 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="p-6 bg-white bg-opacity-10 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "A fun and engaging way to apply my market analysis skills. Highly recommended!"
              </p>
              <p className="mt-4 text-right">- Maria S.</p>
            </motion.div>
            {/* Testimonial 3 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="p-6 bg-white bg-opacity-10 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "Even when I don't win, I still enjoy being part of the community and learning from others."
              </p>
              <p className="mt-4 text-right">- Liam T.</p>
            </motion.div>
          </div>
        </div>

        {/* Call to Action */}
        {!isAuthenticated ? <div className="text-center">
          <button
            onClick={openRegisterForm} // Use the function to open the modal
            className="inline-block px-6 py-3 font-semibold text-white bg-purple-700 rounded-lg shadow-lg transition duration-300 hover:scale-105 hover:bg-purple-600"
          >
            {t('Join Now and Start Winning!')}
          </button>
        </div>
          : <></>
        }
      </div>

      {/* Render the modal if showModal is true */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost="your-api-host" // Replace with actual API host if needed
          apiVersion="v1" // Replace with actual API version if needed
        />
      )}
    </>
  );
};

export default About;
