import React, { useState, useEffect } from 'react';
import CommentInput from './CommentInput';
import SingleComment from './SingleComment';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useApi } from "../contexts/ApiContext";
import { useAuth } from "../AuthContext";
import { useTranslation } from 'react-i18next';

const Comments = ({ id }) => {
  const [comments, setComments] = useState([]);
  const { apiHost, apiVersion } = useApi();
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    fetchComments();
    const interval = setInterval(fetchComments, 10000);
    return () => clearInterval(interval);
  }, [id]);
  const fetchComments = async () => {
    if(id !== undefined) {
      const response = await fetch(`${apiHost}${apiVersion}/market/comments/${id}`);
      const data = await response.json();
      setComments(data);
    }
  };
  const renderComments = (commentsToRender) => {
    return commentsToRender.map(comment => (
      <SingleComment
        poolId={id}
        commentId={comment.id}
        authorName={comment.author.name}
        authorId={comment.author.id}
        content={comment.content}
        timestamp={comment.timestamp}
        replies={comment.replies.length > 0 && renderComments(comment.replies)}
        parentCommentId={comment.parent_comment_id}
        setComments={setComments}
        comments={comments}
        fetchComments={fetchComments}
      />
    ));
  };
  return (
    <div className="rounded-md border border-gray-300 shadow-lg dark:border-slate-700">
      <div
        className="flex justify-between items-center px-4 py-4 cursor-pointer"
        onClick={toggleOpen}
      >
        <h2 className="text-lg font-semibold">{t('Comments')}</h2>
        {isOpen ? (
        <FaChevronUp className="text-lg" />
        ) : (
        <FaChevronDown className="text-lg" />
        )}
      </div>
      <div
          className={`overflow-y-auto transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-screen' : 'max-h-0'
          }`}
      >
        {
          isOpen && (<div className="p-6 mx-auto">
            <h2 className="mb-6 text-2xl font-semibold">
              {comments.length} {t('Comments')}
            </h2>
            {isAuthenticated ? 
              (<div className="space-y-6">
                  <CommentInput id={id} fetchComments={fetchComments}/>
                <div className="space-y-6">
                  {comments.length > 0 ? (
                    renderComments(comments)
                  ) : (
                    <p className="text-center text-gray-400">{t('No comments yet. Be the first to comment!')}</p>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-center text-gray-400">{t('Please log in to leave a comment.')}</p>
            )}
          </div>)
        }
      </div>
    </div>
  );
};
export default Comments;