import React, { useEffect } from 'react';
import { checkVersion } from '../utils/versionCheck';
import toast from 'react-hot-toast';

export function VersionCheck() {
    useEffect(() => {
        const checkAppVersion = async () => {
            const { needsUpdate, serverVersion } = await checkVersion();
            
            if (needsUpdate) {
                toast((t) => (
                    <div>
                        <p>New version available! ({serverVersion})</p>
                        <button
                            onClick={() => {
                                window.location.reload(true);
                                toast.dismiss(t.id);
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                        >
                            Update Now
                        </button>
                    </div>
                ), {
                    duration: Infinity,
                    position: 'top-center',
                });
            }
        };

        // Check version on mount
        checkAppVersion();

        // Check version every 5 minutes
        const interval = setInterval(checkAppVersion, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    return null; // This component doesn't render anything
}