import React, { useState, useMemo } from "react";
import { ChevronUpDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useTranslation } from 'react-i18next';
import usdtIcon from 'cryptocurrency-icons/svg/color/usdt.svg';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const CurrencySelect = ({ value, onChange, options, isLoading = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();

    const selectedOption = options?.find(opt => opt.value === value);

    // Group currencies by type
    const coinGroupList = useMemo(() => {
        if (!options) return { "Cryptocurrencies": [], "Fiat Currencies": [] };

        const cryptoC = options.filter(c => c.type === "crypto");
        const fiatC = options.filter(c => c.type === "fiat");

        return {
            "Cryptocurrencies": cryptoC,
            "Fiat Currencies": fiatC,
        };
    }, [options]);

    // Filter currencies based on search term
    const filteredCoinGroupList = useMemo(() => {
        if (!searchTerm.trim()) return coinGroupList;

        const filtered = {};
        Object.entries(coinGroupList).forEach(([groupLabel, options]) => {
            const filteredOptions = options.filter(option =>
                option.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                option.value.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (filteredOptions.length > 0) {
                filtered[groupLabel] = filteredOptions;
            }
        });
        return filtered;
    }, [coinGroupList, searchTerm]);

    return (
        <div className="relative font-semibold">
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center px-4 py-2 mt-1 w-full text-base text-left rounded-lg border border-gray-300 shadow-sm appearance-none dark:border-slate-600 placeholder:text-gray-400 focus:ring-2 dark:focus:ring-2 dark:focus:ring-slate-900 focus:ring-black"
                disabled={isLoading}
            >
                {selectedOption ? (
                    <div className="flex gap-2 items-center">
                        {selectedOption.type === 'crypto' ? (
                            <img src={usdtIcon} alt={selectedOption.value} className="w-4 h-4" />
                        ) : (
                            <span className={`fi fi-${selectedOption.value.slice(0, 2).toLowerCase()}`} />
                        )}
                        <span>{selectedOption.name}</span>
                    </div>
                ) : (
                    <span className="text-gray-700">{t('Select Currency')}</span>
                )}
                <ChevronUpDownIcon className="w-5 h-5 text-gray-400" />
            </button>

            {isOpen && (
                <div className="absolute z-50 py-1 mt-1 w-full bg-white rounded-lg border border-gray-300 shadow-lg dark:bg-slate-800 dark:border-slate-600">
                    <div className="sticky top-0 z-10 bg-white border-b border-gray-200 dark:bg-slate-800 dark:border-slate-600">
                        <div className="relative">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
                            </div>
                            <input
                                type="search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => e.key === 'Escape' && setIsOpen(false)}
                                className="block p-2 pl-10 w-full text-sm text-gray-700 bg-white border-0 focus:ring-0 focus:outline-none dark:text-slate-300 dark:bg-slate-800 dark:border-slate-600"
                                placeholder={t('Search currencies')}
                                autoFocus
                            />
                        </div>
                    </div>
                    <ul className="overflow-auto max-h-60">
                        {isLoading ? (
                            <li className="px-4 py-2 text-gray-500">{t('Loading currencies...')}</li>
                        ) : (
                            Object.entries(filteredCoinGroupList).map(([groupLabel, options]) => (
                                options.length > 0 && (
                                    <li key={groupLabel}>
                                        <div className="px-3 py-1 text-xs font-semibold text-gray-800 bg-gray-50 dark:text-slate-300 dark:bg-slate-800 dark:border-slate-600">
                                            {t(groupLabel)}
                                        </div>
                                        <ul>
                                            {options.map((option) => (
                                                <li
                                                    key={option.value}
                                                    onClick={() => {
                                                        onChange(option.value);
                                                        setIsOpen(false);
                                                    }}
                                                    className="flex gap-2 items-center px-4 py-2 cursor-pointer hover:bg-black/5"
                                                >
                                                    {option.type === 'crypto' ? (
                                                        <img src={usdtIcon} alt={option.value} className="w-4 h-4" />
                                                    ) : (
                                                        <span className={`fi fi-${option.value.slice(0, 2).toLowerCase()}`} />
                                                    )}
                                                    <span className="text-gray-800 dark:text-slate-300">{option.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                )
                            ))
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CurrencySelect;
