import React, { useEffect, useState } from 'react';
import { FaTrophy, FaMedal } from 'react-icons/fa';
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';

const PoolLeaderboardSkeleton = () => {
  const skeletonItems = Array(10).fill(null);
  return (
    <div className="overflow-hidden text-white bg-gray-800 rounded-lg shadow-lg animate-pulse">
      <div className="p-4">
        <div className="grid grid-cols-7 gap-4 py-2 border-b border-gray-700">
          {Array(7).fill(null).map((_, index) => (
            <div key={index} className="h-6 bg-gray-700 rounded"></div>
          ))}
        </div>
        {skeletonItems.map((_, index) => (
          <div key={index} className="grid grid-cols-7 gap-4 py-2">
            {Array(7).fill(null).map((_, colIndex) => (
              <div key={colIndex} className="h-6 bg-gray-700 rounded"></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const getRankIcon = (index) => {
  switch (index) {
    case 0:
      return <FaTrophy className="text-xl text-yellow-400" />;
    case 1:
      return <FaTrophy className="text-xl text-gray-400" />;
    case 2:
      return <FaTrophy className="text-xl text-amber-600" />;
    default:
      return <span className="w-6 font-medium text-center text-gray-400">{index + 1}</span>;
  }
};

const PoolLeaderboards = ({ marketId }) => {
  const [leaders, setLeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { apiHost, apiVersion } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaders = async () => {
      if (!marketId) return;

      try {
        setLoading(true);
        const response = await fetch(
          `${apiHost}${apiVersion}/leaderboards/pool?per_page=${perPage}&page=${currentPage}&pool_id=${marketId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        const data = await response.json();
        if (data.status === "success") {
          setLeaders(data.data.data);
          setTotalPages(data.data.last_page);
        }
      } catch (error) {
        console.error("Error fetching pool leaderboards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaders();
  }, [apiHost, apiVersion, marketId, currentPage, perPage]);

  useEffect(() => {
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    var channel = pusher.subscribe(`leaderboard.pool.${marketId}`);
    channel.bind("leaderboard-update", async function (data) {
      if (data?.data?.data) {
        // Take only the first 5 entries from the leaderboard data
        setLeaders(data.data.data.slice(0, 5));
        setTotalPages(data.data.last_page);
      }
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`leaderboard.pool.${marketId}`);
    };
  }, [marketId]);

  const handlePageChange = (page) => {
    if (loading) return;
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={loading}
          className={`px-3 py-1 mx-1 rounded ${currentPage === i
            ? 'bg-purple-600 text-white'
            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            } disabled:opacity-50 disabled:cursor-not-allowed`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="flex justify-center items-center mt-4 space-x-2">
        <button
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1 || loading}
          className="px-3 py-1 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {t('First')}
        </button>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || loading}
          className="px-3 py-1 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {t('Previous')}
        </button>
        {pages}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || loading}
          className="px-3 py-1 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {t('Next')}
        </button>
        <button
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages || loading}
          className="px-3 py-1 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {t('Last')}
        </button>
      </div>
    );
  };

  if (loading && leaders.length === 0) {
    return <PoolLeaderboardSkeleton />;
  }

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 pool-leaderboard">
      <div className="flex justify-between items-center p-4 bg-gradient-to-r from-purple-400 to-purple-700">
        <h2 className="text-lg font-bold">{t('Pool Leaders')}</h2>
      </div>
      <div className="p-4">
        <div className="grid grid-cols-4 gap-4 py-2 font-semibold border-b border-gray-700 md:grid-cols-6">
          <div>{t('Rank')}</div>
          <div>{t('Name')}</div>
          <div className="text-right">{t('Pool Stake')}</div>
          <div className="hidden text-right md:block">{t('Pool Bets')}</div>
          <div className="hidden text-right md:block">{t('Bets Won')}</div>
          <div className="text-right">{t('Win Rate')}</div>
        </div>
        <div className="relative">
          {loading && (
            <div className="flex absolute inset-0 justify-center items-center bg-gray-100 bg-opacity-75 dark:bg-gray-800">
              <div className="flex flex-col items-center space-y-2">
                <div className="w-8 h-8 rounded-full border-4 border-purple-500 animate-spin border-t-transparent"></div>
                <span className="text-sm text-gray-300">{t('Loading...')}</span>
              </div>
            </div>
          )}
          <div className="space-y-2">
            {leaders.map((leader, index) => (
              <div key={leader.id} className="grid grid-cols-4 gap-4 py-2 rounded transition-colors md:grid-cols-6 hover:bg-gray-400 dark:hover:bg-gray-700">
                <div className="flex items-center">
                  {getRankIcon((currentPage - 1) * perPage + index)}
                </div>
                <div className="overflow-hidden font-medium text-ellipsis">{leader.name}</div>
                <div className="text-right">${leader.pool_stake}</div>
                <div className="hidden text-right md:block">{leader.pool_bets}</div>
                <div className="hidden text-right md:block">{leader.pool_bets_won}</div>
                <div className="text-right">{leader.pool_win_rate}%</div>
              </div>
            ))}
          </div>
        </div>
        {renderPagination()}
      </div>
    </div>
  );
};

export default PoolLeaderboards;
