import React, { useState, useEffect, useContext, useCallback } from "react";
import BetPanel from "./BetPanel";
import ChartComponent from "./ChartComponent";
import Rules from "./Rules";
import OrderBook from "./OrderBook";
import { useParams } from "react-router-dom";
import { CoinsContext } from "../contexts/CoinsContext";
import { useApi } from "../contexts/ApiContext";
import { useAuth } from "../AuthContext";
import Comments from "./Comments";
import PoolLeaderboards from "./PoolLeaderboards";
import { useTranslation } from 'react-i18next';
import { useTour } from '../contexts/TourContext'; // Import useTour hook
import PredictionData from "./PredictionData";
import PredictionDataChart from "./PredictionDataChart";

const EventPanel = ({ isHistory = false }) => {
  const { type, coin, id } = useParams();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const { tourState, activeTab: tourActiveTab } = useTour();
  const [market, setMarket] = useState("");
  const [realTimePrice, setRealTimePrice] = useState(null); // State for real-time price
  const { getCoinData } = useContext(CoinsContext);
  const { apiHost, apiVersion } = useApi();
  const [slug, setSlug] = useState("");
  const { isAuthenticated } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [predictionData, setPredictionData] = useState(null);
  const { t, i18n } = useTranslation();

  // Use tour active tab if available, otherwise use local state
  const [localActiveTab, setLocalActiveTab] = useState(isHistory ? "Charts" : "Predict");
  const activeTab = tourActiveTab || localActiveTab;
  const setActiveTab = (tab) => {
    setLocalActiveTab(tab);
  };

  const tabs = ["Predict", "Charts"];

  const fetchMarket = useCallback(async (timeZone) => {
    const endpoint = isHistory ? "/market/historical-data" : "/market";
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}${endpoint}`,
        {
          method: "POST",
          headers: {
            ...(isHistory && {
              Authorization: `Bearer ${localStorage.getItem('sanctum_token')}`
            }),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
          credentials: "include",
        }
      );
      const data = await response.json();
      setMarket(data);
      const newDate = new Date(`${data.end_time}Z`);
      const formattedDate = newDate.toLocaleDateString("en-US", {
        timeZone: timeZone,
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      setDate(formattedDate);
      const formattedTime = newDate.toLocaleTimeString("en-US", {
        timeZone: timeZone,
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      setTime(formattedTime);
    } catch (error) {
      console.error("Error fetching market:", error);
    }
  }, [apiHost, apiVersion, id, isHistory]);

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    fetchMarket(userTimeZone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run once on mount

  const fetchPredictionData = useCallback(async () => {
    try {
      const response = await fetch(`${apiHost}${apiVersion}/market/prediction-data`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: market.id, price: realTimePrice }),
      });
      const data = await response.json();
      setPredictionData(data);
    } catch (error) {
      console.error('Error fetching prediction data:', error);
    }
  }, [apiHost, apiVersion, market.id, realTimePrice]);

  useEffect(() => {
    // Only fetch prediction data once when market.id is available

    if (market.id && realTimePrice && !predictionData) {
      fetchPredictionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.id, realTimePrice, predictionData]); // Only depend on market.id

  useEffect(() => {
    if (!isHistory) {
      const coinData = getCoinData(id);
      if (coinData) {
        setRealTimePrice(coinData.currentPrice); // Update real-time price
        setSlug(coinData.fullCryptoName);
      }
    }
  }, [getCoinData, isHistory, id]); // Empty dependency array to run once

  const handleCloseError = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowError(false);
      setIsClosing(false);
    }, 300); // Match this with the duration-300 class
  };
  return (
    <div className="flex flex-col mt-24">
      {/* Error Popup */}
      {showError && (
        <div className="flex fixed inset-0 z-50 justify-center items-center transition-opacity duration-300 ease-in-out"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className={`dark:bg-gray-800 bg-white p-8 rounded-lg shadow-xl w-96 transform transition-all duration-300 ease-in-out
                          ${isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="flex flex-col items-center text-center">
              <svg className="mb-6 w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
              <p className="text-lg dark:text-white">{errorMessage}</p>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Buttons for switching between tabs (hidden on desktop) */}
      {!isHistory && (
        <div className="flex w-full lg:hidden">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`flex-1 px-4 py-2 font-semibold mobile-tab-${tab.toLowerCase()} ${activeTab === tab
                ? "dark:bg-purple-600 bg-purple-600/20 text-purple-700 dark:text-purple-100"
                : "dark:bg-gray-800 bg-gray-200 dark:text-gray-400"
                }`}
              onClick={() => setActiveTab(tab)}
            >
              {t(tab)}
            </button>
          ))}
        </div>
      )}

      {/* Desktop layout */}
      <div className="hidden w-full lg:flex">
        {/* Main content area */}
        <div className={`flex flex-col ${isHistory ? `w-full` : `w-4/6`} pr-4`}>
          <div className="mb-4">
            {isHistory ? (
              market ? (
                <ChartComponent coin={coin} coinName={getCoinData(id)?.name || coin} type={type} isHistory={isHistory} start_time={market.start_time} end_time={market.end_time} />
              ) : (
                <div className="flex justify-center items-center">
                  <div
                    className="w-10 h-10 rounded-full border-4 border-white animate-spin border-t-transparent"
                    role="status"
                    aria-label={t("Loading chart")}
                  ></div>
                </div>
              )
            ) : (
              <div className="flex flex-col space-y-4">
                <ChartComponent coin={coin} coinName={getCoinData(id)?.name || coin} type={type} realTimePrice={realTimePrice} />
                {isAuthenticated && <OrderBook id={market.id} myBets={true} />}
              </div>
            )}
          </div>
          <div className="mb-4">
            <Rules coin={coin} coinName={getCoinData(id)?.name || coin} slug={slug} date={date} time={time} isHistory={isHistory} />
            <OrderBook id={market.id} isHistory={isHistory} />
          </div>
          <div className="mb-4">
            <Comments id={market.id} />
          </div>
          <PoolLeaderboards marketId={id} />
        </div>
        {/* PredictPanel area */}
        {isHistory ? null : (
          <div className="w-2/6">
            <div className="sticky top-20 bet-panel">
              <BetPanel
                coinTicker={coin}
                coinName={getCoinData(id)?.name || coin}
                type={type}
                id={market.id}
                min={market.min_size}
                max={market.max_size}
                odds={market.odds}
                targetDate={market.end_time}
                realTimePrice={realTimePrice}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                showError={showError}
                setShowError={setShowError}
                handleCloseError={handleCloseError}
                timeframe={market.timeframe}
              />
              <PredictionDataChart predictionData={predictionData} />
              <PredictionData predictionData={predictionData} />
            </div>
          </div>
        )}
      </div>

      {/* Mobile layout */}
      <div className="flex flex-col pt-8 lg:hidden">
        {/* Conditional rendering based on activeTab */}
        {activeTab === "Predict" && !isHistory && (
          <div className="w-full mobile-predict-panel">
            <BetPanel
              coinTicker={coin}
              coinName={getCoinData(id)?.name || coin}
              type={type}
              id={market.id}
              min={market.min_size}
              max={market.max_size}
              odds={market.odds}
              targetDate={market.end_time}
              realTimePrice={realTimePrice}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              showError={showError}
              setShowError={setShowError}
              handleCloseError={handleCloseError}
              timeframe={market.timeframe}
            />
            <PredictionDataChart predictionData={predictionData} />
            <PredictionData predictionData={predictionData} />
          </div>
        )}
        {activeTab === "Charts" && (
          <div className="w-full mobile-charts-panel">
            <div className="mb-4">
              {isHistory ? (
                market ? (
                  <ChartComponent
                    coin={coin}
                    coinName={getCoinData(id)?.name || coin}
                    type={type}
                    isHistory={isHistory}
                    start_time={market.start_time}
                    end_time={market.end_time}
                  />
                ) : (
                  <div className="flex justify-center items-center">
                    <div
                      className="w-10 h-10 rounded-full border-4 border-white animate-spin border-t-transparent"
                      role="status"
                      aria-label={t("Loading chart")}
                    ></div>
                  </div>
                )
              ) : (
                <div className="flex flex-col space-y-4">
                  <ChartComponent
                    coin={coin}
                    coinName={getCoinData(id)?.name || coin}
                    type={type}
                    realTimePrice={realTimePrice}
                  />
                  {isAuthenticated && <OrderBook id={market.id} myBets={true} />}
                </div>
              )}
            </div>
            <div className="mb-4">
              <Rules coin={coin} coinName={getCoinData(id)?.name || coin} slug={slug} date={date} time={time} isHistory={isHistory} />
              <OrderBook id={market.id} isHistory={isHistory} />
            </div>
            <div className="mb-4">
              <Comments id={market.id} />
            </div>
            <PoolLeaderboards marketId={id} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPanel;
