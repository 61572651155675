import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from './contexts/ApiContext';
import toast from 'react-hot-toast';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const BALANCE_CACHE_DURATION = 600000; // 10 minutes in milliseconds
let lastBalanceFetch = 0;
let cachedBalance = null;

// Add function to invalidate cache when needed
const invalidateBalanceCache = () => {
    cachedBalance = null;
    lastBalanceFetch = 0;
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(null);
    const [hasBonus, setHasBonus] = useState(false);
    const [totalBonus, setTotalBonus] = useState(0);
    const [loading, setLoading] = useState(true);
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [isUserOnTrialMode, setIsUserOnTrialMode] = useState(true);
    const [trialAttempts, setTrialAttempts] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [canWithdrawBonus, setCanWithdrawBonus] = useState(false);
    const [balanceError, setBalanceError] = useState(null);
    const [isBalanceLoading, setIsBalanceLoading] = useState(false);
    const [canWithdrawCommission, setCanWithdrawCommission] = useState(false);
    const [withdrawableCommission, setWithdrawableCommission] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [hasCommission, setHasCommission] = useState(false);
    const toggleModal = () => setShowModal(!showModal);

    const navigate = useNavigate();
    const { apiHost, apiVersion } = useApi();

    const refreshToken = async () => {
        try {
            const token = localStorage.getItem('sanctum_token');
            if (!token) return;

            const isTokenValid = await validateToken(token);
            if (!isTokenValid) {
                await clearSessionData();
                return;
            }

            const response = await fetch(`${apiHost}${apiVersion}/regenerate-token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const data = await response.json();
                if (data.code === 'TOKEN_EXPIRED') {
                    await clearSessionData();
                    return;
                }
                throw new Error('Token refresh failed');
            }

            const data = await response.json();
            localStorage.setItem('sanctum_token', data.token);
            localStorage.setItem('expires_at', data.expires_at);

        } catch (error) {
            console.error('Error refreshing token:', error);
            await clearSessionData();
        }
    };

    useEffect(() => {
        let intervalId;
        const storedExpiresAt = localStorage.getItem('expires_at');
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (user && storedExpiresAt) {
            const expiresAt = parseInt(storedExpiresAt);
            const timeUntilExpiry = expiresAt - currentTimestamp;
            const tenHoursInSeconds = 10 * 60 * 60; // 10 hours in seconds

            // Only refresh if token is not expired but within 10 hour window
            if (timeUntilExpiry > 0 && timeUntilExpiry < tenHoursInSeconds) {
                refreshToken();
            }

            // Set up interval to check every hour
            intervalId = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const timeLeft = expiresAt - currentTime;

                // Only refresh if token is not expired but within 10 hour window
                if (timeLeft > 0 && timeLeft < tenHoursInSeconds) {
                    refreshToken();
                } else if (timeLeft <= 0) {
                    // Token has expired, clean up interval and logout
                    clearInterval(intervalId);
                }
            }, 3600000); // 1 hour
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [user]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                const storedToken = localStorage.getItem('sanctum_token');
                const storedExpiresAt = localStorage.getItem('expires_at');

                if (storedUser && storedToken && storedExpiresAt) {
                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    if (currentTimestamp >= parseInt(storedExpiresAt)) {
                        await clearSessionData();
                        return;
                    }

                    const isTokenValid = await validateToken(storedToken);
                    if (!isTokenValid) {
                        await clearSessionData();
                        return;
                    }

                    login(JSON.parse(storedUser));
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                await clearSessionData();
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const setUserSession = async (user, token, expires_at) => {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('sanctum_token', token);
        localStorage.setItem('expires_at', expires_at);
        login(user);
    }

    const fetchBalance = async () => {
        const now = Date.now();

        // Return cached value if within cache duration
        if (cachedBalance && (now - lastBalanceFetch) < BALANCE_CACHE_DURATION) {
            setBalance(cachedBalance.balance);
            setHasBonus(cachedBalance.has_bonus);
            setTotalBonus(cachedBalance.bonus);
            setCanWithdrawBonus(cachedBalance.can_withdraw_bonus);
            return;
        }

        setIsBalanceLoading(true);
        setBalanceError(null);

        try {
            const token = localStorage.getItem('sanctum_token');
            const response = await fetch(`${apiHost}${apiVersion}/wallet`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) throw new Error(`Error fetching balance: ${response.statusText}`);

            const data = await response.json();

            // Update cache
            cachedBalance = data;
            lastBalanceFetch = now;

            setBalance(data.balance);
            setHasBonus(data.has_bonus);
            setHasCommission(data.has_commission);
            setTotalBonus(data.bonus);
            setTotalCommission(data.commission);
            setWithdrawableCommission(data.withdrawable_commission);
            setCanWithdrawCommission(data.can_withdraw_commission);
            setCanWithdrawBonus(data.can_withdraw_bonus);
        } catch (error) {
            console.error('Error fetching balance:', error);
            setBalanceError(error.message);
        } finally {
            setIsBalanceLoading(false);
        }
    };

    const verifyUserTrial = async () => {
        try {
            const token = localStorage.getItem('sanctum_token');

            const response = await fetch(`${apiHost}${apiVersion}/verify-user-trial`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include', // Include credentials for cookie-based auth
            });

            if (!response.ok) {
                throw new Error(`Error verifying user trial: ${response.statusText}`);
            }

            const data = await response.json();

            setTrialAttempts(data.trial_attempts);
            setIsUserOnTrialMode(data.on_trial);
        } catch (error) {
            console.error('Error verifying user trial:', error);
        }
    };

    const login = async (userData) => {
        setUser(userData);
        await forceBalanceRefresh(); // Fetch balance on login
        await verifyUserTrial();
    };

    const logout = async () => {
        try {
            const response = await fetch(`${apiHost}${apiVersion}/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('sanctum_token')}`,
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const data = await response.json();

                // Continue with logout even if token has expired
                if (data.code === 'TOKEN_EXPIRED') {
                    clearSessionData(true);
                    return
                } else {
                    throw new Error('Logout failed');
                }
            }

        } catch (error) {
            console.error('Logout Error:', error);
            // Don't rethrow error - we want to continue with logout
        } finally {
            // Always clear user data and redirect, regardless of API response
            clearSessionData(true);
        }
    };

    const clearSessionData = async (isLogOut = false) => {
        if (!isLogOut) {
            toast.error('Session expired, please login again');
        }
        localStorage.removeItem('sanctum_token');
        localStorage.removeItem('user');
        localStorage.removeItem('expires_at');
        setUser(null);
        setBalance(null);
        setBalanceLoading(true);
        navigate("/", { replace: true });
    }

    const validateToken = async (token) => {
        try {
            const response = await fetch(`${apiHost}${apiVersion}/check-token`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                mode: 'cors'
            });

            if (!response.ok) {
                if (response.status === 0) {
                    return false;
                }
            }

            const data = await response.json();
            return data.valid;
        } catch (error) {
            return false;
        }
    };

    // Call this after successful transactions
    const forceBalanceRefresh = async () => {
        invalidateBalanceCache();
        await fetchBalance();
    };

    return (
        <AuthContext.Provider value={{ user, balance, isAuthenticated: !!user, login, logout, loading, balanceLoading, fetchBalance, isUserOnTrialMode, trialAttempts, setTrialAttempts, verifyUserTrial, hasBonus, totalBonus, toggleModal, showModal, canWithdrawBonus, setUserSession, balanceError, isBalanceLoading, forceBalanceRefresh, hasCommission, totalCommission, withdrawableCommission, canWithdrawCommission }}>
            {children}
        </AuthContext.Provider>
    );
};
