/**
 * Validates numeric input to prevent non-numeric characters and specific keys
 * @param {React.KeyboardEvent} event - The keyboard event
 * @returns {void}
 */
export const validateNumericInput = (event) => {
    // Prevent 'e', '-', '.' and 'E' characters
    if ([45, 101, 46, 69].includes(event.keyCode)) {
        event.preventDefault();
        return;
    }

    // Get the character code
    const charCode = event.which || event.keyCode;

    // Allow only numeric characters (0-9)
    if ((charCode >= 32) && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
    }
};
