import React from 'react';

const PredictionData = ({ predictionData }) => {

    const Card = ({ title, children }) => {
        return (
            <div className='flex flex-col gap-2 justify-center items-center px-4 py-2 rounded-md border border-gray-300 shadow dark:border-slate-700'>
                <h4 className="text-xl font-semibold">{title}</h4>
                <div className='flex justify-center items-center w-full text-sm font-medium'>{children}</div>
            </div>
        );
    };

    return (
        <div className='flex flex-col items-center mt-10 space-y-4'>
            <h2 className='text-lg font-bold'>Prediction Data</h2>
            <div className="grid grid-cols-2 gap-2 w-full">
                <Card title="Min">
                    {predictionData?.min || 0}
                </Card>
                <Card title="Max">
                    {predictionData?.max || 0}
                </Card>
                <Card title="Average">
                    {predictionData?.average || 0}
                </Card>
                <Card title="Median">
                    {predictionData?.median || 0}
                </Card>
                <Card title="# of predictions">
                    {predictionData?.number_of_orders || 0}
                </Card>
                <Card title="Bullish/Bearish">
                    <div className='flex w-4/5 text-xs rounded-full'>
                        <div
                            className='text-center text-white bg-green-500 rounded-full rounded-e-none'
                            style={{ width: `${predictionData?.bullish_percentage || 50}%` }}
                        >
                            {predictionData?.bullish_percentage || 50}%
                        </div>
                        <div
                            className='text-center text-white bg-red-500 rounded-full rounded-s-none'
                            style={{ width: `${predictionData?.bearish_percentage || 50}%` }}
                        >
                            {predictionData?.bearish_percentage || 50}%
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default PredictionData;