import React, { useState, useEffect, useMemo, forwardRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import confettiImage from '../assets/images/confetti.png';
import failPaymentImage from '../assets/images/payment-error.png';
import pendingPaymentImage from '../assets/images/pending-payment.png';
import { QRCodeSVG } from "qrcode.react";
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import { validateNumericInput } from '../utils/inputValidation';
import { FaPlus } from "react-icons/fa";
import '/node_modules/flag-icons/css/flag-icons.min.css';
import CurrencySelect from './CurrencySelect';

const DepositModal = forwardRef(({ onClose, isPaymentProcessed, paymentStatus, currnecyList }, ref) => {
	// State variables
	const [isLoading, setIsLoading] = useState(false);
	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [accountEmail, setAccountEmail] = useState("");
	const [accountName, setAccountName] = useState("");
	const [gateway, setGateway] = useState("");
	const [error, setError] = useState("");
	const [coinList, setCoinList] = useState(currnecyList);
	const [coinGroupList, setCoinGroupList] = useState({
		"Crypto Currencies": [],
		"Fiat Currencies": [],
	});
	const [minAmount, setMinAmount] = useState(100);
	const [maxAmount, setMaxAmount] = useState(null);
	const [messages, setMessages] = useState({});
	const [cryptoPaymentResponse, setCryptoPaymentResponse] = useState({});
	const [processedPayment, setProcessedPayment] = useState(isPaymentProcessed);
	const [paymentType, setPaymentType] = useState("");
	const { apiHost, apiVersion } = useApi();
	const { t } = useTranslation();
	const [isCurrencyLoading, setIsCurrencyLoading] = useState(true);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	useEffect(() => {
		setCoinList(currnecyList);
		let fiatC = [];
		let cryptoC = [];

		currnecyList.forEach(currency => {
			if (currency.type === "fiat") {
				fiatC.push(currency)
			} else if (currency.type === "crypto") {
				cryptoC.push(currency)
			}
		});

		setCoinGroupList({
			"Crypto Currencies": cryptoC,
			"Fiat Currencies": fiatC,
		});
		setIsCurrencyLoading(false);
	}, [currnecyList]);

	useEffect(() => {
		let image = "";
		let title = "";
		let message = "";

		switch (paymentStatus) {
			case 'success':
				image = confettiImage;
				title = t("Your Deposit Was Successful");
				message = t("Thank you for your payment. Your deposit has been credited to your account.");
				setGateway("finsol");
				break;
			case 'pending':
				image = pendingPaymentImage;
				title = t("Processing Your Payment");
				message = t("We’ve received your payment details. Confirmation may take a few minutes. Please check back shortly.");
				setGateway("finsol");
				break;

			default:
				image = failPaymentImage;
				title = t("Payment Unsuccessful");
				message = t("Your deposit could not be completed. Please check your payment details or try a different method.");
				setGateway("finsol");
				break;
		}
		setPaymentType("fiat");
		setMessages({
			image, title, message
		});
	}, [paymentStatus, setMessages, t]);

	useEffect(() => {
		const selectedCurrency = coinList.find(item => item.value === currency);
		console.log(selectedCurrency)
		if (selectedCurrency) {
			setPaymentType(selectedCurrency.type) // crypto or fiat
			setGateway(selectedCurrency.gateway);

			if (selectedCurrency.deposit_max <= 0) {
				setMinAmount(parseFloat(selectedCurrency.deposit_min));
				setMaxAmount(null)
			} else {
				setMinAmount(parseFloat(selectedCurrency.deposit_min));
				setMaxAmount(parseFloat(selectedCurrency.deposit_max))
			}
		};
	}, [currency, setGateway, coinList]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (gateway === "finsol") {
			if (mobileNumber.length < 8 || mobileNumber.length > 13) {
				setError(t("Mobile Number should be between 8 to 13 digits"));
				return;
			}

			if (!accountName) {
				setError(t("Please enter an account name"));
				return;
			}

			if (!accountEmail) {
				setError(t("Please enter an account email"));
				return;
			}

			if (!mobileNumber) {
				setError(t("Please enter a mobile number"));
				return;
			}
		}

		if (amount <= 0)
			setError(t("Amount is required"));

		setError("");

		setIsLoading(true);

		let formattedAmount = amount;
		if (currency === 'THB') {
			formattedAmount = parseFloat(amount).toFixed(2);
		}

		try {
			const token = localStorage.getItem("sanctum_token");

			const response = await fetch(`${apiHost}${apiVersion}/topup/create`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					amount: formattedAmount,
					currency: currency,
					gateway: gateway,
					mobile_number: mobileNumber ?? "",
					account_name: accountName ?? "",
					account_email: accountEmail ?? "",
				}),
			});

			const contentType = response.headers.get("content-type");

			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				console.log(data)
				if (response.ok) {
					if (paymentType === "fiat") {
						if (data.success) {
							window.location = data.redirect_url
						} else {
							alert(data.message);
						}

					} else if (paymentType === "crypto") {
						if (data.success) {
							window.location = data.redirect_url
							// setProcessedPayment(true);
							// setCryptoPaymentResponse({
							// 	pay_address: data.data.pay_address,
							// 	amount: data.data.amount
							// });

							// checkTransactionStatus(data.transaction.id);
						} else {
							alert(data.message);
						}
					}
				} else {
					setError(data.message);
				}
			} else {
				const text = await response.text();
				console.error("Non-JSON response:", text);
				setError(`${t('Unexpected response format')}: ${text}`);
			}

		} catch (error) {
			console.error('Fetch Error:', error);
			setError(error.message || t("An error occurred, please try again later."));
		} finally {
			setIsLoading(false);
		}
	};

	const PaymentStatus = () => {
		if (paymentType === 'fiat')
			return <FiatPaymentStatus />
		else if (paymentType === 'crypto')
			return <CryptoPaymentStatus />
	}

	const FiatPaymentStatus = () => {
		return <>
			<div className="p-6">
				<div className="flex flex-col items-center mb-2 text-center">
					<img src={messages.image} alt="" className="w-24" />
					<h2 className="mt-6 text-xl font-semibold sm:text-2xl text-slate-900">
						{messages.title}
					</h2>
					<p className="mt-2 text-sm sm:text-base text-slate-600">
						{messages.message}
					</p>
				</div>
			</div>
		</>
	}

	const CryptoPaymentStatus = () => {
		return <>
			<div className="p-6">
				<div className="flex flex-col items-center mb-2 text-left">
					<h2 className="text-xl font-semibold sm:text-2xl text-slate-900">
						{t('Processing Payment')}
					</h2>

					<div className="mb-5">
						<p className="mt-2 text-sm sm:text-base text-slate-600">{t('Please make your payment to the following address:')}</p>
						<p className="mt-2 text-sm sm:text-base text-slate-600">{t('Address')}: <strong>{cryptoPaymentResponse.pay_address} </strong></p>
						<p className="mt-2 text-sm sm:text-base text-slate-600">{t('Amount')}: <strong>{cryptoPaymentResponse.amount} </strong></p>
					</div>

					<QRCodeSVG
						value={cryptoPaymentResponse.pay_address}
						size={256} // Size of the QR code
						bgColor={"#ffffff"} // Background color
						fgColor={"#000000"} // Foreground color
						level={"H"} // Error correction level (L, M, Q, H)
					/>
				</div>
			</div>
		</>
	}

	// const checkTransactionStatus = (transaction_id) => {
	// 	const statusInterval = setInterval(() => {
	// 		fetchTransactionStatus(transaction_id).then((response) => {
	// 			const validStatus = ["completed", "expired", "failed", "cancelled"];
	// 			const paymentStatus = response.data.payment_status;

	// 			if( validStatus.includes(paymentStatus) ) {
	// 				// clear when the api request success
	// 				clearInterval(statusInterval);
	// 				onClose();

	// 				alert(`${t('Payment')} ${paymentStatus[0].toUpperCase()}${paymentStatus.slice(1)}`);
	// 			}
	// 		});
	// 	}, 10000);
	// }

	// const fetchTransactionStatus = async (transaction_id) => {
	// 	try {
	// 		const response = await fetch(`${apiHost}${apiVersion}/topup/check/transaction/${transaction_id}`, {
	// 			method: "GET",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			}
	// 		});

	// 		const data = await response.json();

	// 		return data;
	// 	} catch (error) {
	// 		console.log(error)
	// 	}	
	// }

	return (
		<div
			id="deposit-modal"
			tabIndex="-1"
			className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-start min-h-screen bg-black/50"
		>
			<div ref={ref} className="relative p-4 mt-8 mb-8 w-full max-w-md bg-white rounded-lg shadow-md dark:bg-slate-800 sm:p-6">
				<button
					type="button"
					className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
					onClick={onClose}
				>
					<XMarkIcon className="w-5 h-5 text-gray-500 sm:h-6 sm:w-6" />
					<span className="sr-only">{t('Close popup')}</span>
				</button>
				{!processedPayment
					? <>
						<div className="p-6">
							<div className="mb-2 text-center">
								<h2 className="text-xl font-semibold sm:text-2xl">
									{t('Deposit')}
								</h2>
								<p className="mt-2 text-sm sm:text-base text-slate-600 dark:text-slate-300">
									{t('Top Up Your Account to Join the Next Pool')}
								</p>
							</div>
						</div>

						<form className="" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="currency" className="text-sm font-semibold">
									{t('Currency')}
								</label>
								<CurrencySelect
									value={currency}
									onChange={setCurrency}
									options={coinList}
									isLoading={isCurrencyLoading}
								/>
							</div>
							{(currency && gateway === "finsol") && (
								<>
									<div>
										<label htmlFor="account_name" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
											{t('Enter Account Name')}
										</label>
										<input
											name="account_name"
											type="text"
											value={accountName}
											onChange={(e) => setAccountName(e.target.value)}
											required
											className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 text-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-black dark:text-slate-300 dark:placeholder:text-slate-300 dark:border-slate-600"
											placeholder="Account Name"
											disabled={isCurrencyLoading}
											style={isCurrencyLoading ? { opacity: 0.5, cursor: 'progress' } : {}}
										/>
									</div>
									<div>
										<label htmlFor="account_email" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
											{t('Enter Account Email')}
										</label>
										<input
											name="account_email"
											type="email"
											value={accountEmail}
											onChange={(e) => setAccountEmail(e.target.value)}
											required
											className="block px-4 py-2 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 text-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-black dark:text-slate-300 dark:placeholder:text-slate-300 dark:border-slate-600"
											placeholder="Account Email"
											disabled={isCurrencyLoading}
											style={isCurrencyLoading ? { opacity: 0.5, cursor: 'progress' } : {}}
										/>
									</div>
									<div>
										<label htmlFor="mobile_number" className="text-sm font-semibold dark:text-slate-300 text-slate-900">
											{t('Enter Mobile Number')}
										</label>
										<div className="relative">
											<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
												<span className="text-gray-500"><FaPlus size={14} /></span>
											</div>
											<input
												name="mobile_number"
												type="number"
												value={mobileNumber}
												onChange={(e) => setMobileNumber(e.target.value)}
												onKeyDown={validateNumericInput}
												required
												className="block py-2 pr-4 pl-8 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 text-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-black dark:text-slate-300 dark:placeholder:text-slate-300 dark:border-slate-600"
												placeholder="Mobile Number"
												disabled={isCurrencyLoading}
												style={isCurrencyLoading ? { opacity: 0.5, cursor: 'progress' } : {}}
											/>
										</div>
									</div>
								</>
							)}
							{currency && (
								<div>
									<label htmlFor="amount" className="text-sm font-semibold text-slate-900 dark:text-slate-300">
										{t('Enter Amount')}
									</label>
									<div className="relative">
										<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
											<span className="text-gray-500">{currency?.substring(0, 3)}</span>
										</div>
										<input
											name="amount"
											type="number"
											min={minAmount}
											max={maxAmount}
											value={amount}
											onChange={(e) => setAmount(e.target.value)}
											required
											className="block py-2 pr-4 pl-12 mt-1 w-full text-base rounded-lg border border-gray-300 shadow-sm dark:bg-slate-800 text-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-black dark:text-slate-300 dark:placeholder:text-slate-300 dark:border-slate-600"
											placeholder="0.00"
											disabled={isCurrencyLoading}
											style={isCurrencyLoading ? { opacity: 0.5, cursor: 'progress' } : {}}
										/>
									</div>
									{error && <p className="text-red-500">{error}</p>}
								</div>
							)}
							<button
								type="submit"
								className={`w-full py-3 rounded-lg bg-slate-900 text-white font-medium text-base sm:text-sm focus:ring-2 focus:ring-black mt-4 ${isLoading || isCurrencyLoading ? 'opacity-50 cursor-not-allowed' : ''
									}`}
								disabled={isLoading || isCurrencyLoading}
							>
								{isLoading ? `${t('Loading')}...` : t("Proceed")}
							</button>
						</form>
					</>
					: <PaymentStatus />
				}
			</div>
		</div>
	);
});

export default DepositModal;