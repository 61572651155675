// NavBar.jsx
import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../assets/images/swaggyxlogo.png";
import LoginForm from "./LoginForm";
import { useAuth } from "../AuthContext";
import { CoinsContext } from "../contexts/CoinsContext";
import { useApi } from "../contexts/ApiContext";
import { useTour } from '../contexts/TourContext';
import {
  MagnifyingGlassIcon,
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  EnvelopeIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/solid";
import NotificationBell from "./User/NotificationBell";
import Pusher from "pusher-js";
import toast from "react-hot-toast";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  // State for managing the login modal
  //const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState("login");
  const { apiHost, apiVersion } = useApi();
  // User and authentication context
  const { user, isAuthenticated, logout, balance, toggleModal, showModal, hasBonus, totalBonus, hasCommission, totalCommission } = useAuth();
  const navigate = useNavigate();

  const totalBalance = useMemo(() => {
    let total = balance;
    if (hasBonus) {
      total += totalBonus;
    }
    if (hasCommission) {
      total += totalCommission;
    }
    return total;
  }, [balance, hasBonus, totalBonus, hasCommission, totalCommission]);

  // State for search functionality
  const [searchTerm, setSearchTerm] = useState("");
  const { coinsData } = useContext(CoinsContext);

  // States for managing dropdowns and menus
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false); // New state for notifications

  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('personal'); // Add this with other state declarations
  const { t, i18n } = useTranslation();
  const { resetAllTours } = useTour();

  // Theme state
  const [darkMode, setDarkMode] = useState(() => {
    // Check for saved theme preference or system preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    // If no saved preference, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Add ref for notification dropdown
  const notificationRef = useRef(null);

  // Handle click outside for notification dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setNotificationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Theme effect
  useEffect(() => {
    // Apply or remove dark class on the document element
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  // Toggle theme function
  const toggleTheme = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // Prevent background scroll when mobile menu or notification panel is open
  useEffect(() => {
    if (mobileMenuOpen || notificationOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenuOpen, notificationOpen]);

  const userId = user?.id; // Replace with authenticated user ID
  const token = localStorage.getItem("sanctum_token");

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await fetch(`${apiHost}${apiVersion}/notifications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`${t('Error fetching notifications')}: ${response.statusText}`);
      }
      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [apiHost, apiVersion, token]);

  useEffect(() => {
    if (userId && token) {
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });
      var channel = pusher.subscribe(`user.${userId}`);
      channel.bind("live-notification", async function (data) {
        console.log(data);
        await fetchNotifications();
        toast.success(data.title);
      });
      fetchNotifications();
    }
    return () => {
      if (userId && token) {
        channel.unbind_all();
        pusher.unsubscribe(`user.${userId}`);
      }
    };
  }, [fetchNotifications, token, userId]);

  const handleNotificationRead = async (notificationId) => {
    const token = localStorage.getItem("sanctum_token");
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/mark-notification-as-read/${notificationId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(
          `${t('Error marking notification as read')}: ${response.statusText}`
        );
      }
      setNotificationOpen(false);
      await fetchNotifications();
      toast.success(t("Notification marked as read"));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    const token = localStorage.getItem("sanctum_token");
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/mark-all-notifications-as-read`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(
          `${t('Error marking all notifications as read')}: ${response.statusText}`
        );
      }
      setNotificationOpen(false);
      await fetchNotifications();
      toast.success(t("All notifications marked as read"));
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };
  // Filtered coin list based on search term
  const filteredCoins = coinsData.filter((coin) =>
    coin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Functions for opening login or register modals
  //const toggleModal = () => setShowModal(!showModal);
  const openLoginForm = () => {
    setFormMode("login");
    toggleModal();
  };
  const openRegisterForm = () => {
    setFormMode("register");
    toggleModal();
  };

  // Navigate to specific coin event page
  const handleCoinClick = (coinName, id, type) => {
    navigate(`/event/${type.toLowerCase()}/${coinName.toLowerCase()}/${id}`);
    setSearchTerm(""); // Clear search input
  };

  // Function to navigate to dashboard when user icon/name is clicked
  const handleUserClick = () => navigate("/dashboard");

  // Function to navigate to the deposit page
  const handleDeposit = () => {
    navigate("/dashboard", { state: { shouldOpenDepositModal: true } });
  };

  // Coin icon mappings
  const iconMappings = {
    BTC: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png",
    ETH: "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
    DOGE: "https://assets.coingecko.com/coins/images/5/large/dogecoin.png",
    XRP: "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png",
    SOL: "https://assets.coingecko.com/coins/images/4128/large/solana.png",
    BNB: "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png",
  };

  // Function to get coin icon based on symbol
  const getCoinIcon = (cryptoCompareSymbol) =>
    iconMappings[cryptoCompareSymbol] || "https://via.placeholder.com/50";

  // Number of notifications
  const notificationCount = notifications.length;

  // Function to handle clicking on the notification bell
  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen);
    // Close other menus if necessary
    setMenuOpen(false);
    setMobileMenuOpen(false);
  };

  // const resetAllTours = () => {
  //   // Implement logic to reset all tours here
  // };

  return (
    <>
      {/* Header section */}
      <header className="fixed top-0 left-0 z-30 w-full bg-white shadow-lg dark:bg-gray-800" id="main-nav">
        <div className="px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Left section - Logo and search bar */}
            <div className="flex gap-4 items-center">
              {/* Logo */}
              <Link className="block navbar-logo" to="/">
                <span className="sr-only">Home</span>
                <img src={logo} className="h-9" alt="Swaggycoins Logo" />
              </Link>

              {/* Search bar (visible only on desktop) */}
              <div className="hidden md:flex md:flex-1">
                <div className="flex relative items-center">
                  <MagnifyingGlassIcon className="absolute left-3 w-5 h-5 text-gray-500" />
                  <input
                    type="text"
                    placeholder={t("Search markets")}
                    className="py-2 pr-3 pl-10 w-full h-10 text-sm text-gray-600 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-inherit dark:text-gray-200 md:w-64 lg:w-80 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* Filtered coin dropdown */}
                  {searchTerm && filteredCoins.length > 0 && (
                    <ul className="overflow-y-auto absolute right-0 left-0 top-full z-10 mt-1 max-h-60 bg-white rounded-lg shadow-lg dark:text-gray-200 dark:bg-gray-600">
                      {filteredCoins.map((coin) => (
                        <li
                          key={coin.id}
                          className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                          onClick={() => handleCoinClick(coin.name, coin.id, coin.type)}
                        >
                          <img
                            src={getCoinIcon(coin.cryptoCompareSymbol)}
                            alt={coin.name}
                            className="mr-2 w-6 h-6 rounded-full"
                          />
                          {coin.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/* Right section - Global links and user menu */}
            <div className="flex gap-4 items-center">
              {/* Global navigation links (visible only on desktop) */}
              <nav aria-label="Global">
                <ul className="hidden gap-6 items-center text-sm md:flex">
                  <li>
                    <LanguageSwitcher />
                  </li>
                  <li>
                    <button
                      className="text-gray-600 transition duration-200 hover:text-gray-800 dark:text-white dark:hover:text-gray-200"
                      onClick={toggleTheme}
                    >
                      {darkMode ? (
                        <SunIcon className="w-6 h-6" />
                      ) : (
                        <MoonIcon className="w-6 h-6" />
                      )}
                    </button>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="font-medium text-gray-600 transition duration-200 hover:text-gray-800 dark:text-white dark:hover:text-gray-200"
                    >
                      {t('About Us')}
                    </Link>
                  </li>
                </ul>
              </nav>

              {/* Authentication buttons or user dropdown */}
              <div className="flex gap-2 items-center">
                {!isAuthenticated && !localStorage.getItem("user") ? (
                  <>
                    {/* Login and Sign Up buttons (for unauthenticated users) */}
                    <button
                      className="px-4 py-2 text-sm font-medium text-gray-600 rounded-md transition duration-200 dark:text-white hover:text-white hover:bg-purple-700"
                      onClick={openLoginForm}
                    >
                      {t('Login')}
                    </button>

                    <button
                      className="px-5 py-2 text-sm font-medium text-white bg-purple-700 rounded-md transition duration-200 hover:bg-purple-600"
                      onClick={openRegisterForm}
                    >
                      {t('Sign Up')}
                    </button>

                    {/* Hamburger icon */}
                    <button
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                      className="focus:outline-none md:hidden"
                      aria-label="Toggle mobile menu"
                    >
                      {mobileMenuOpen ? (
                        <XMarkIcon className="w-6 h-6" />
                      ) : (
                        <Bars3Icon className="w-6 h-6" />
                      )}
                    </button>

                    {/* Sliding mobile menu from right */}
                    {mobileMenuOpen && (
                      <div
                        className={`fixed top-0 right-0 mobile-sidebar h-full w-64 bg-gray-50 dark:bg-gray-800 transform ${mobileMenuOpen
                          ? "translate-x-0"
                          : "translate-x-full"
                          } transition-transform duration-300 ease-in-out z-50`}
                      >
                        <div className="flex flex-col p-4 h-full">
                          {/* Header of mobile menu with 'Menu' title and close button */}
                          <div className="flex justify-between items-center mb-8">
                            <h2 className="text-lg font-semibold">
                              {t('Menu')}
                            </h2>
                            <button
                              onClick={() => setMobileMenuOpen(false)}
                              className="focus:outline-none"
                            >
                              <XMarkIcon className="w-6 h-6" />
                            </button>
                          </div>

                          {/* Navigation links */}
                          <nav className="flex-1">
                            <ul className="space-y-4">
                              {/* Guides Link */}
                              <li>
                                <Link
                                  to="/guides"
                                  className="block transition duration-200"
                                  onClick={() => setMobileMenuOpen(false)}
                                >
                                  {t('Guides')}
                                </Link>
                              </li>

                              {/* About Us Link */}
                              <li>
                                <Link
                                  to="/about"
                                  className="block transition duration-200"
                                  onClick={() => setMobileMenuOpen(false)}
                                >
                                  {t('About Us')}
                                </Link>
                              </li>
                            </ul>
                          </nav>

                          <div className="flex flex-col gap-2 justify-between items-start">
                            <LanguageSwitcher placement="mobile" />
                            <button
                              onClick={toggleTheme}
                              className="flex justify-between items-center px-4 py-2 w-full bg-gray-200 rounded-md transition duration-200 dark:bg-gray-600"
                            >
                              {darkMode ? (
                                <>
                                  <span>{t('Light Mode')}</span>
                                  <SunIcon className="w-5 h-5" />
                                </>
                              ) : (
                                <>
                                  <span>{t('Dark Mode')}</span>
                                  <MoonIcon className="w-5 h-5" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Overlay when mobile menu is open */}
                    {(mobileMenuOpen || notificationOpen) && (
                      <div
                        className="fixed inset-0 z-40 bg-black bg-opacity-50"
                        onClick={() => {
                          setMobileMenuOpen(false);
                          setNotificationOpen(false);
                        }}
                      ></div>
                    )}
                  </>
                ) : (
                  <>
                    {/* Desktop version */}
                    <div className="hidden relative gap-4 items-center md:flex">
                      {/* NotificationBell */}
                      <NotificationBell
                        className="notification-bell"
                        notificationCount={notificationCount}
                        onClick={handleNotificationClick}
                      />

                      {/* Notification Dropdown */}
                      {notificationOpen && (
                        <div ref={notificationRef} className="absolute right-0 top-10 z-50 w-96 bg-gray-50 rounded-md shadow-lg dark:bg-gray-700 dark:text-white">
                          <div className="p-4">
                            <div className="flex justify-between items-center mb-4">
                              <h3 className="text-lg font-semibold">
                                {t('Notifications')}
                              </h3>
                              {notifications.length > 0 && (
                                <span
                                  className="text-sm text-gray-400 cursor-pointer hover:text-gray-200"
                                  onClick={handleMarkAllAsRead}
                                >
                                  {t('Mark all as read')}
                                </span>
                              )}
                            </div>

                            {/* Add Tab Navigation */}
                            <div className="flex mb-4 border-b border-gray-600">
                              <button
                                className={`px-4 py-2 w-1/2 ${activeTab === 'personal'
                                  ? 'border-b-2 border-purple-500'
                                  : 'dark:text-gray-400 dark:hover:text-gray-200 hover:text-gray-500'
                                  }`}
                                onClick={() => setActiveTab('personal')}
                              >
                                {t('Personal')}
                              </button>
                              <button
                                className={`px-4 py-2 w-1/2 ${activeTab === 'news'
                                  ? 'border-b-2 border-purple-500'
                                  : 'dark:text-gray-400 dark:hover:text-gray-200 hover:text-gray-500'
                                  }`}
                                onClick={() => setActiveTab('news')}
                              >
                                {t('News')}
                              </button>
                            </div>

                            {/* Notification Content */}
                            {notifications.filter(notification =>
                              activeTab === 'personal'
                                ? notification.type === 'transactions'
                                : notification.type === 'news'
                            ).length > 0 ? (
                              <ul className="overflow-y-auto space-y-2 max-h-48">
                                {notifications
                                  .filter(notification =>
                                    activeTab === 'personal'
                                      ? notification.type === 'transactions'
                                      : notification.type === 'news'
                                  )
                                  .map((notification) => (
                                    <li
                                      key={notification.id}
                                      className="px-2 py-1 pb-2 bg-gray-200 rounded-md hover:bg-gray-100 dark:bg-gray-600 dark:hover:bg-gray-500"
                                    >
                                      <div className="flex justify-between items-center">
                                        <div className="p-2 cursor-pointer">
                                          <p className="font-semibold">
                                            {notification.data.title}
                                          </p>
                                          <p
                                            className="text-sm text-gray-400"
                                            dangerouslySetInnerHTML={{ __html: notification.data.message }}
                                          ></p>
                                        </div>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleNotificationRead(
                                              notification.id
                                            )
                                          }
                                        >
                                          <EnvelopeIcon className="w-6 h-6 text-purple-700 transition duration-200 dark:text-purple-500 dark:hover:text-purple-600 hover:text-purple-500" />
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <div className="py-4 mb-4 text-center rounded-md bg-purple-600/20">
                                <p className="text-purple-700 dark:text-purple-100">
                                  {t('No')} {activeTab} {t('notifications')}.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* User dropdown */}
                      <div
                        className="relative text-left"
                        onMouseEnter={() => {
                          setMenuOpen(true);
                          setNotificationOpen(false);
                        }}
                        onMouseLeave={() => setMenuOpen(false)}
                      >
                        <button
                          onClick={handleUserClick}
                          className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium rounded-md hover:text-purple-500 focus:outline-none user-profile-button"
                        >
                          <UserCircleIcon className="mr-1 w-6 h-6" />
                          {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : t("User"))}
                        </button>

                        {/* Dropdown with balance, deposit button, dashboard, settings, guides, and logout */}
                        {menuOpen && (
                          <div className="absolute right-0 top-full z-50 w-56 bg-gray-50 rounded-md divide-y drop-shadow-xl origin-top-right dark:divide-gray-600 dark:bg-gray-700 dark:text-white">
                            {/* Balance and Deposit */}
                            <div className="flex justify-between items-center px-4 py-2 text-sm">
                              <span className="balance-display">{t('Balance')}: ${totalBalance ?? 0}</span>
                              <button
                                className="px-3 py-1 text-black bg-yellow-400 rounded-md transition duration-200 hover:bg-yellow-500"
                                onClick={handleDeposit}
                              >
                                {t('Deposit')}
                              </button>
                            </div>

                            {/* Dashboard Link */}
                            <Link
                              to="/dashboard"
                              className="block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600"
                              onClick={() => setMenuOpen(false)} // Close menu on click
                            >
                              {t('Dashboard')}
                            </Link>

                            {/* Guides Link */}
                            <Link
                              to="/guides"
                              className="block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600"
                              onClick={() => setMenuOpen(false)}
                            >
                              {t('Guides')}
                            </Link>

                            {/* About Us Link */}
                            <Link
                              to="/about"
                              className="block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600"
                              onClick={() => setMenuOpen(false)}
                            >
                              {t('About Us')}
                            </Link>

                            {/* Theme Toggle */}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleTheme();
                              }}
                              className="flex justify-between items-center px-4 py-2 w-full text-sm text-left hover:bg-gray-200 dark:hover:bg-gray-600"
                            >
                              <span>{darkMode ? t('Light Mode') : t('Dark Mode')}</span>
                              {darkMode ? (
                                <SunIcon className="w-5 h-5" />
                              ) : (
                                <MoonIcon className="w-5 h-5" />
                              )}
                            </button>

                            {/* Settings Link */}
                            <Link
                              to="/user/settings"
                              className="block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600"
                              onClick={() => setMenuOpen(false)}
                            >
                              {t('Settings')}
                            </Link>

                            {/* Logout Button */}
                            <button
                              onClick={logout}
                              className="px-4 py-2 w-full text-sm text-left rounded-b-md hover:bg-gray-200 dark:hover:bg-gray-600"
                            >
                              {t('Logout')}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Mobile version */}
                    <div className="flex relative gap-2 items-center md:hidden">
                      {/* NotificationBell */}
                      <NotificationBell
                        className="notification-bell"
                        notificationCount={notificationCount}
                        onClick={handleNotificationClick}
                      />

                      {/* User Name Button */}
                      <button
                        onClick={handleUserClick}
                        className="inline-flex justify-center items-center px-2 py-2 text-sm font-medium rounded-md hover:text-purple-500 focus:outline-none"
                      >
                        <UserCircleIcon className="mr-1 w-6 h-6" />
                        {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : t("User"))}
                      </button>

                      {/* Hamburger icon */}
                      <button
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        className="focus:outline-none"
                        aria-label="Toggle mobile menu"
                      >
                        {mobileMenuOpen ? (
                          <XMarkIcon className="w-6 h-6" />
                        ) : (
                          <Bars3Icon className="w-6 h-6" />
                        )}
                      </button>

                      {/* Sliding notification panel from right */}
                      {notificationOpen && (
                        <>
                          <div className="fixed top-0 right-0 z-50 w-64 h-full bg-gray-50 transition-transform duration-300 ease-in-out transform translate-x-0 mobile-sidebar dark:bg-gray-800">
                            <div className="flex flex-col p-4 h-full">
                              {/* Header of notification panel */}
                              <div className="flex justify-between items-center mb-8">
                                <h2 className="text-lg font-semibold">
                                  {t('Notifications')}
                                </h2>
                                <button
                                  onClick={() => setNotificationOpen(false)}
                                  className="focus:outline-none"
                                >
                                  <XMarkIcon className="w-6 h-6" />
                                </button>
                              </div>

                              {/* Notification list */}
                              <div className="overflow-y-auto flex-1">
                                {notifications.length > 0 ? (
                                  <ul className="space-y-4">
                                    {notifications.map((notification) => (
                                      <li
                                        key={notification.id}
                                        className="px-2 py-1 pb-2 bg-gray-200 rounded-md hover:bg-gray-100 dark:bg-gray-600 dark:hover:bg-gray-500"
                                      >
                                        <div className="flex justify-between items-center">
                                          <div className="p-2 cursor-pointer">
                                            <p className="font-semibold">
                                              {notification.data.title}
                                            </p>
                                            <p
                                              className="text-sm text-gray-400"
                                              dangerouslySetInnerHTML={{ __html: notification.data.message }}
                                            ></p>
                                          </div>
                                          <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                              handleNotificationRead(
                                                notification.id
                                              )
                                            }
                                          >
                                            <EnvelopeIcon className="w-6 h-6 text-purple-700 transition duration-200 dark:text-purple-500 dark:hover:text-purple-600 hover:text-purple-500" />
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p className="dark:text-gray-400">
                                    {t('No new notifications.')}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Overlay when notification panel is open */}
                          <div
                            className="fixed inset-0 z-40 bg-black bg-opacity-50"
                            onClick={() => setNotificationOpen(false)}
                          ></div>
                        </>
                      )}

                      {/* Sliding mobile menu from right */}
                      {mobileMenuOpen && (
                        <div
                          className={`mobile-sidebar fixed top-0 right-0 h-full w-64 bg-gray-50 dark:bg-gray-800 transform ${mobileMenuOpen
                            ? "translate-x-0"
                            : "translate-x-full"
                            } transition-transform duration-300 ease-in-out z-50`}
                        >
                          <div className="flex flex-col p-4 h-full">
                            {/* Header of mobile menu with 'Menu' title and close button */}
                            <div className="flex justify-between items-center mb-8">
                              <h2 className="text-lg font-semibold">
                                {t('Menu')}
                              </h2>
                              <button
                                onClick={() => setMobileMenuOpen(false)}
                                className="focus:outline-none"
                              >
                                <XMarkIcon className="w-6 h-6" />
                              </button>
                            </div>

                            {/* Balance and Deposit Button at the Top */}
                            <div className="flex justify-between items-center mb-4">
                              <span className="balance-display">{t('Balance')}: ${totalBalance ?? 0}</span>
                              <button
                                className="px-3 py-1 text-black bg-yellow-400 rounded-md transition duration-200 hover:bg-yellow-500"
                                onClick={handleDeposit}
                              >
                                {t('Deposit')}
                              </button>
                            </div>

                            {/* Navigation links */}
                            <nav className="flex-1">
                              <ul className="space-y-4">
                                {/* Dashboard Link */}
                                <li>
                                  <Link
                                    to="/dashboard"
                                    className="block transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('Dashboard')}
                                  </Link>
                                </li>

                                {/* Guides Link */}
                                <li>
                                  <Link
                                    to="/guides"
                                    className="block transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('Guides')}
                                  </Link>
                                </li>

                                {/* About Us Link */}
                                <li>
                                  <Link
                                    to="/about"
                                    className="block transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('About Us')}
                                  </Link>
                                </li>

                                {/* Settings Link */}
                                <li>
                                  <Link
                                    to="/user/settings"
                                    className="block transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('Settings')}
                                  </Link>
                                </li>
                              </ul>
                            </nav>

                            <div className="flex flex-col gap-2 justify-between items-start">
                              <LanguageSwitcher placement="mobile" />
                              <button
                                onClick={toggleTheme}
                                className="flex justify-between items-center px-4 py-2 w-full bg-gray-200 rounded-md transition duration-200 dark:bg-gray-600"
                              >
                                {darkMode ? (
                                  <>
                                    <span>{t('Light Mode')}</span>
                                    <SunIcon className="w-5 h-5" />
                                  </>
                                ) : (
                                  <>
                                    <span>{t('Dark Mode')}</span>
                                    <MoonIcon className="w-5 h-5" />
                                  </>
                                )}
                              </button>

                              {/* Logout Button */}
                              <button
                                onClick={() => {
                                  logout();
                                  setMobileMenuOpen(false);
                                }}
                                className="px-4 py-2 w-full text-sm text-left text-white bg-red-500 rounded-md hover:bg-red-600"
                              >
                                {t('Logout')}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Overlay when mobile menu is open */}
                      {(mobileMenuOpen || notificationOpen) && (
                        <div
                          className="fixed inset-0 z-40 bg-black bg-opacity-50"
                          onClick={() => {
                            setMobileMenuOpen(false);
                            setNotificationOpen(false);
                          }}
                        ></div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Modal for Login/Register */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost={apiHost}
          apiVersion={apiVersion}
        />
      )}
    </>
  );
};

export default NavBar;
