import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApi } from './ApiContext';
import { useAuth } from '../AuthContext';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const location = useLocation();
  const { apiHost, apiVersion } = useApi();
  const { user, isAuthenticated } = useAuth();
  const [activeTab, setActiveTab] = useState(null);
  const [tourState, setTourState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
    currentTour: null,
  });
  const { i18n } = useTranslation();

  // Check if current route is a history page
  const isHistory = location.pathname.includes('/history/');

  // List of all available tours
  const AVAILABLE_TOURS = ['home', 'event', 'dashboard'];

  // Check if a specific tour is completed locally
  const isTourCompleted = (tourName) => {
    const completed = localStorage.getItem(`local_tour_completed_${tourName}`) === 'true';
    console.log(`[Tour] Checking if ${tourName} tour is completed:`, completed);
    return completed;
  };

  // Check if all tours are completed
  const areAllToursCompleted = () => {
    const allCompleted = AVAILABLE_TOURS.every(tour => isTourCompleted(tour));
    console.log('[Tour] All tours completed?', allCompleted);
    console.log('[Tour] Individual tour status:',
      AVAILABLE_TOURS.reduce((acc, tour) => ({
        ...acc,
        [tour]: localStorage.getItem(`local_tour_completed_${tour}`) === 'true'
      }), {})
    );
    return allCompleted;
  };

  // Check if backend is synced with tour completion
  const isBackendSynced = () => {
    const synced = localStorage.getItem('finishedTour') === 'true';
    console.log('[Tour] Backend sync status:', synced);
    return synced;
  };

  // Mark a specific tour as completed
  const markTourCompleted = async (tourName) => {
    console.log(`[Tour] Attempting to mark ${tourName} tour as completed`);

    if (!isTourCompleted(tourName)) {
      console.log(`[Tour] ${tourName} tour not previously completed, marking as complete`);
      // Mark this specific tour as completed locally
      localStorage.setItem(`local_tour_completed_${tourName}`, 'true');

      // If all tours are completed and we haven't synced with backend yet
      const allCompleted = areAllToursCompleted();
      const backendSynced = isBackendSynced();

      console.log('[Tour] Status check for backend sync:', {
        allToursCompleted: allCompleted,
        backendSynced: backendSynced,
        isAuthenticated: isAuthenticated
      });

      if (allCompleted && !backendSynced && isAuthenticated) {
        console.log('[Tour] All conditions met, notifying backend');
        try {
          const response = await fetch(`${apiHost}${apiVersion}/complete-tour`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`,
            }
          });

          if (response.ok) {
            const data = await response.json();
            console.log('[Tour] Backend response:', data);
            if (data.finishedTour) {
              localStorage.setItem('finishedTour', 'true');
              console.log('[Tour] Successfully synced with backend');
            }
          } else {
            console.error('[Tour] Failed to update tour completion status:', await response.text());
          }
        } catch (error) {
          console.error('[Tour] Error updating tour completion status:', error);
        }
      } else {
        console.log('[Tour] Not syncing with backend yet:', {
          reason: !allCompleted ? 'Not all tours completed' :
            !isAuthenticated ? 'User not authenticated' :
              'Already synced with backend'
        });
      }
    } else {
      console.log(`[Tour] ${tourName} tour was already completed`);
    }
  };

  // Function to check tour completion status from backend
  const syncTourCompletionStatus = async () => {
    console.log('[Tour] Syncing tour status with backend');
    if (!isAuthenticated) {
      console.log('[Tour] Not authenticated, skipping sync');
      return;
    }

    try {
      const response = await fetch(`${apiHost}${apiVersion}/tour-status`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('[Tour] Backend tour status:', data);
        if (data.finishedTour) {
          console.log('[Tour] Backend confirms tours completed, updating local state');
          // If backend says tours are completed, mark all tours as completed locally
          AVAILABLE_TOURS.forEach(tour => {
            localStorage.setItem(`local_tour_completed_${tour}`, 'true');
          });
          localStorage.setItem('finishedTour', 'true');
        }
      }
    } catch (error) {
      console.error('[Tour] Error fetching tour completion status:', error);
    }
  };

  // Sync with backend when component mounts and auth state changes
  useEffect(() => {
    syncTourCompletionStatus();
  }, [isAuthenticated]);

  // Reset tour when language changes
  useEffect(() => {
    const currentSteps = getStepsForRoute(location.pathname);
    if (currentSteps) {
      setTourState(prev => ({
        ...prev,
        steps: currentSteps.steps,
      }));
    }
  }, [i18n.language, location.pathname]);

  // Function to handle tab switching during tour
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    // Continue to next step after tab switch
    setTourState(prev => ({
      ...prev,
      stepIndex: prev.stepIndex + 1
    }));
  };

  // Function to check if we should switch tabs
  const shouldSwitchTab = (data) => {
    const { action, index, type } = data;
    if (type === 'step:after') {
      // Check if next step is the Charts tab step
      const nextStep = tourState.steps[index + 1];
      if (nextStep) {
        if (nextStep.target.includes('mobile-tab-charts')) {
          handleTabSwitch('Charts');
        } else if (nextStep.title === 'Bet History 🎯') {
          handleTabSwitch('betHistory');
        }
      }
    }
  };

  const customTitle = (title) => {
    return (
      <>
        <LanguageSwitcher onLanguageChange={(lang) => {
          // Force re-render of Joyride by temporarily stopping and restarting
          setTourState(prev => ({ ...prev, run: false }));
          // Increase timeout to ensure language change is processed
          setTimeout(() => {
            setTourState(prev => ({ ...prev, run: true }));
          }, 250);
        }} />
        {t(title)}
      </>
    );
  }

  // Define steps for different routes
  const getStepsForRoute = (pathname) => {
    const isMobile = window.innerWidth < 1024; // lg breakpoint in Tailwind

    if (pathname === '/') {
      return {
        steps: [
          {
            disableBeacon: true,
            target: ".navbar-logo",
            title: customTitle("Welcome to SwaggyX!"),
            content: t("Your success here depends on your knowledge, analysis, and strategy in predicting price movements. Explore rooms for popular stocks and cryptocurrencies, make predictions, and win exciting rewards. Join a growing community of market enthusiasts and put your skills to the test!"),
          },
          {
            target: ".coin-list",
            placement: 'top',
            title: customTitle("Explore Markets & Choose Your Room"),
            content: t("Here you'll find all ongoing events. Join in and start predicting!"),
          },
          {
            target: ".coin-list-filters",
            placement: 'top',
            title: customTitle("Search and Options"),
            content: t("Search for rooms that you like and filter them by room type."),
          },
          {
            target: ".recent-leaderboards",
            title: customTitle("Leaderboards"),
            content: t("Check out our top predictors and their winning strategies."),
          },
          {
            target: ".last-bets",
            title: customTitle("Recent Activity"),
            content: t("See the latest predictions."),
          },
        ],
        tourName: 'home'
      };
    }

    if (pathname.includes('/event/') || pathname.includes('/history/')) {
      // Skip tour for history pages
      if (isHistory) {
        return { steps: [], tourName: null };
      }

      const mobileSteps = [
        {
          disableBeacon: true,
          disableScrolling: true,
          target: ".mobile-tab-predict",
          title: customTitle("Welcome to the Prediction Room"),
          content: t("On mobile, you can switch between the Predict and Charts tabs to access different features. Let's start with making a prediction!"),
          placement: "bottom",
        },
        {
          disableScrolling: true,
          target: ".mobile-predict-panel .bet-panel",
          title: customTitle("Bet Panel"),
          content: t("This is where you make your predictions. You have 3 free bets available to use! Experience the thrill of betting before you deposit!"),
          placement: "top",
        },
        {
          target: ".mobile-predict-panel .prediction-input",
          title: customTitle("Prediction"),
          content: t("Enter your predicted price here. Use the + and - buttons to adjust your prediction."),
          placement: "bottom",
        },
        {
          disableScrolling: true,
          target: ".mobile-predict-panel .prediction-amount-input",
          title: customTitle("Prediction Amount"),
          content: t("Set how much you want to bet. The higher your stake, the more you can win!"),
          placement: "bottom",
        },
        {
          disableScrolling: true,
          target: ".mobile-predict-panel .potential-win",
          title: customTitle("Potential Winnings"),
          content: t("See how much you could win based on your prediction and stake amount."),
          placement: "bottom",
        },
        {
          target: ".mobile-tab-charts",
          title: customTitle("Switch to Charts"),
          content: t("Now let's check out the charts view!"),
          placement: "bottom",
          disableBeacon: true,
        },
        {
          target: ".mobile-charts-panel .chart-container",
          title: customTitle("Price Chart"),
          content: t("This chart shows the real-time price movement. Use it to analyze trends and make informed predictions."),
          placement: "bottom",
          delay: 400, // Add delay to allow tab switch animation
          disableBeacon: true, // Disable beacon when switching tabs
          disableScrolling: true,
        },
        ...(isAuthenticated ? [{
          target: ".mobile-charts-panel .my-bets",
          title: customTitle("Your Recent Bets"),
          content: t("View all your recent bets for this room."),
          placement: "top",
        }] : []),
        {
          target: ".mobile-charts-panel .order-book",
          title: customTitle("Order Book"),
          content: t("View all current predictions from other players. This can help you gauge market sentiment."),
          placement: "top",
        },
        {
          target: ".mobile-charts-panel .pool-leaderboard",
          title: customTitle("Pool Leaderboard"),
          content: t("View the top players in this pool."),
          placement: "top",
        }
      ];

      const desktopSteps = [
        {
          disableBeacon: true,
          target: ".chart-container",
          title: customTitle("Welcome to the Prediction Room"),
          content: t("This is where you can predict the future price of cryptocurrencies and earn rewards. Let's show you how it works."),
          placement: "right",
          disableScrolling: true,
        },
        {
          target: ".chart-container",
          title: customTitle("Price Chart"),
          content: t("This chart shows the real-time price movement. Use it to analyze trends and make informed predictions."),
          placement: "right",
          disableScrolling: true,
        },
        {
          target: ".bet-panel",
          title: customTitle("Bet Panel"),
          content: t("This is where you make your predictions. You have 3 free bets available to use! Experience the thrill of betting before you deposit!"),
          placement: "left",
          disableScrolling: true,
        },
        {
          target: ".prediction-input",
          title: customTitle("Prediction"),
          content: t("Enter your predicted price here. Use the + and - buttons to adjust your prediction."),
          placement: "left",
          disableScrolling: true,
        },
        {
          target: ".prediction-amount-input",
          title: customTitle("Prediction Amount"),
          content: t("Set how much you want to bet. The higher your stake, the more you can win!"),
          placement: "left",
          disableScrolling: true,
        },
        {
          target: ".potential-win",
          title: customTitle("Potential Winnings"),
          content: t("See how much you could win based on your prediction and stake amount. The 3 closest predictions will win their bets times the multiplier. Don't worry, if you do not win, you will not lose all your money!"),
          placement: "left",
          disableScrolling: true,
        },
        {
          target: ".my-bets",
          title: customTitle("Your Recent Bets"),
          content: t("View all your recent bets for this room."),
          placement: "top",
          disableScrolling: true,
        },
        {
          target: ".order-book",
          title: customTitle("Order Book"),
          content: t("View all current predictions from other players. This can help you gauge market sentiment."),
          placement: "top",
        },
        {
          target: ".pool-leaderboard",
          title: customTitle("Pool Leaderboard"),
          content: t("View the top players in this pool."),
          placement: "top",
        }
      ];

      return {
        steps: isMobile ? mobileSteps : desktopSteps,
        tourName: 'event'
      };
    }

    if (pathname === '/dashboard') {
      return {
        steps: [
          {
            disableScrolling: true,
            disableBeacon: true,
            target: ".dashboard-header",
            title: customTitle("Welcome to your Dashboard"),
            content: t("This is your personal space where you can track your activity and manage your account."),
            placement: "bottom"
          },
          {
            target: ".settings-button",
            title: customTitle("Account Settings"),
            content: t("Customize your account settings, security preferences, and notification options."),
            placement: "left",
            disableScrolling: true
          },
          {
            target: ".balance-card",
            title: customTitle("Balance Overview"),
            content: t("View your total balance, including any bonus rewards. Use the Deposit and Withdraw buttons to manage your funds."),
            placement: "right",
            disableScrolling: true
          },
          {
            target: ".deposit-withdraw-buttons",
            title: customTitle("Deposit/Withdraw"),
            content: t("Use the Deposit and Withdraw buttons to manage your funds, topup your balance so you can place your predictions, or withdraw your winnings."),
            placement: "right",
            disableScrolling: true
          },
          {
            target: ".additional-cards",
            title: customTitle("Quick Actions"),
            content: t("Access important features and view your current statistics at a glance."),
            placement: "top",
            disableScrolling: true
          },
          {
            target: ".records-tab",
            title: customTitle("Asset Records"),
            content: t("Track all your deposits, withdrawals, and other financial transactions."),
            placement: "top",
            disableScrolling: true
          },
          {
            target: ".records-tab",
            title: customTitle("Bet History"),
            content: t("View your complete betting history, including wins and losses."),
            placement: "top",
            disableScrolling: true,
            delay: 400 // Add delay to allow tab switch animation
          }
        ],
        tourName: 'dashboard'
      }
    }

    return { steps: [], tourName: null };
  };

  // Reset step index when route changes
  useEffect(() => {
    if (tourState.currentTour) {
      const newSteps = getStepsForRoute(location.pathname).steps;
      setTourState(prev => ({
        ...prev,
        steps: newSteps,
        stepIndex: 0 // Reset step index on route change
      }));
    }
  }, [location.pathname]);

  // Update steps when route changes
  useEffect(() => {
    const { steps, tourName } = getStepsForRoute(location.pathname);
    const shouldRunTour = tourName && !isTourCompleted(tourName) && isAuthenticated && !user.finishedTour;

    setTourState(prev => ({
      ...prev,
      steps,
      currentTour: tourName,
      run: shouldRunTour,
    }));
  }, [location.pathname, isAuthenticated, user]);

  const startTour = (tourName) => {
    const { steps } = getStepsForRoute(location.pathname);
    setTourState({
      run: true,
      stepIndex: 0,
      steps,
      currentTour: tourName
    });
  };

  const stopTour = () => {
    setTourState(prev => ({ ...prev, run: false }));
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    // Only allow closing tour through the Skip or Done buttons
    if (action === 'close' && type !== 'finished') {
      return;
    }

    if (action === 'update' && type === 'error:target_not_found') {
      // Skip to next step if current target is not found
      setTourState(prev => ({
        ...prev,
        stepIndex: index + 1
      }));
    }

    if (status === 'finished' || status === 'skipped') {
      // Reset active tab state
      setActiveTab(null);
      // Mark current tour as completed
      if (tourState.currentTour) {
        markTourCompleted(tourState.currentTour);
      }
      stopTour();
    }

    // Handle tab switching
    shouldSwitchTab(data);

    // Handle step changes including back button
    if (type === 'step:after') {
      let nextIndex = index;
      if (action === 'prev') {
        nextIndex = index - 1;
      } else if (action === 'next') {
        nextIndex = index + 1;
      }

      setTourState(prev => ({
        ...prev,
        stepIndex: nextIndex
      }));
    }
  };

  // Function to reset all tours (useful for testing)
  const resetAllTours = () => {
    console.log('[Tour] Resetting all tour progress');
    // Clear all local tour completions
    AVAILABLE_TOURS.forEach(tour => {
      localStorage.removeItem(`local_tour_completed_${tour}`);
    });
    // Clear backend sync status
    localStorage.removeItem('finishedTour');
    setTourState(prev => ({
      ...prev,
      run: true,
      stepIndex: 0
    }));
    console.log('[Tour] Tour progress reset complete');
  };

  // Pass active tab state to context value
  return (
    <TourContext.Provider
      value={{
        tourState,
        setTourState,
        startTour,
        stopTour,
        handleJoyrideCallback,
        resetAllTours,
        isTourCompleted,
        areAllToursCompleted,
        activeTab
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};
