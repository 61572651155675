// This optional code is used to register a service worker.
// Registering a service worker is a way to make your app work offline.

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || 'https://swaggyx.com';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(
    /^127(?:\.\d+){3}$/
  )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${PUBLIC_URL}/service-worker.js?v=${Date.now()}`; // Add cache busting query param

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      // Check for updates more frequently - every 15 minutes
      setInterval(() => {
        registration.update().then(() => {
          console.log('Service worker update check completed');
        }).catch(error => {
          console.error('Service worker update check failed:', error);
        });
      }, 15 * 60 * 1000);

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New content is available
              console.log('New content is available');

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }

              // Force immediate activation
              if (installingWorker.waiting) {
                // Send skip waiting message
                installingWorker.waiting.postMessage({ type: 'SKIP_WAITING' });
              }

              // Listen for controlling change and reload all tabs
              navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('New service worker activated, reloading...');
                window.location.reload();
              });

            } else {
              // Content is cached for offline use
              console.log('Content is cached for offline use');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Add cache busting headers
  fetch(swUrl, {
    headers: {
      'Service-Worker': 'script',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    }
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}