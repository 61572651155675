import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaTrophy, FaMedal } from 'react-icons/fa';
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';
import Pusher from 'pusher-js';

const getRankIcon = (index, currentPage) => {
  // Only show rank icons on the first page
  if (currentPage === 1) {
    switch (index) {
      case 0:
        return <FaTrophy className="text-xl text-yellow-400" />;
      case 1:
        return <FaMedal className="text-xl text-gray-400" />;
      case 2:
        return <FaMedal className="text-xl text-yellow-600" />;
      default:
        return null;
    }
  }
  return null;
};

const PerPageSelector = ({ perPage, onChange, t }) => (
  <div className="flex items-center space-x-2">
    <label className="text-sm">{t('Show')}:</label>
    <select 
      value={perPage} 
      onChange={(e) => onChange(Number(e.target.value))}
      className="px-2 py-1 text-sm bg-gray-200 rounded dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
    >
      <option value={10}>10</option>
      <option value={25}>25</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </select>
    <span className="text-sm">{t('entries')}</span>
  </div>
);

const LeaderboardTable = ({ leaders, t, currentPage }) => (
  <div className="hidden overflow-hidden bg-gray-200 rounded-lg shadow-lg dark:bg-gray-800 md:block">
    <table className="min-w-full table-auto">
      <thead className="text-white bg-gradient-to-r from-purple-400 to-purple-700">
        <tr>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Rank')}
          </th>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Player')}
          </th>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Total Stake')}
          </th>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Total Bets')}
          </th>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Bets Won')}
          </th>
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
            {t('Win Rate')}
          </th>
        </tr>
      </thead>
      <tbody className="bg-gray-200 divide-y dark:bg-gray-800">
        {leaders.map((leader, index) => (
          <tr key={leader.id} className="transition-colors hover:bg-gray-300 dark:hover:bg-gray-700">
            <td className="flex items-center px-6 py-4 space-x-2 whitespace-nowrap">
              {getRankIcon(index, currentPage)}
              <span>{((currentPage - 1) * 10) + index + 1}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="font-medium">{leader.name}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">${leader.total_stake}</td>
            <td className="px-6 py-4 whitespace-nowrap">{leader.total_bets}</td>
            <td className="px-6 py-4 whitespace-nowrap">{leader.bets_won}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={`px-2 py-1 rounded-md text-white ${
                parseFloat(leader.win_rate) >= 70 ? 'bg-green-500' :
                parseFloat(leader.win_rate) >= 50 ? 'bg-yellow-500' :
                'bg-red-500'
              }`}>
                {leader.win_rate}%
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const LeaderboardCards = ({ leaders, t, currentPage }) => (
  <div className="space-y-4 md:hidden">
    {leaders.map((leader, index) => (
      <div key={leader.id} className="p-4 rounded-lg shadow-lg dark:bg-gray-800">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center space-x-2">
            {getRankIcon(index, currentPage)}
            <span className="">#{((currentPage - 1) * 10) + index + 1}</span>
            <span className="font-medium">{leader.name}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 text-sm">
          <div className="text-gray-400">
            {t('Total Stake')}: <span className="">${leader.total_stake}</span>
          </div>
          <div className="text-gray-400">
            {t('Total Bets')}: <span className="">{leader.total_bets}</span>
          </div>
          <div className="text-gray-400">
            {t('Bets Won')}: <span className="">{leader.bets_won}</span>
          </div>
          <div className="text-gray-400">
            {t('Win Rate')}: 
            <span className={`ml-1 px-2 py-1 rounded-md text-white ${
              parseFloat(leader.win_rate) >= 70 ? 'bg-green-500' :
              parseFloat(leader.win_rate) >= 50 ? 'bg-yellow-500' :
              'bg-red-500'
            }`}>
              {leader.win_rate}%
            </span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

LeaderboardTable.propTypes = {
  leaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      total_stake: PropTypes.number.isRequired,
      total_bets: PropTypes.number.isRequired,
      bets_won: PropTypes.number.isRequired,
      win_rate: PropTypes.string.isRequired,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

LeaderboardCards.propTypes = LeaderboardTable.propTypes;

const LeaderboardSkeleton = () => {
  // Create an array of 10 items for skeleton loading
  const skeletonItems = Array(10).fill(null);

  return (
    <>
      {/* Desktop Skeleton */}
      <div className="hidden overflow-hidden text-white bg-gray-200 rounded-lg shadow-lg animate-pulse dark:bg-gray-800 md:block">
        <table className="min-w-full table-auto">
          <thead className="bg-gradient-to-r from-purple-400 to-purple-700">
            <tr>
              {['Rank', 'Player', 'Total Stake', 'Total Bets', 'Bets Won', 'Win Rate'].map((header) => (
                <th key={header} className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-200 divide-y dark:bg-gray-900">
            {skeletonItems.map((_, index) => (
              <tr key={index} className="transition-colors">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-8 h-6 bg-gray-700 rounded"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-24 h-6 bg-gray-700 rounded"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-12 h-6 bg-gray-700 rounded"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-16 h-6 bg-gray-700 rounded"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-12 h-6 bg-gray-700 rounded"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-16 h-6 bg-gray-700 rounded"></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Skeleton */}
      <div className="space-y-4 md:hidden">
        {skeletonItems.map((_, index) => (
          <div key={index} className="p-4 bg-gray-200 rounded-lg shadow-lg animate-pulse dark:bg-gray-800">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-gray-700 rounded"></div>
                <div className="w-24 h-4 bg-gray-700 rounded"></div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="space-y-1">
                <div className="w-20 h-3 bg-gray-700 rounded"></div>
                <div className="w-12 h-4 bg-gray-700 rounded"></div>
              </div>
              <div className="space-y-1">
                <div className="w-20 h-3 bg-gray-700 rounded"></div>
                <div className="w-12 h-4 bg-gray-700 rounded"></div>
              </div>
              <div className="space-y-1">
                <div className="w-20 h-3 bg-gray-700 rounded"></div>
                <div className="w-12 h-4 bg-gray-700 rounded"></div>
              </div>
              <div className="space-y-1">
                <div className="w-20 h-3 bg-gray-700 rounded"></div>
                <div className="w-16 h-4 bg-gray-700 rounded"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const Leaderboards = () => {
  const [leaders, setLeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const { apiHost, apiVersion } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaderboards = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/leaderboards?page=${currentPage}&per_page=${perPage}`
        );
        const data = await response.json();
        
        if (data.status === "success") {
          setLeaders(data.data.data);
          setTotalPages(data.data.last_page);
        }
      } catch (error) {
        console.error("Error fetching leaderboards:", error);
      }
      setIsLoading(false);
    };

    fetchLeaderboards();
  }, [apiHost, apiVersion, currentPage, perPage]);

    useEffect(() => {
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });
      
      var channel = pusher.subscribe(`leaderboard.overall`);
        channel.bind("leaderboard-update", async function (data) {
          if (data?.data?.data) {
            // Take only the first 5 entries from the leaderboard data
            setLeaders(data.data.data.slice(0, 5));
            setTotalPages(data.data.last_page);
          }
      });

      return () => {
        channel.unbind_all();
        pusher.unsubscribe(`leaderboard.overall`);
      };
    }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (value) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div className="mt-20 space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t('Leaderboards')}</h2>
        <PerPageSelector perPage={perPage} onChange={handlePerPageChange} t={t} />
      </div>
      
      {isLoading ? (
        <LeaderboardSkeleton />
      ) : (
        <>
          <LeaderboardTable leaders={leaders} t={t} currentPage={currentPage} />
          <LeaderboardCards leaders={leaders} t={t} currentPage={currentPage} />
        </>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Leaderboards;
