import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Tooltip } from 'flowbite-react';
import { FaInfoCircle } from 'react-icons/fa';

const BalanceCard = ({ label, value, tooltip }) => {
    return (
        <div>
            <label htmlFor="" className="text-sm font-medium text-gray-400 dark:text-slate-600">{label}</label>
            <div className="flex gap-2 items-center">
                <h3 className="text-3xl font-medium text-gray-900 dark:text-white">${value}</h3>
                <Tooltip
                    arrow={false}
                    content={tooltip}
                    className='w-56 text-gray-900 bg-gray-100 shadow-md dark:text-white dark:bg-slate-700'
                    placement='right'
                >
                    <FaInfoCircle className="text-gray-500 size-4" />
                </Tooltip>
            </div>
        </div>
    )
}

const BalanceModal = forwardRef(({ onClose, balance, totalBonus, totalCommission, formattedBalance }, ref) => {
    const { t } = useTranslation();
    return (
        <div
            id="balance-modal"
            tabIndex="-1"
            className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-start min-h-screen bg-black/50"
        >
            <div ref={ref} className="relative p-4 mt-8 mb-8 w-full max-w-md bg-white rounded-lg shadow-md dark:bg-slate-800 sm:p-6">
                <button
                    type="button"
                    className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
                    onClick={onClose}
                >
                    <XMarkIcon className="w-5 h-5 text-gray-500 sm:h-6 sm:w-6" />
                    <span className="sr-only">{t('Close popup')}</span>
                </button>
                <div className="p-4 my-8 w-full max-w-md">
                    <div className="flex flex-col justify-center items-center">
                        <h2 className="mb-4 text-2xl font-semibold text-center">{t('Balance Summary')}</h2>
                        <div className='flex flex-col gap-5 w-4/5'>
                            <BalanceCard
                                label={t('Balance')}
                                value={balance}
                                tooltip={t('This is the money you have deposited and won in bets')}
                            />
                            <BalanceCard
                                label={t('Sign up and Top up Bonus')}
                                value={totalBonus}
                                tooltip={t('This is the money you have got in sign up and top up bonus. You can only withdraw this money when you have won a bet.')}
                            />
                            <BalanceCard
                                label={t('Commission Credits')}
                                value={totalCommission}
                                tooltip={t('This is the money you have got in referral commissions. You can only withdraw this money when you have placed a bet and have a successful deposit. Example: If you have deposited $3 and you have a $5 commission, you can only withdraw $3 of it.')}
                            />
                            <BalanceCard
                                label={t('Total')}
                                value={formattedBalance}
                                tooltip={t('This is the total money you have got')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default BalanceModal